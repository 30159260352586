import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  Alert,
  Space,
  Empty,
  Typography,
  message,
  Row,
  Col,
  Collapse,
  Form,
  Input,
  Cascader,
  Select,
} from "antd";

import "./index.less";
import LinkAge from "../../components/linkage";
import LinkSelect from "../../components/linkselect";
import httpPromise from "@/api";

const { Text } = Typography;
const { Panel } = Collapse;

const AdvancedSearchForm = (props) => {
  const [edu, setEdu] = useState([]);
  const [exp, setExp] = useState([]);
  const [form] = Form.useForm();

  function getInfo(api, fn) {
    return new Promise((resolve, reject) => {
      httpPromise({ apiDemo: api, params: {} })
        .then((res) => {
          if (res.code === 200) {
            fn([...res.data]);
            resolve();
          } else {
            message.error(res.message);
            reject();
          }
        })
        .catch((error) => {
          reject();
        });
    });
  }

  useEffect(() => {
    getInfo("companyGetExp", setExp);
    getInfo("companyGetEdu", setEdu);
  }, []);

  const onFinish = (values) => {
    props.search(values);
  };
  return (
    <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={8}>
          {/* <LinkSelect
            name="provinceCode"
            label="地区"
            className="pg-entrance-form_item"
            placeholder="请选择省市县"
            //   defaultValue={formDefaultValue.work_code}
          /> */}
          <Form.Item name="cities" label="意向地区">
            <Input placeholder="请输入搜索关键词" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="occupation" label="职位">
            <Input placeholder="请输入搜索关键词" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="education" label="学历">
            <Input placeholder="请输入搜索关键词" />
          </Form.Item>
          {/* <Form.Item name="educationId" label="学历">
            <Select
              options={
                (edu.length &&
                  edu.map((item, key) => ({
                    label: item.title,
                    value: item.id,
                  }))) ||
                []
              }
            />
          </Form.Item> */}
        </Col>
        <Col span={8}>
          <Form.Item name="experience" label="经验">
            <Input placeholder="请输入搜索关键词" />
          </Form.Item>
          {/* <Form.Item name="experienceId" label="经验">
            <Select
              options={
                (exp.length &&
                  exp.map((item, key) => ({
                    label: item.title,
                    value: item.id,
                  }))) ||
                []
              }
            />
          </Form.Item> */}
        </Col>
        <Col span={8}>
          <Form.Item name="sex" label="性别">
            <Select
              options={[
                {
                  value: "",
                  label: "不限",
                },
                {
                  value: "男",
                  label: "男",
                },
                {
                  value: "女",
                  label: "女",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="advantage" label="个人优势">
            <Input placeholder="请输入搜索关键词" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => {
              form.resetFields();
              props.search(false);
            }}
          >
            清空
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default class BizSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      psList: [],
      issueList: [],
      tableInfo: [
        {
          title: "申请时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "姓名",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "性别",
          dataIndex: "sex",
          key: "sex",
        },
        {
          title: "年龄",
          dataIndex: "age",
          key: "age",
        },
        {
          title: "学历",
          dataIndex: "education",
          key: "education",
        },
        {
          title: "经验",
          dataIndex: "experience",
          key: "experience",
        },
        {
          title: "意向职业",
          dataIndex: "occupation",
          key: "occupation",
        },
        {
          title: "意向地区",
          dataIndex: "cities",
          key: "cities",
        },
        {
          title: "个人优势",
          dataIndex: "advantage",
          key: "advantage",
          width: 200,
          render: (text, data) => <div style={{ fontSize: 12 }}>{text}</div>,
        },
        {
          title: "操作",
          dataIndex: "",
          key: "op",
          render: (value, item) => {
            const detailOp = (
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  this.download(item.id);
                }}
              >
                下载简历
              </Button>
            );
            return <Space size="middle">{detailOp}</Space>;
          },
        },
      ],
    };
  }

  componentWillMount = () => {
    this.getPsList();
    this.getIssue();
  };

  download = (id) => {
    httpPromise({
      apiDemo: "companyDownloadResume",
      params: {
        ids: id + "",
      },
    })
      .then((res) => {
        console.log(1);
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("操作成功，如需查看请到下载中心查看");
          this.getPsList();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleTableChange = ({ current }, filters, sorter) => {
    let { pagination } = this.state;
    pagination.pageNo = current;
    this.setState({ pagination });
    this.getPsList();
  };

  search = (values) => {
    console.log("Received values of form: ", values);
    if (values === false) {
      this.setState(
        {
          pagination: {
            pageNo: 1,
            pageSize: 10,
          },
        },
        () => {
          this.getPsList();
        }
      );
    } else {
      let params = {};
      Object.keys(values).forEach((k) => {
        if (values[k] !== undefined) {
          if (k == "address") {
            params["provinceCode"] = values[k][0];
            params["cityCode"] = values[k][1];
            params["areaCode"] = values[k][2];
          } else {
            params[k] = values[k];
          }
        }
      });

      this.setState(
        {
          pagination: {
            pageNo: 1,
            pageSize: 10,
            ...params,
          },
        },
        () => {
          this.getPsList();
        }
      );
    }
  };

  getPsList = () => {
    this.setState({ loading: true });
    let { pagination } = this.state;
    httpPromise({
      apiDemo: "companySearchResumeList",
      params: { ...pagination },
    })
      .then((res) => {
        console.log(1);
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState(
            {
              psList: res.data.data,
              pagination: { ...pagination, total: res.data.total },
            },
            () => {
              console.log(this.state.pagination);
            }
          );
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getIssue = () => {
    this.setState({ loading: true });
    let { pagination } = this.state;
    httpPromise({ apiDemo: "companyIssue" })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({ issueList: res.data });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  continue = (id) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyEditJob",
      params: {
        id,
        state: 1,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getPsList();
          message.success("操作成功");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  pause = (id) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyEditJob",
      params: {
        id,
        state: 0,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getPsList();
          message.success("操作成功");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  over = (id) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyEditJob",
      params: {
        id,
        state: 2,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getPsList();
          message.success("操作成功");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toTop = (id) => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companySetTop", params: { id } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("置顶成功");
          this.getPsList();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toRefre = (id) => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companySetRefresh", params: { id } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("刷新成功");
          this.getPsList();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    let { psList, tableInfo, pagination, loading, issueList } = this.state;
    return (
      <main className="pg-biz-recruit">
        <Row gutter="24">
          <Col span={18}>
            <Alert
              message="温馨提示：线上HR推荐人才到企业面试，企业需及时更改求职者状态。"
              type="warning"
            />
            <section
              className="md-section border"
              style={{ marginTop: "24px" }}
            >
              <header
                className="md-section-header"
                style={{ position: "relative" }}
              >
                人才精选
              </header>
              <div className="md-section-body">
                <div style={{ marginBottom: "15px" }}>
                  <AdvancedSearchForm search={this.search} />
                </div>
                <Table
                  columns={tableInfo}
                  dataSource={psList}
                  onChange={this.handleTableChange}
                  pagination={{
                    current: pagination.pageNo,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showSizeChanger: false,
                  }}
                  loading={loading}
                  rowKey="id"
                />
              </div>
            </section>
          </Col>
          <Col span={6}>
            <section className="md-section border">
              <header className="md-section-header">常见问题</header>
              {issueList.length ? (
                <Collapse accordion className="md-section-body">
                  {issueList.map((item) => (
                    <Panel header={item.title} key={item.id}>
                      <p>{item.content}</p>
                    </Panel>
                  ))}
                </Collapse>
              ) : (
                <div className="md-section-body">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </section>
          </Col>
        </Row>
      </main>
    );
  }
}
