import React from "react";
import { Link } from "react-router-dom";
import {
  Descriptions,
  Card,
  Button,
  Typography,
  Spin,
  message,
  Pagination,
  Row,
  Col,
  Empty,
  Collapse,
  Modal,
  Table,
  Form,
  InputNumber,
  Tag,
} from "antd";
import {
  DoubleRightOutlined,
  MoneyCollectOutlined,
  VerticalAlignTopOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { QRCodeCanvas } from "qrcode.react";
import "./index.less";
import httpPromise from "@/api";
const { Text } = Typography;
const { Panel } = Collapse;
export default class BizAccount extends React.Component {
  timeRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      pageTotal: 0,
      userInfo: {},
      jobList: [],
      issueList: [],
      moneyList: [
        {
          field: "balance",
          title: "人才币",
          icon: <MoneyCollectOutlined />,
        },
        {
          field: "top_number",
          title: "置顶次数",
          icon: <VerticalAlignTopOutlined />,
        },
        {
          field: "refresh_number",
          title: "刷新次数",
          icon: <InteractionOutlined />,
        },
      ],
      balance: {},
      peopleList: [
        {
          title: "S级",
          link: require("../../atess/image/S01.png"),
          number: 0,
        },
        {
          title: "A级",
          link: require("../../atess/image/A01.png"),
          number: 0,
        },
        {
          title: "B级",
          link: require("../../atess/image/B01.png"),
          number: 0,
        },
        {
          title: "C级",
          link: require("../../atess/image/C01.png"),
          number: 0,
        },
      ],
      shareList: [
        {
          link: require("../../atess/image/S01.png"),
          number: 0,
        },
        {
          link: require("../../atess/image/A01.png"),
          number: 0,
        },
        {
          link: require("../../atess/image/B01.png"),
          number: 0,
        },
        {
          link: require("../../atess/image/C01.png"),
          number: 0,
        },
        {
          title: "无效",
          type: "text-gry2",
          number: 0,
        },
        {
          title: "审核中",
          type: "text-error",
          number: 0,
        },
      ],
      visible: false,
      visible1: false,
      dataSource: [],
      rechargeQrcode: "",
      rechargeVisible: false,
      rechargeParams: {},
      money: 100,
      payList: [],
      payPagination: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
    };
    this.columns = [
      {
        title: "人才币",
        dataIndex: "balance",
        key: "balance",
        render: (text, data) =>
          (data.balance_type == 1 && `+ ${text}`) || `- ${text}`,
      },
      {
        title: "类型",
        dataIndex: "balance_type",
        key: "balance_type",
        render: (text, data) => (text == 1 && "收入") || "支出",
      },
      {
        title: "明细",
        dataIndex: "text",
        key: "text",
        render: (text, data) => `【${data.tag_name}】 ${data.text}`,
      },
      {
        title: "创建时间",
        dataIndex: "create_time",
        key: "create_time",
      },
    ];
  }

  componentWillMount = () => {
    this.getUserInfo();
  };

  getList = async (paginations) => {
    httpPromise({ apiDemo: "companyUserLog", params: { ...paginations } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({
            payList: res.data.data,
            payPagination: { ...paginations, total: res.data.total },
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  paginationChange = (pages) => {
    this.setState({
      payPagination: {
        ...this.state.payPagination,
        pageNo: pages.current || 1,
        current: pages.current,
      },
    });
    this.getList({
      ...this.state.payPagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    console.log("Page: ", pages);
  };

  getUserInfo = () => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      return;
    }
    this.setState({ userInfo });
    this.getJobList();
    this.getIssue();
    this.getbalance();
    this.getList(this.state.payPagination);
  };

  getIssue = () => {
    this.setState({ loading: true });
    let { pagination } = this.state;
    httpPromise({ apiDemo: "companyIssue" })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({ issueList: res.data });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showModal1 = () => {
    this.setState({
      visible1: true,
    });
  };

  handleOk = () => {
    this.setState({ modalLoading: true });
    setTimeout(() => {
      this.setState({ modalLoading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCancel1 = () => {
    this.setState({ visible1: false });
  };

  getJobList = () => {
    this.setState({ loading: true });
    let { pagination } = this.state;
    httpPromise({ apiDemo: "companyGetJbList", params: { ...pagination } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({
            jobList: res.data.data,
            pageTotal: res.data.pageTotal,
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getbalance = () => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companyGetBalance" })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({ balance: res.data });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  changePage = (page) => {
    let { pagination } = this.state;
    pagination.pageNo = page;
    this.setState({ pagination });
    this.getJobList();
  };
  rechargeCall = async () => {
    this.setState({ rechargeVisible: true });
  };
  getQrcode = async () => {
    clearInterval(this.timeRef.current);
    httpPromise({
      apiDemo: "companyRecharge",
      params: {
        id: this.state.userInfo.id,
        type: 2,
        money: this.state.money,
      },
    })
      .then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.setState({
            rechargeQrcode: res.data.code_url,
            rechargeParams: {
              order_id: res.data.order_id,
            },
          });

          this.timeRef.current = setInterval(async () => {
            httpPromise({
              apiDemo: "companyPayStatus",
              params: {
                id: this.state.rechargeParams.order_id,
              },
            })
              .then((res) => {
                if (res.code === 200) {
                  if (res.data.status == 1) {
                    this.setState({
                      rechargeParams: {},
                      visible: false,
                      rechargeVisible: false,
                    });
                    message.success("已完成充值");
                    this.getbalance();
                    clearInterval(this.timeRef.current);
                  }
                }
              })
              .catch((error) => {
                console.log(error);
                clearInterval(this.timeRef.current);
              });
          }, 3000);
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  rechargeOk = async () => {
    this.getbalance();
    this.setState({ visible: false, rechargeVisible: false });
  };
  rechargeCancel = () => {
    this.setState({ rechargeVisible: false });
  };

  render() {
    let {
      userInfo,
      jobList,
      peopleList,
      shareList,
      moneyList,
      pageTotal,
      pagination,
      issueList,
      balance,
      visible,
      visible1,
      rechargeQrcode,
      rechargeVisible,
      rechargeParams,
      money,
      payList,
      payPagination,
    } = this.state;
    return (
      <main className="pg-biz-account">
        <Row gutter="24">
          <Col span={18}>
            <section className="md-section border">
              <header className="md-section-header">基本信息</header>
              <Descriptions column={2}>
                <Descriptions.Item label="联系人">
                  {userInfo.contact}
                </Descriptions.Item>
                <Descriptions.Item label="联系电话">
                  {userInfo.mobile}
                </Descriptions.Item>
                <Descriptions.Item label="企业名称">
                  {userInfo.name}
                </Descriptions.Item>
                <Descriptions.Item label="企业地址">
                  {userInfo.provinces_name +
                    userInfo.cities_name +
                    userInfo.areas_name +
                    userInfo.address}
                </Descriptions.Item>
                <Descriptions.Item label="企业简介">
                  {userInfo.intro}
                </Descriptions.Item>
              </Descriptions>
            </section>
            <section className="md-section border">
              <header className="md-section-header">
                我的职位
                <Link className="md-section-header_right" to="/biz/recruit">
                  查看所有职位 <DoubleRightOutlined />
                </Link>
              </header>
              <Spin spinning={this.state.loading}>
                <Row gutter={[24, 24]}>
                  {jobList.map((item) => (
                    <Col sm={24} md={12} lg={8} key={item.id}>
                      <Card
                        onClick={() => {
                          this.props.history.push(
                            `/biz/recruit/detail/${item.id}`
                          );
                        }}
                        hoverable={true}
                        title={
                          <Button
                            type="link"
                            style={{ padding: "0", fontSize: 18 }}
                          >
                            {item.occupation_name}
                          </Button>
                        }
                        extra={
                          <Text type="danger" className="fs-18">
                            {item.brokerage} 币/人
                          </Text>
                        }
                      >
                        <Descriptions column={{ sm: 1 }} size="small">
                          <Descriptions.Item label="薪资范围">
                            {item.min_salary_name} - {item.max_salary_name}
                          </Descriptions.Item>
                          <Descriptions.Item label="工作经验">
                            {item.experience_name}
                          </Descriptions.Item>
                          <Descriptions.Item label="职位名称">
                            {item.title}
                          </Descriptions.Item>
                        </Descriptions>
                      </Card>
                    </Col>
                  ))}
                </Row>
                <Pagination
                  current={pagination.pageNo}
                  pageSize={pagination.pageSize}
                  total={pageTotal}
                  responsive={true}
                  onChange={this.changePage}
                  style={{ textAlign: "right" }}
                />
              </Spin>
              <div className="md-section-footer">
                <Button type="primary">
                  <Link to="/biz/recruit/add">立即发布职位</Link>
                </Button>
              </div>
            </section>
            <Row gutter={20} style={{ marginTop: 20 }}>
              <Col span={12}>
                <section className="md-section border">
                  <header className="md-section-header">
                    企业人才{" "}
                    <Link
                      className="md-section-header_right"
                      to="/biz/download"
                    >
                      查看已下载简历 <DoubleRightOutlined />
                    </Link>
                  </header>
                  <Row gutter={16} className="md-section-body">
                    {peopleList.map((item, index) => {
                      return (
                        <Col
                          className="md-section-rich"
                          span={6}
                          key={index}
                          style={{
                            justifyContent: "center",
                            padding: "24px 0",
                          }}
                        >
                          <span className="fs-30 lh-50">{item.number}</span>
                          <span className="lh-50">
                            <img src={item.link} />
                          </span>
                        </Col>
                      );
                    })}
                  </Row>
                  <div className="btn-box" style={{ textAlign: "center" }}>
                    <Button type="primary" href="/biz/search">
                      搜索更多简历
                    </Button>
                  </div>
                </section>
              </Col>
              <Col span={12}>
                <section className="md-section border">
                  <header className="md-section-header">
                    分享简历{" "}
                    <Link className="md-section-header_right" to="/biz/share">
                      查看已分享简历 <DoubleRightOutlined />
                    </Link>
                  </header>
                  <Row gutter={16} className="md-section-body">
                    {shareList.map((item, index) => {
                      return (
                        <Col
                          className={`md-section-rich ${item.type || ""}`}
                          span={4}
                          key={index}
                          style={{
                            justifyContent: "center",
                            padding: "24px 0",
                          }}
                        >
                          <span className="fs-30 lh-50">{item.number}</span>
                          <span className="lh-50">
                            {item.title || <img src={item.link} />}
                          </span>
                        </Col>
                      );
                    })}
                  </Row>
                  <div className="btn-box" style={{ textAlign: "center" }}>
                    <Button type="primary" href="/biz/share">
                      分享更多简历
                    </Button>
                  </div>
                </section>
              </Col>
            </Row>

            <section className="md-section border" style={{ marginTop: 20 }}>
              <header className="md-section-header">收支明细</header>

              <div className="table">
                <Table
                  columns={this.columns}
                  dataSource={payList}
                  pagination={{
                    pageSize: payPagination.pageSize,
                    total: payPagination.total,
                  }}
                  onChange={this.paginationChange}
                />
              </div>
            </section>
          </Col>
          <Col span={6}>
            <section className="md-section border">
              <header className="md-section-header">
                账户信息{" "}
                <div className="ml-auto">
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1"
                    style={{ height: "auto" }}
                    onClick={() => {
                      this.showModal();
                    }}
                  >
                    充值人才币
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    style={{ height: "auto" }}
                    onClick={() => {
                      this.showModal1();
                    }}
                  >
                    人才币说明
                  </Button>
                </div>
              </header>
              <Row gutter={16} className="md-section-body">
                {moneyList.map((item, index) => {
                  return (
                    <Col className="md-section-rich" span={8} key={index}>
                      <span className="fs-24 lh-50 text-error">
                        {balance ? balance[item.field] : 0}
                      </span>
                      <span className="lh-30">{item.title}</span>
                    </Col>
                  );
                })}
              </Row>
            </section>
            <section className="md-section border">
              <header className="md-section-header">常见问题</header>
              {issueList.length ? (
                <Collapse accordion className="md-section-body">
                  {issueList.map((item) => (
                    <Panel header={item.title} key={item.id}>
                      <p>{item.content}</p>
                    </Panel>
                  ))}
                </Collapse>
              ) : (
                <div className="md-section-body">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </section>
          </Col>
        </Row>
        <Modal
          visible={visible}
          title="增加人才币"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="600px"
          footer={[
            <Button
              key="submit"
              type="primary"
              //   loading={modalLoading}
              //   onClick={this.handleOk}
            >
              分享简历
            </Button>,
            <Button
              key="link"
              type="primary"
              //   loading={modalLoading}
              onClick={this.rechargeCall}
            >
              在线充值
            </Button>,
          ]}
        >
          <div>
            <p>
              可通过以下方式快速获取人才币：<b>分享简历</b>或<b>联系客服</b>
              在线充值。相关规则，说明如下。
            </p>
            <p>
              上传审核通过后，
              <span style={{ color: "rgb(151,57,230)", fontSize: "16px" }}>
                S级
              </span>
              可获得<span style={{ color: "red", fontSize: "16px" }}>8</span>
              个人才币； 上传审核通过后，
              <span style={{ color: "orange", fontSize: "16px" }}>A级</span>
              可获得<span style={{ color: "red", fontSize: "16px" }}>6</span>
              个人才币
            </p>
            <p>
              上传审核通过后，
              <span style={{ color: "green", fontSize: "16px" }}>B级</span>
              可获得<span style={{ color: "red", fontSize: "16px" }}>2</span>
              个人才币； 上传审核通过后，
              <span style={{ color: "skyblue", fontSize: "16px" }}>C级</span>
              可获得<span style={{ color: "red", fontSize: "16px" }}>0</span>
              个人才币
            </p>
            <p>联系客服，获取更多人才币。</p>
          </div>
        </Modal>

        <Modal
          visible={rechargeVisible}
          title="微信扫码支付充值人才币"
          onOk={this.rechargeOk}
          onCancel={this.rechargeCancel}
          width="400px"
          footer={[
            <Button key="submit" type="primary" onClick={this.rechargeOk}>
              完成充值
            </Button>,
            <Button key="link" onClick={this.rechargeCancel}>
              取消
            </Button>,
          ]}
        >
          <div className="mb-3">
            <Form.Item
              label="充值金额"
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 17 }}
            >
              <Row gutter={30}>
                <Col span={12}>
                  <InputNumber
                    min={0.01}
                    style={{ width: "100%" }}
                    value={money}
                    onChange={(val) => {
                      this.setState({
                        money: val,
                      });
                    }}
                  />
                </Col>
                <Col span={12}>
                  <Button
                    onClick={() => {
                      localStorage.setItem("paymain", money);
                      window.location.href = "/paymain";
                    }}
                  >
                    去支付
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </div>
          {rechargeQrcode && (
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <QRCodeCanvas width={200} value={rechargeQrcode} />
            </div>
          )}
        </Modal>
        <Modal
          visible={visible1}
          title="人才币说明"
          onOk={this.handleOk}
          onCancel={this.handleCancel1}
          width="600px"
          footer={[
            <Button key="link" type="primary" onClick={this.handleCancel1}>
              确定
            </Button>,
          ]}
        >
          <div>
            <p>
              <b>1. 人才币的获得？</b>
            </p>
            <p>
              企业在各自后台“分享简历”中上传一条求职者简历信息，审核通过后即可获得相应数量的人才币。
            </p>
            <p>
              <b>2. 人才币的使用？</b>
            </p>
            <p>
              人才币是速猎直推人才云聘中用于下载简历，使用面试申请，代理招聘等功能的虚拟货币。
            </p>
          </div>
        </Modal>
      </main>
    );
  }
}
