import React from "react";
import "./index.less";
import { Row, Col } from "antd";

export default class footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      style: this.props.style,
    };
  }
  render() {
    const style = this.state.style;
    return (
      <footer>
        <div className="px-3">
          <div className="footer-links">
            <Row gutter={30}>
              <Col span={4}>
                <div className="footer-links-block">
                  <div className="footer-links-title">首页</div>
                  <ul className="footer-links-list">
                    <li className="footer-links-item">
                      <a
                        title="关于我们"
                        className="footer-links-link"
                        href="/about"
                      >
                        关于我们
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="招聘流程"
                        className="footer-links-link"
                        href="/service"
                      >
                        招聘流程
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="服务优势"
                        className="footer-links-link"
                        href="/service"
                      >
                        服务优势
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col span={4}>
                <div className="footer-links-block">
                  <div className="footer-links-title">关于我们</div>
                  <ul className="footer-links-list">
                    <li className="footer-links-item">
                      <a
                        title="解决问题"
                        className="footer-links-link"
                        href="/about"
                      >
                        解决问题
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="平台特色"
                        className="footer-links-link"
                        href="/about"
                      >
                        平台特色
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="热门岗位"
                        className="footer-links-link"
                        href="/about"
                      >
                        热门岗位
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="创新机制"
                        className="footer-links-link"
                        href="/about"
                      >
                        创新机制
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="人才来源"
                        className="footer-links-link"
                        href="/about"
                      >
                        人才来源
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="HR渠道"
                        className="footer-links-link"
                        href="/about"
                      >
                        HR渠道
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="付费方式"
                        className="footer-links-link"
                        href="/about"
                      >
                        付费方式
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col span={4}>
                <div className="footer-links-block">
                  <div className="footer-links-title">招聘流程</div>
                  <ul className="footer-links-list">
                    <li className="footer-links-item">
                      <a title="流程图" className="footer-links-link" href="#">
                        流程图
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="使用感受"
                        className="footer-links-link"
                        href="#"
                      >
                        使用感受
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="招聘图谱"
                        className="footer-links-link"
                        href="#"
                      >
                        招聘图谱
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col span={4}>
                <div className="footer-links-block">
                  <div className="footer-links-title">服务优势</div>
                  <ul className="footer-links-list">
                    <li className="footer-links-item">
                      <a
                        title="我们的特点"
                        className="footer-links-link"
                        href="/service"
                      >
                        我们的特点
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="我们的优点"
                        className="footer-links-link"
                        href="/service"
                      >
                        我们的优点
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="模式对比"
                        className="footer-links-link"
                        href="/service"
                      >
                        模式对比
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="服务优势"
                        className="footer-links-link"
                        href="/service"
                      >
                        服务优势
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="模式场景"
                        className="footer-links-link"
                        href="/service"
                      >
                        模式场景
                      </a>
                    </li>
                    <li className="footer-links-item">
                      <a
                        title="人才转让"
                        className="footer-links-link"
                        href="/service"
                      >
                        人才转让
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col span={8}>
                <div className="footer-links-block">
                  <div className="footer-links-title">服务咨询</div>
                  <ul className="footer-links-list">
                    <li className="footer-links-item">
                      <div
                        className="d-flex align-item-center text-center"
                        style={{ color: "#333" }}
                      >
                        <div className="mr-1">
                          <img
                            src={require("@/atess/image/gongzhonghao.jpg")}
                            style={{ width: "100px" }}
                          />
                          <div className="mt-1">公众号</div>
                        </div>
                        <div className="mr-1">
                          <img
                            src={require("@/atess/image/qrcode.png")}
                            style={{ width: "100px" }}
                          />
                          <div className="mt-1">在线客服</div>
                        </div>
                        <div className="mr-1">
                          <img
                            src={require("@/atess/image/16684140167137.png")}
                            style={{ width: "100px" }}
                          />
                          <div className="mt-1">客户经理</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className="copyright">
            Copyright ©2022 速猎直推招聘平台{" "}
            <a href="https://beian.miit.gov.cn/" target="_blank">
              沪ICP备2022028284号-2
            </a>
          </div>
        </div>
      </footer>
    );
  }
}
