import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  Alert,
  Space,
  Empty,
  Typography,
  message,
  Row,
  Col,
  Collapse,
  Form,
  Upload,
  Modal,
  Input,
  DatePicker,
  Select,
  InputNumber,
} from "antd";

import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import "./index.less";
import httpPromise from "@/api";
import moment from "moment";
import LinkAge from "../../components/linkage";

const { Text } = Typography;
const { Panel } = Collapse;

const UploadFiles = ({ visible, setVisible, sub, cancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [backgroundFileList, setBackgroundFileList] = useState([]);

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={850}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        sub(form.getFieldsValue());
      }}
      onCancel={() => {
        cancel();
      }}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>导入简历</h1>
          </div>
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {}}
          >
            <div>
              <Form.Item name="file" label="导入简历">
                <Upload
                  name="background"
                  listType="picture-card"
                  maxCount={1}
                  fileList={backgroundFileList}
                  beforeUpload={(file, beforeUploadFileList) => {
                    let thumbUrl =
                      require("../../atess/image/excel.png").default;
                    setBackgroundFileList([{ ...file, thumbUrl: thumbUrl }]);
                    return false;
                  }}
                >
                  <div>
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      点击上传简历模板
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="px-3 py-2"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                sub(form.getFieldsValue());
              }}
            >
              确认导入
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const CreateShopForm = ({ visible, info = {}, onCreate, cancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [education, setEducation] = useState([]);
  const [salary, setSalary] = useState([]);
  const [experience, setExperience] = useState([]);
  const [occupation, setOccupation] = useState([]);
  useEffect(() => {
    form.resetFields();
    if (visible) {
      getTable("education");
      getTable("experience");
      getTable("salary");
      getTable("occupation");
      // if (
      //   info?.experienceList?.length ||
      //   info?.educationList?.length ||
      //   info?.projectList?.length
      // ) {
      //   console.log(info);
      //   info.experienceList.map((item, ind) => {
      //     info.experienceList[ind].entry_time = moment(item.entry_time);
      //     info.experienceList[ind].depart_time = moment(item.depart_time);
      //   });
      //   info.educationList.map((item, ind) => {
      //     info.educationList[ind].start_date = moment(item.start_date);
      //     info.educationList[ind].end_date = moment(item.end_date);
      //   });
      //   info.projectList.map((item, ind) => {
      //     info.projectList[ind].start_date = moment(item.start_date);
      //     info.projectList[ind].end_date = moment(item.end_date);
      //   });
      //   form.setFieldsValue({
      //     ...info,
      //   });
      //   setFormVals(info);
      // }
    }
  }, [visible]);

  const getTable = async (type) => {
    httpPromise({
      apiDemo: "companyGetTable",
      params: {
        table: type,
      },
    })
      .then((res) => {
        if (res.code == 200) {
          if (type == "education") {
            setEducation([
              ...res.data.map((item) => ({
                label: item.title,
                value: item.title,
              })),
            ]);
          }
          if (type == "experience") {
            setExperience([
              ...res.data.map((item) => ({
                label: item.title,
                value: item.title,
              })),
            ]);
          }
          if (type == "salary") {
            setSalary([
              ...res.data.map((item) => ({
                label: item.title,
                value: item.title,
              })),
            ]);
          }
          if (type == "occupation") {
            setOccupation([
              ...res.data.map((item) => ({
                label: item.title,
                value: item.title,
              })),
            ]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Modal
      getContainer={false}
      open={visible}
      width={850}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {}}
      onCancel={() => {
        cancel();
      }}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1 style={{ fontSize: 22 }}>
              {info.id ? "修改" : "创建"}简历{" "}
              <small className="fs-14" style={{ color: "#999", fontSize: 12 }}>
                (请保证输入的信息真实有效，平台将进行核实，请勿发布虚假不实信息)
              </small>
            </h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              setFormVals(allVals);
              console.log(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <div className="page-header">
                <div className="title mb-3">基础信息</div>
              </div>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="name" label="真实姓名">
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="age" label="年龄">
                    <InputNumber
                      placeholder="请输入"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="mobile" label="联系方式">
                    <Input maxLength={11} placeholder="请输入" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="experience" label="工作经验">
                    <Select options={experience} placeholder="请选择" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sex" label="性别">
                    <Select
                      optionType="button"
                      options={[
                        {
                          label: "男",
                          value: "男",
                        },
                        {
                          label: "女",
                          value: "女",
                        },
                      ]}
                      placeholder="请选择"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="education" label="学历">
                    <Select options={education} placeholder="请选择" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="state" label="求职状态">
                    <Select
                      options={[
                        {
                          label: "在职-月内到岗",
                          value: "在职-月内到岗",
                        },
                        {
                          label: "离职-随时到岗",
                          value: "离职-随时到岗",
                        },
                        {
                          label: "在职-考虑机会",
                          value: "在职-考虑机会",
                        },
                        {
                          label: "在职-暂不考虑",
                          value: "在职-暂不考虑",
                        },
                      ]}
                      placeholder="请选择"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="page-header">
                <div className="title mb-3">求职意向</div>
              </div>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="occupation" label="意向职位">
                    <Select options={occupation} placeholder="请选择" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="cities" label="意向城市">
                    <Input placeholder="请填写" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="期望薪资">
                    <Row gutter={30}>
                      <Col span={11}>
                        <Form.Item name="min_salary" className="m-0">
                          <Select options={salary} placeholder="请选择" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <div
                          className="text-center"
                          style={{ lineHeight: "30px" }}
                        >
                          ~
                        </div>
                      </Col>
                      <Col span={11}>
                        <Form.Item name="max_salary" className="m-0">
                          <Select options={salary} placeholder="请选择" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              <div className="page-header">
                <div className="title mb-3">个人优势</div>
              </div>
              <Form.Item name="advantage">
                <Input.TextArea placeholder="请填写" />
              </Form.Item>

              <div className="page-header">
                <div className="title mb-3">社交主页</div>
              </div>
              <Form.Item name="page">
                <Input placeholder="选填" />
              </Form.Item>
              <div className="page-header">
                <div className="title mb-3">工作经历</div>
              </div>
              <Form.Item name="expDelIds" hidden>
                <Input />
              </Form.Item>
              <Form.List name="experienceList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "block",
                          background: "rgb(248,248,248)",
                          padding: 15,
                          marginBottom: 20,
                          position: "relative",
                        }}
                        align="baseline"
                      >
                        <MinusCircleOutlined
                          onClick={() => {
                            if (formVals.experienceList[name]?.id) {
                              formVals.expDelIds =
                                (formVals.expDelIds &&
                                  formVals.expDelIds?.split(",")) ||
                                [];
                              formVals.expDelIds.push(
                                formVals.experienceList[name]?.id
                              );
                              form.setFieldsValue({
                                expDelIds: formVals.expDelIds.join(","),
                              });
                              formVals.expDelIds = formVals.expDelIds.join(",");
                            }
                            remove(name);
                          }}
                          style={{
                            position: "absolute",
                            top: -5,
                            right: -5,
                          }}
                        />
                        <Form.Item {...restField} name={[name, "id"]} hidden>
                          <Input />
                        </Form.Item>
                        <Row gutter={30}>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "corporate_name"]}
                              label="公司名称"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "corporate_industry"]}
                              label="公司行业"
                            >
                              <Select
                                options={occupation}
                                placeholder="请选择"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={30}>
                          <Col span={12}>
                            <Form.Item label="在职时间">
                              <Row gutter={30}>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "entry_time"]}
                                    className="m-0"
                                  >
                                    <DatePicker placeholder="请选择" />
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <div
                                    className="text-center"
                                    style={{ lineHeight: "30px" }}
                                  >
                                    ~
                                  </div>
                                </Col>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "depart_time"]}
                                    className="m-0"
                                  >
                                    <DatePicker placeholder="请选择" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "position"]}
                              label="职位名称"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          {...restField}
                          name={[name, "work_content"]}
                          label="工作内容"
                        >
                          <Input.TextArea placeholder="请填写" />
                        </Form.Item>
                        <Row gutter={30}>
                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              name={[name, "pay"]}
                              label="月薪(选填)"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              name={[name, "department"]}
                              label="所属部门(选填)"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              name={[name, "report"]}
                              label="汇报对象(选填)"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              name={[name, "subordinates"]}
                              label="下属人数(选填)"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          {...restField}
                          name={[name, "work_location"]}
                          label="工作地点(选填)"
                        >
                          <Input placeholder="请填写" />
                        </Form.Item>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        添加工作经历
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <div className="page-header">
                <div className="title mb-3">教育经历</div>
              </div>
              <Form.Item name="eduDelIds" hidden>
                <Input />
              </Form.Item>
              <Form.List name="educationList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "block",
                          background: "rgb(248,248,248)",
                          padding: 15,
                          marginBottom: 20,
                          position: "relative",
                        }}
                        align="baseline"
                      >
                        <MinusCircleOutlined
                          onClick={() => {
                            if (formVals.educationList[name]?.id) {
                              formVals.eduDelIds =
                                (formVals.eduDelIds &&
                                  formVals.eduDelIds?.split(",")) ||
                                [];
                              formVals.eduDelIds.push(
                                formVals.educationList[name]?.id
                              );
                              form.setFieldsValue({
                                eduDelIds: formVals.eduDelIds.join(","),
                              });
                              formVals.eduDelIds = formVals.eduDelIds.join(",");
                            }
                            remove(name);
                          }}
                          style={{
                            position: "absolute",
                            top: -5,
                            right: -5,
                          }}
                        />

                        <Form.Item {...restField} name={[name, "id"]} hidden>
                          <Input />
                        </Form.Item>
                        <Row gutter={30}>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "school_name"]}
                              label="学校名称"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "education"]}
                              label="学历"
                            >
                              <Select
                                options={education}
                                placeholder="请选择"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={30}>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "professional_name"]}
                              label="专业名称"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="在校时间">
                              <Row gutter={30}>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "start_date"]}
                                    className="m-0"
                                  >
                                    <DatePicker placeholder="请选择" />
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <div
                                    className="text-center"
                                    style={{ lineHeight: "30px" }}
                                  >
                                    ~
                                  </div>
                                </Col>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "end_date"]}
                                    className="m-0"
                                  >
                                    <DatePicker placeholder="请选择" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        添加教育经历
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <div className="page-header">
                <div className="title mb-3">项目经历</div>
              </div>
              <Form.Item name="proDelIds" hidden>
                <Input />
              </Form.Item>
              <Form.List name="projectList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "block",
                          background: "rgb(248,248,248)",
                          padding: 15,
                          marginBottom: 20,
                          position: "relative",
                        }}
                        align="baseline"
                      >
                        <MinusCircleOutlined
                          onClick={() => {
                            if (formVals.projectList[name]?.id) {
                              formVals.proDelIds =
                                (formVals.proDelIds &&
                                  formVals.proDelIds?.split(",")) ||
                                [];
                              formVals.proDelIds.push(
                                formVals.projectList[name]?.id
                              );
                              form.setFieldsValue({
                                proDelIds: formVals.proDelIds.join(","),
                              });
                              formVals.proDelIds = formVals.proDelIds.join(",");
                            }
                            remove(name);
                          }}
                          style={{
                            position: "absolute",
                            top: -5,
                            right: -5,
                          }}
                        />

                        <Form.Item {...restField} name={[name, "id"]} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "product_name"]}
                          label="项目名称"
                        >
                          <Input placeholder="请填写" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "product_intro"]}
                          label="项目描述"
                        >
                          <Input.TextArea placeholder="请填写" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "achieve"]}
                          label="项目成就"
                        >
                          <Input.TextArea placeholder="请填写" />
                        </Form.Item>
                        <Row gutter={30}>
                          <Col span={12}>
                            <Form.Item label="项目时间">
                              <Row gutter={30}>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "start_date"]}
                                    className="m-0"
                                  >
                                    <DatePicker placeholder="请选择" />
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <div
                                    className="text-center"
                                    style={{ lineHeight: "30px" }}
                                  >
                                    ~
                                  </div>
                                </Col>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "end_date"]}
                                    className="m-0"
                                  >
                                    <DatePicker placeholder="请选择" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "link"]}
                              label="项目链接(选填)"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        添加项目经历
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item name="education" hidden>
                <Input />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-1 px-2"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                onCreate(form.getFieldValue());
                console.log(form.getFieldValue());
              }}
            >
              {info.id ? "修改" : "创建"}简历
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default class BizShare extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      visibleNew: false,
      visibleNewInfo: {},
      psList: [],
      issueList: [],
      tableInfo: [
        {
          title: "申请时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "联系信息",
          dataIndex: "name",
          key: "name",
          render: (text, item) => (
            <div
              onClick={async () => {
                let res = this.setState({
                  visibleNew: true,
                  visibleNewInfo: item,
                });
              }}
              style={{ color: "#1890ff", cursor: "pointer" }}
            >
              <div>{text}</div>
              <div>{item.mobile}</div>
            </div>
          ),
        },
        {
          title: "性别",
          dataIndex: "sex",
          key: "sex",
        },
        {
          title: "年龄",
          dataIndex: "age",
          key: "age",
        },
        {
          title: "学历",
          dataIndex: "education",
          key: "education",
        },
        {
          title: "经验",
          dataIndex: "experience",
          key: "experience",
        },
        {
          title: "意向职业",
          dataIndex: "occupation",
          key: "occupation",
        },
        {
          title: "意向地区",
          dataIndex: "cities",
          key: "cities",
        },
        {
          title: "个人优势",
          dataIndex: "advantage",
          key: "advantage",
        },
        {
          title: "操作",
          render: (text, data) => {
            return (
              <div>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    this.delResume(data);
                  }}
                >
                  删除
                </Button>
              </div>
            );
          },
        },
      ],
      uploadVisible: false,
      visibleCreate: false,
      visibleInfo: {},
    };
  }

  componentWillMount = () => {
    this.getPsList();
    this.getIssue();
  };

  handleTableChange = ({ current }, filters, sorter) => {
    let { pagination } = this.state;
    pagination.pageNo = current;
    this.setState({ pagination });
    this.getPsList();
  };

  getPsList = () => {
    this.setState({ loading: true });
    let { pagination } = this.state;
    httpPromise({
      apiDemo: "companyShareResumeList",
      params: { ...pagination },
    })
      .then((res) => {
        console.log(1);
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({
            psList: res.data.data,
            pagination: { ...pagination, total: res.data.total },
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getIssue = () => {
    this.setState({ loading: true });
    let { pagination } = this.state;
    httpPromise({ apiDemo: "companyIssue" })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({ issueList: res.data });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  continue = (id) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyEditJob",
      params: {
        id,
        state: 1,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getPsList();
          message.success("操作成功");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  pause = (id) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyEditJob",
      params: {
        id,
        state: 0,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getPsList();
          message.success("操作成功");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  over = (id) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyEditJob",
      params: {
        id,
        state: 2,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getPsList();
          message.success("操作成功");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toTop = (id) => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companySetTop", params: { id } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("置顶成功");
          this.getPsList();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toRefre = (id) => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companySetRefresh", params: { id } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("刷新成功");
          this.getPsList();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onUploadSub = (vals) => {
    let fileInfo = vals.file;
    vals.file = fileInfo.file;
    const fd = new FormData();
    fd.append("file", fileInfo.file);

    httpPromise({ apiDemo: "companyImportResume", params: fd })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("导入成功");
          this.setState({
            uploadVisible: false,
          });
          this.getPsList();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onCancel = () => {
    this.setState({
      visibleCreate: false,
      visibleInfo: {},
    });
  };

  onCreate = async (values) => {
    let res;
    // console.log(values);
    values.experienceList =
      (values.experienceList &&
        values.experienceList.length &&
        values.experienceList.map((item) => ({
          ...item,
          depart_time: moment(item.depart_time).format("YYYY-MM-DD"),
          entry_time: moment(item.entry_time).format("YYYY-MM-DD"),
        }))) ||
      [];
    values.educationList =
      (values.educationList &&
        values.educationList.length &&
        values.educationList.map((item) => ({
          ...item,
          start_date: moment(item.start_date).format("YYYY-MM-DD"),
          end_date: moment(item.end_date).format("YYYY-MM-DD"),
        }))) ||
      [];
    values.projectList =
      (values.projectList &&
        values.projectList.length &&
        values.projectList.map((item) => ({
          ...item,
          start_date: moment(item.start_date).format("YYYY-MM-DD"),
          end_date: moment(item.end_date).format("YYYY-MM-DD"),
        }))) ||
      [];
    // return;
    // if (values.address.length) {
    //   values.intention_province_code = values.address[0];
    //   values.intention_city_code = values.address[1];
    //   values.intention_area_code = values.address[2];
    // }
    // delete values.address;
    if (this.state.visibleInfo.id) {
      res = await httpPromise({
        apiDemo: "companyUpdateResume",
        params: values,
      });
    } else {
      res = await httpPromise({
        apiDemo: "companyCreateResume",
        params: values,
      });
    }
    if (res.code == 200) {
      message.success(res.data.message);
    } else {
      message.error(res.data.message);
    }
    this.getPsList();
    this.setState({
      visibleCreate: false,
      visibleInfo: {},
    });
  };

  delResume = async (data) => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companyDelResume", params: { id: data.id } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("删除成功");
          this.getPsList();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    let {
      psList,
      tableInfo,
      pagination,
      loading,
      issueList,
      visibleNew,
      visibleNewInfo,
      visibleCreate,
      visibleInfo,
    } = this.state;
    return (
      <main className="pg-biz-recruit">
        <Row gutter="24">
          <Col span={18}>
            <Alert
              message="温馨提示：线上HR推荐人才到企业面试，企业需及时更改求职者状态。"
              type="warning"
            />
            <section
              className="md-section border"
              style={{ marginTop: "24px" }}
            >
              <header
                className="md-section-header"
                style={{ position: "relative" }}
              >
                <span>共享资源</span>

                <Button
                  type="link"
                  ghost
                  className="ml-2"
                  href="http://admin.slzhitui.com/%E7%AE%80%E5%8E%86%E6%A8%A1%E6%9D%BF.xlsx"
                  target="_blank"
                  style={{ marginTop: "-5px" }}
                >
                  获取导入简历模板
                </Button>
                <Button
                  type="primary"
                  size="large"
                  style={{
                    position: "absolute",
                    right: 150,
                    top: -15,
                    paddingLeft: "40px",
                  }}
                  className="md-section-body_ctrlbtn"
                  onClick={() => {
                    this.setState({
                      visibleCreate: true,
                    });
                  }}
                >
                  <span
                    style={{
                      fontSize: "35px",
                      position: "absolute",
                      top: "-12px",
                      left: "9px",
                    }}
                  >
                    +
                  </span>{" "}
                  新建简历
                </Button>
                <Button
                  type="link"
                  size="large"
                  style={{
                    position: "absolute",
                    right: 0,
                    top: -15,
                    paddingLeft: "40px",
                  }}
                  className="md-section-body_ctrlbtn"
                  onClick={() => {
                    this.setState({
                      uploadVisible: true,
                    });
                  }}
                >
                  导入已有简历
                </Button>
              </header>
              <div className="md-section-body">
                <div className="filter"></div>
                <Table
                  columns={tableInfo}
                  dataSource={psList}
                  onChange={this.handleTableChange}
                  pagination={{
                    current: pagination.pageNo,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                  }}
                  loading={loading}
                  rowKey="id"
                />
              </div>
            </section>
            <UploadFiles
              visible={this.state.uploadVisible}
              setVisible={this.setState}
              sub={this.onUploadSub}
              cancel={() => {
                this.setState({
                  uploadVisible: false,
                });
              }}
            />

            {/* 创建 */}
            {visibleCreate && (
              <CreateShopForm
                visible={visibleCreate}
                info={visibleInfo}
                onCreate={this.onCreate}
                cancel={this.onCancel}
              />
            )}
          </Col>
          <Col span={6}>
            <section className="md-section border">
              <header className="md-section-header">常见问题</header>
              {issueList.length ? (
                <Collapse accordion className="md-section-body">
                  {issueList.map((item) => (
                    <Panel header={item.title} key={item.id}>
                      <p>{item.content}</p>
                    </Panel>
                  ))}
                </Collapse>
              ) : (
                <div className="md-section-body">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </section>
          </Col>
        </Row>

        <Modal
          open={visibleNew}
          title={`【${visibleNewInfo?.name}】 的 信息`}
          onCancel={() => {
            this.setState({
              visibleNew: false,
              visibleNewInfo: {},
            });
          }}
          width="850px"
          footer={false}
        >
          <Form layout="vertical">
            <div className="new_resume">
              <div className="page-header">
                <div className="title mb-3">基础信息</div>
              </div>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="name" label="真实姓名">
                    {visibleNewInfo?.name}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="age" label="年龄">
                    {visibleNewInfo?.age}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="mobile" label="联系方式">
                    {visibleNewInfo?.mobile}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="experience" label="工作经验">
                    {visibleNewInfo?.experience}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sex" label="性别">
                    {visibleNewInfo?.sex}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="education" label="学历">
                    {visibleNewInfo?.education}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="state" label="求职状态">
                    {visibleNewInfo?.state}
                  </Form.Item>
                </Col>
              </Row>

              <div className="page-header">
                <div className="title mb-3">求职意向</div>
              </div>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="occupation" label="意向职位">
                    {visibleNewInfo?.occupation}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="cities" label="意向城市">
                    {visibleNewInfo?.cities}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="期望薪资">
                    {visibleNewInfo?.min_salary} ~ {visibleNewInfo?.max_salary}
                  </Form.Item>
                </Col>
              </Row>

              <div className="page-header">
                <div className="title mb-3">个人优势</div>
              </div>
              <Form.Item name="advantage">
                {visibleNewInfo?.advantage}
              </Form.Item>

              <div className="page-header">
                <div className="title mb-3">社交主页</div>
              </div>
              <Form.Item name="page">{visibleNewInfo?.page}</Form.Item>
              <div className="page-header">
                <div className="title mb-3">工作经历</div>
              </div>
              {(visibleNewInfo?.experienceList &&
                visibleNewInfo?.experienceList.length &&
                visibleNewInfo?.experienceList.map((item, ind) => (
                  <Space
                    key={ind}
                    style={{
                      display: "block",
                      background: "rgb(248,248,248)",
                      padding: 15,
                      marginBottom: 20,
                      position: "relative",
                    }}
                    align="baseline"
                  >
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="公司名称">
                          {item.corporate_name}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="公司行业">
                          {item.corporate_industry}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="在职时间">
                          {item.entry_time} ~ {item.depart_time}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="职位名称">{item.position}</Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="工作内容">{item.work_content}</Form.Item>
                    <Row gutter={30}>
                      <Col span={6}>
                        <Form.Item label="月薪(选填)">{item.pay}</Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="所属部门(选填)">
                          {item.department}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="汇报对象(选填)">
                          {item.report}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="下属人数(选填)">
                          {item.subordinates}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="工作地点(选填)">
                      {item.work_location}
                    </Form.Item>
                  </Space>
                ))) || <div className="empty">暂无信息</div>}

              <div className="page-header">
                <div className="title mb-3">教育经历</div>
              </div>
              {(visibleNewInfo?.educationalList &&
                visibleNewInfo?.educationalList.length &&
                visibleNewInfo?.educationalList.map((item, ind) => (
                  <Space
                    key={ind}
                    style={{
                      display: "block",
                      background: "rgb(248,248,248)",
                      padding: 15,
                      marginBottom: 20,
                      position: "relative",
                    }}
                    align="baseline"
                  >
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="学校名称">
                          {item.school_name}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="学历">{item.education}</Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="专业名称">
                          {item.professional_name}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="在校时间">
                          {item.start_date} ~ {item.end_date}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))) || <div className="empty">暂无信息</div>}
              <div className="page-header">
                <div className="title mb-3">项目经历</div>
              </div>
              {(visibleNewInfo?.projectList &&
                visibleNewInfo?.projectList.length &&
                visibleNewInfo?.projectList.map((item, ind) => (
                  <Space
                    key={ind}
                    style={{
                      display: "block",
                      background: "rgb(248,248,248)",
                      padding: 15,
                      marginBottom: 20,
                      position: "relative",
                    }}
                    align="baseline"
                  >
                    <Form.Item label="项目名称">{item.product_name}</Form.Item>
                    <Form.Item label="项目描述">{item.product_intro}</Form.Item>
                    <Form.Item label="项目成就">{item.achieve}</Form.Item>
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="项目时间">
                          {item.start_date} ~ {item.end_date}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="项目链接(选填)">
                          {item.link}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))) || <div className="empty">暂无信息</div>}
            </div>
          </Form>
        </Modal>
      </main>
    );
  }
}
