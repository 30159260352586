import React, { useState } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import {
  Spin,
  Layout,
  Row,
  Col,
  Menu,
  message,
  Input,
  Button,
  Empty,
} from "antd";
import {
  EnvironmentOutlined,
  TrophyOutlined,
  SolutionOutlined,
  UserOutlined,
  FieldTimeOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";

import httpPromise from "@/api";

import cookie from "react-cookies";
import qs from "qs";

import "./hot.less";

import Headers from "./Headers";
import HomeFooter from "../../components/homeFooter";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

class Hot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userinfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      searchInput: "",
      searchVal: "",
      pagination: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },
      searchRes: [],
      currentItem: undefined,
    };
    this.ref = null;
    this.scrollRef = React.createRef();
  }
  componentDidMount() {
    this.getUserInfo();

    let param = qs.parse(this.props.history.location.search.split("?")[1]);

    if (!!param?.search) {
      this.setState(
        { searchInput: param?.search, searchVal: param?.search },
        () => {
          this.getSearch();
        }
      );
    } else {
      this.getSearch();
    }

    window.addEventListener("scroll", this.handleScroll, false);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  getScrollTop = () => {
    var scrollTop = 0,
      bodyScrollTop = 0,
      documentScrollTop = 0;
    if (document.body) {
      bodyScrollTop = document.body.scrollTop;
    }
    if (document.documentElement) {
      documentScrollTop = document.documentElement.scrollTop;
    }
    scrollTop =
      bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
    return scrollTop;
  };

  getScrollHeight = () => {
    var scrollHeight = 0,
      bodyScrollHeight = 0,
      documentScrollHeight = 0;
    if (document.body) {
      bodyScrollHeight = document.body.scrollHeight;
    }
    if (document.documentElement) {
      documentScrollHeight = document.documentElement.scrollHeight;
    }
    scrollHeight =
      bodyScrollHeight - documentScrollHeight > 0
        ? bodyScrollHeight
        : documentScrollHeight;
    return scrollHeight;
  };
  getWindowHeight = () => {
    var windowHeight = 0;
    if (document.compatMode == "CSS1Compat") {
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowHeight = document.body.clientHeight;
    }
    return windowHeight;
  };

  getUserInfo = async () => {
    let t = this;
    httpPromise({ apiDemo: "companyGetUserInfo" })
      .then(function (res) {
        console.log(res);
        if (res.code === 200) {
          t.setState({ loading: false, userinfo: res.data });
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          cookie.save("userinfo", res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 页面滚动
  handleScroll = () => {
    if (
      this.getScrollHeight() - this.getScrollTop() - this.getWindowHeight() <
      1080
    ) {
      // 解除绑定
      window.removeEventListener("scroll", this.handleScroll, false);
      // 在这里发送请求
      if (this.state.pagination.pageNo >= this.state.pagination.pageTotal) {
        return false;
      }

      this.setState(
        {
          pagination: {
            ...this.state.pagination,
            pageNo: this.state.pagination.pageNo + 1,
          },
        },
        () => {
          this.getSearch();
        }
      );

      console.log("到底了");
      // 并在请求到数据后重新开启监听
      setTimeout(
        () => window.addEventListener("scroll", this.handleScroll, false),
        300
      );
    }
  };

  getSearch = async () => {
    let t = this;
    httpPromise({
      apiDemo: "companyGetSearchJob",
      params: { ...this.state.pagination, title: t.state.searchInput },
    })
      .then(function (res) {
        console.log(res);
        if (res.code === 200) {
          t.setState({
            searchRes: [...t.state.searchRes, ...res.data.data],
            pagination: {
              ...t.state.pagination,
              total: res.data.total,
              pageTotal: res.data.pageTotal,
            },
          });

          if (!t.state.currentItem) {
            t.setState({
              currentItem: res.data.data[0],
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const {
      userinfo,
      searchInput,
      searchVal,
      searchRes,
      pagination,
      currentItem,
    } = this.state;

    return (
      <Spin spinning={this.state.loading}>
        <Headers userinfo={userinfo} />
        <Layout className="hot">
          <Layout style={{ minHeight: "100vh" }}>
            <Content style={{ position: "relative" }}>
              <div className="top-banner">
                <div className="container">
                  <div className="search">
                    <Input
                      value={searchInput}
                      placeholder="请输入期望职位的关键词"
                      onChange={(e) => {
                        this.setState({
                          searchInput: e.target.value,
                        });
                      }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setState(
                          {
                            searchVal: searchInput,
                            searchRes: [],
                            pagination: {
                              pageNo: 1,
                              pageSize: 12,
                            },
                          },
                          () => {
                            this.getSearch();

                            window.removeEventListener(
                              "scroll",
                              this.handleScroll
                            );

                            window.addEventListener(
                              "scroll",
                              this.handleScroll,
                              false
                            );
                          }
                        );
                      }}
                    >
                      搜索
                    </Button>
                  </div>
                </div>
              </div>
              <div
                className="search-res"
                // ref={this.scrollRef}
                // onScroll={this.handleScroll}
              >
                {(searchRes.length > 0 && (
                  <div className="container">
                    <div className="left">
                      <div className="search-list">
                        {searchRes.map((item, index) => (
                          <div
                            className={`search-list-item ${
                              (item.id == currentItem?.id && "cur") || ""
                            }`}
                            key={index}
                            onClick={() => {
                              this.setState({
                                currentItem: { ...item, index: index },
                              });
                            }}
                          >
                            <div className="head">
                              <div className="name">
                                <div className="title">{item.title}</div>
                                <div className="subtitle">
                                  [{item.occupation_name}]
                                </div>
                              </div>
                              <div className="price">
                                {(item.min_salary_name ===
                                  item.max_salary_name &&
                                  item.max_salary_name) ||
                                  `${item.min_salary_name} ~ ${item.max_salary_name}`}
                              </div>
                            </div>
                            <div className="cont">
                              <div className="tag">{item.education_name}</div>
                              <div className="tag">{item.experience_name}</div>
                              <div className="tag">
                                {(item.sex == 0 && "不限") ||
                                  (item.sex == 1 && "男") ||
                                  (item.sex == 2 && "女") ||
                                  ""}
                              </div>
                              <div className="tag">
                                {(item.min_age === item.max_age &&
                                  item.max_age) ||
                                  `${item.min_age} ~ ${item.max_age}`}
                              </div>
                              {/* <div className="tag">{item.state}</div> */}
                            </div>
                            <div className="foot">
                              <div className="postname">
                                {item.interview_contact}
                              </div>
                              <div className="address">
                                {item.work_provinces_name}-
                                {item.work_cities_name}-{item.work_areas_name}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="right">
                      {(currentItem && (
                        <div className="info-cont">
                          <div className="head">
                            <div className="info">
                              <div className="title">
                                <div className="name">{currentItem.title}</div>
                                <div className="price">
                                  {(currentItem.min_salary_name ===
                                    currentItem.max_salary_name &&
                                    currentItem.max_salary_name) ||
                                    `${currentItem.min_salary_name} ~ ${currentItem.max_salary_name}`}
                                </div>
                              </div>
                              <div className="tags">
                                <div className="tags-item">
                                  <EnvironmentOutlined />
                                  <span>
                                    {currentItem.work_provinces_name}-
                                    {currentItem.work_cities_name}-
                                    {currentItem.work_areas_name}
                                  </span>
                                </div>
                                <div className="tags-item">
                                  <TrophyOutlined />
                                  <span>{currentItem.experience_name}</span>
                                </div>
                                <div className="tags-item">
                                  <SolutionOutlined />
                                  <span>{currentItem.education_name}</span>
                                </div>
                                <div className="tags-item">
                                  <ClockCircleOutlined />
                                  <span>
                                    {(currentItem.min_age ===
                                      currentItem.max_age &&
                                      currentItem.max_age) ||
                                      `${currentItem.min_age} ~ ${currentItem.max_age}`}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="action">
                              <Button
                                className=""
                                type="primary"
                                onClick={() => {
                                  this.props.history.push(
                                    `/biz/home`
                                  );
                                }}
                              >
                                立即沟通
                              </Button>
                            </div>
                          </div>
                          <div className="cont">
                            <div className="page-header">
                              <div className="title">职位描述：</div>
                            </div>
                            <div className="tags">
                              {currentItem.benefits
                                .split("/")
                                .map((item, index) => (
                                  <div className="tags-item" key={index}>
                                    {item}
                                  </div>
                                ))}
                              {/* <div className="tags-item">地点</div>
                            <div className="tags-item">经验要求</div>
                            <div className="tags-item">学历要求</div> */}
                            </div>
                            <div className="intro">
                              <Input.TextArea
                                value={currentItem.intro}
                                readOnly
                                autoSize
                              />
                            </div>
                            <div className="contact">
                              <div className="page-header">
                                <div className="title">联系信息：</div>
                              </div>
                              <div className="name">
                                <UserOutlined />
                                <span>{currentItem.interview_contact}</span>
                              </div>
                              <div className="time">
                                <FieldTimeOutlined />
                                <span>{currentItem.interview_time}</span>
                              </div>
                              <div className="page-header">
                                <div className="title">工作地址：</div>
                              </div>
                              <div className="address">
                                <EnvironmentOutlined />
                                <span>
                                  {currentItem.work_provinces_name}
                                  {currentItem.work_cities_name}
                                  {currentItem.work_areas_name}
                                  {/* {currentItem.work_address} */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )) || (
                        <div className="info-cont empty p-5">
                          <Empty />
                        </div>
                      )}
                    </div>
                  </div>
                )) ||
                  ""}
                {(searchRes.length <= 0 && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "60px",
                    }}
                  >
                    <Empty />
                  </div>
                )) ||
                  ""}
              </div>

              <HomeFooter />
            </Content>
          </Layout>
        </Layout>
      </Spin>
    );
  }
}

export default Hot;
