import instance from './instance.js';
import { base } from "./config.js";
import { message } from 'antd'

export default {
    //用户注册/登录
    async hiringlogin(params) {
        return await instance.post(`${base}/hr/login/login`, params).then((res) => {
            return res.data;
        }).catch((error) => {
            message.error('服务器出错');
        });
    },
    //用户登录
    async hiringregister(params) {
        return await instance.post(`${base}/hr/login/register`, params).then((res) => {
            return res.data;
        }).catch((error) => {
            message.error('服务器出错');
        });
    },
    //用户手机号获取验证码
    async hiringGetCode(params) {
        return await instance.post(`${base}/hr/login/getRegCode`, params).then((res) => {
            return res.data;
        }).catch((error) => {
            message.error('服务器出错');
        });
    },
    // 用户忘记密码
    async hiringForget(params) {
        return await instance.post(`${base}/hr/login/forget`, params).then((res) => {
            return res.data;
        }).catch((error) => {
            message.error('服务器出错');
        });
    },
    // 用户忘记密码获取验证码
    async hiringForgetCode(params) {
        return await instance.post(`${base}/hr/login/getForgetCode`, params).then((res) => {
            return res.data;
        }).catch((error) => {
            message.error('服务器出错');
        });
    },
}