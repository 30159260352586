import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";

import {
  PageHeader,
  Button,
  Descriptions,
  Table,
  message,
  Spin,
  Modal,
  Form,
  Row,
  Col,
  Space,
} from "antd";
import httpPromise from "@/api";
import "./detail.less";
export default class OccDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      visible: false,
      visibleInfo: {},
      visibleNew: false,
      visibleNewInfo: {},
      statusList: [],
      columns: [
        {
          title: "面试日期",
          dataIndex: "interview_time",
          key: "interview_time",
          align: "center",
        },
        {
          title: "姓名",
          dataIndex: "resume_name",
          key: "resume_name",
          align: "center",
          render: (text, data) => (
            <div
              onClick={() => {
                if (data.is_new_resume == 1) {
                  this.setState({
                    visibleNew: true,
                    visibleNewInfo: data,
                  });
                } else {
                  this.setState({
                    visible: true,
                    visibleInfo: data,
                  });
                }
              }}
              style={{ color: "#1890ff", cursor: "pointer" }}
            >
              {(data.is_new_resume == 1 && data.resume.name) || text}
            </div>
          ),
        },
        {
          title: "联系电话",
          dataIndex: "resume_mobile",
          key: "resume_mobile",
          align: "center",
          render: (text, data) => (
            <div>{(data.is_new_resume == 1 && data.resume.mobile) || text}</div>
          ),
        },
        {
          title: "面试状态",
          dataIndex: "status",
          key: "status",
          align: "center",
          render: (text, data) => (
            <div>
              {(text == 5 && (
                <span
                  style={{
                    display: "inline-block",
                    padding: "5px 10px",
                    background: "#1890ff",
                    color: "#fff",
                  }}
                >
                  入职 {data.entry_day} 天
                </span>
              )) ||
                this.state.statusList.find((item) => item.id == text)?.title}
            </div>
          ),
        },
        {
          title: "性别",
          dataIndex: "resume_sex",
          key: "resume_sex",
          align: "center",
          render: (text, data) => (
            <div>{(data.is_new_resume == 1 && data.resume.sex) || text}</div>
          ),
        },
        {
          title: "年龄",
          dataIndex: "resume_age",
          key: "resume_age",
          align: "center",
          render: (text, data) => (
            <div>{(data.is_new_resume == 1 && data.resume.age) || text}</div>
          ),
        },
        {
          title: "学历",
          dataIndex: "resume_education",
          key: "resume_education",
          align: "center",
          render: (text, data) => (
            <div>
              {(data.is_new_resume == 1 && data.resume.education) || text}
            </div>
          ),
        },
        {
          title: "工作年限",
          dataIndex: "experience",
          key: "experience",
          align: "center",
          render: (text, data) => (
            <div>
              {(data.is_new_resume == 1 && data.resume.experience) || text}
            </div>
          ),
        },
        {
          title: "期望薪资",
          dataIndex: "min_salary",
          key: "min_salary",
          align: "center",
          render: (text, data) =>
            data.is_new_resume == 1 ? (
              data.resume.min_salary == "面议" &&
              data.resume.max_salary == "面议" ? (
                "面议"
              ) : (
                <div>
                  {data.resume.min_salary || "暂无"} ~{" "}
                  {data.resume.max_salary || "暂无"}
                </div>
              )
            ) : data.min_salary_id == 1 && data.max_salary_id == 1 ? (
              "面议"
            ) : (
              <div>
                {data.min_salary || "暂无"} ~ {data.max_salary || "暂无"}
              </div>
            ),
        },
        {
          title: "悬赏佣金/人",
          dataIndex: "offer",
          key: "offer",
          align: "center",
          render: (text, data) => (
            <span style={{ color: "#1890ff" }}>
              {this.state.descInfo.brokerage}
            </span>
          ),
        },
        {
          title: "已扣佣金",
          dataIndex: "deduct",
          key: "deduct",
          align: "center",
          render: (text, data) => (
            <div style={{ color: "red" }}>
              {(data.status == 6 && this.state.descInfo.brokerage) || 0}
            </div>
          ),
        },
        {
          title: "状态/操作",
          dataIndex: "op",
          key: "op",
          align: "center",
          render: (text, data) => (
            <div>
              {data.status == 1 && (
                <Button
                  className="mr-1"
                  type="primary"
                  size="small"
                  onClick={() => {
                    this.changeStatus(data.id, 2);
                  }}
                >
                  确认面试
                </Button>
              )}
              {data.status == 2 && (
                <Button
                  className="mr-1"
                  type="primary"
                  size="small"
                  onClick={() => {
                    this.changeStatus(data.id, 3);
                  }}
                >
                  聘用
                </Button>
              )}
              {data.status == 2 && (
                <Button
                  className="mr-1"
                  type="primary"
                  danger
                  size="small"
                  onClick={() => {
                    this.changeStatus(data.id, 4);
                  }}
                >
                  不通过
                </Button>
              )}
              {data.status == 3 && (
                <Button
                  className="mr-1"
                  type="primary"
                  size="small"
                  onClick={() => {
                    this.changeStatus(data.id, 5);
                  }}
                >
                  确认入职
                </Button>
              )}
              {data.status == 5 && (
                <Button
                  className="mr-1"
                  type="primary"
                  size="small"
                  onClick={() => {
                    this.changeStatus(data.id, 6);
                  }}
                >
                  完成
                </Button>
              )}
              {data.status == 5 && (
                <Button
                  className="mr-1"
                  type="primary"
                  danger
                  size="small"
                  onClick={() => {
                    this.changeStatus(data.id, 7);
                  }}
                >
                  解聘
                </Button>
              )}
            </div>
          ),
        },
      ],
      descInfo: {},
      pageId: null,
      balance: {},
    };
  }
  componentWillMount = () => {
    let { id } = this.props.match.params;
    if (!id) {
      message.error("查无数据");
    } else {
      this.setState({ pageId: id }, () => {
        this.getDetail(id);
        this.getbalance();
        this.getStatus();
        this.getResumeList(id);
      });
    }
  };
  computeTime(time) {
    // time format 2016-11-11T18:56:33.904Z
    const datePart = time.substring(0, 10).replace(/\-/g, "/");
    const timePart = time.substring(10, 18);
    //console.log(datePart + ' ' + timePart);
    const oldTime = new Date(datePart + " " + timePart).getTime();
    const currTime = new Date().getTime();
    const diffValue = currTime - oldTime;

    const days = Math.floor(diffValue / (24 * 3600 * 1000));
    if (days === 0) {
      //计算相差小时数
      const leave1 = diffValue % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      const hours = Math.floor(leave1 / (3600 * 1000));
      if (hours === 0) {
        //计算相差分钟数
        var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        var minutes = Math.floor(leave2 / (60 * 1000));
        if (minutes === 0) {
          //计算相差秒数
          var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
          var seconds = Math.round(leave3 / 1000);
          return seconds + "秒前";
        }
        return minutes + "分钟前";
      }
      return hours + "小时前";
    }

    return days + "天前";
  }

  changeStatus = (id, status) => {
    httpPromise({
      apiDemo: "companyChangeJobResumeStatus",
      params: { id: id, status: status },
    })
      .then((res) => {
        if (res.code === 200) {
          this.getResumeList({ ...this.state.pagination });
        } else {
          this.getResumeList({ ...this.state.pagination });
          message.error(res.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  };

  getbalance = () => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companyGetBalance" })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({ balance: res.data });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getDetail = () => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyGetJobDetail",
      params: { id: this.state.pageId },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({
            descInfo: res.data,
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  };
  getStatus = () => {
    httpPromise({
      apiDemo: "companyJobResumeStatus",
    })
      .then((res) => {
        if (res.code === 200) {
          this.setState({
            statusList: res.data,
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  };

  getResumeList = () => {
    httpPromise({
      apiDemo: "companyGetResumeList",
      params: { job_id: this.state.pageId, ...this.state.pagination },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({
            dataSource: res.data.data,
            pagination: {
              ...this.state.pagination,
              total: res.data.total,
            },
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  };

  handleTableChange = ({ current }, filters, sorter) => {
    let { pagination } = this.state;
    pagination.pageNo = current;
    this.setState({ pagination });
    this.getResumeList();
  };

  toTop = () => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companySetTop", params: { id: this.state.pageId } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getDetail();
          message.success("置顶成功");
          this.getbalance();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toRefre = () => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companySetRefresh",
      params: { id: this.state.pageId },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getDetail();
          message.success("刷新成功");
          this.getbalance();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  goback = () => {
    this.props.history.push("/biz/recruit");
  };

  render() {
    let {
      dataSource,
      columns,
      loading,
      descInfo,
      pagination,
      balance,
      visible,
      visibleInfo,
      visibleNew,
      visibleNewInfo,
    } = this.state;
    return (
      <main className="pg-resruit-detail">
        <Spin spinning={loading}>
          <PageHeader
            className="pg-biz-header"
            onBack={() => this.goback()}
            title="职位详情"
            extra={[
              <Button
                type="primary"
                size="large"
                style={{
                  position: "absolute",
                  right: 0,
                  top: -15,
                  paddingLeft: "40px",
                }}
                className="md-section-body_ctrlbtn"
              >
                <Link to="/biz/recruit/add">
                  <span
                    style={{
                      fontSize: "35px",
                      position: "absolute",
                      top: "-12px",
                      left: "9px",
                    }}
                  >
                    +
                  </span>{" "}
                  发布职位
                </Link>
              </Button>,
            ]}
          >
            <div
              className="alert alert-primary"
              style={{
                padding: "15px",
                backgroundColor: "rgb(255, 120, 0)",
                color: "#fff",
                marginBottom: 15,
              }}
            >
              <span className="mr-1">
                {descInfo.create_time
                  ? this.computeTime(descInfo.create_time)
                  : "-"}
              </span>
              <span className="mr-1">
                {descInfo.work_provinces_name +
                  descInfo.work_cities_name +
                  descInfo.work_areas_name +
                  descInfo.work_address}
              </span>
              <span className="mr-1">{descInfo.occupation_name}</span>
              <Button
                className="mr-1"
                type="ghost"
                size="small"
                style={{
                  color: "#fff",
                  borderColor: "#fff",
                  background: "transparent",
                }}
                onClick={() => {
                  this.toTop();
                }}
              >
                置顶 （剩余{balance.top_number}次）
              </Button>
              <Button
                type="ghost"
                size="small"
                style={{
                  color: "#fff",
                  borderColor: "#fff",
                  background: "transparent",
                }}
                onClick={() => {
                  this.toRefre();
                }}
              >
                刷新（剩余{balance.refresh_number}次）
              </Button>
            </div>
            <Descriptions
              title={descInfo.title + `【${descInfo.occupation_name}】`}
              column={2}
            >
              <Descriptions.Item label="工作经验">
                {descInfo.experience_name}
              </Descriptions.Item>
              <Descriptions.Item label="学历要求">
                {descInfo.education_name}
              </Descriptions.Item>
              <Descriptions.Item label="性别要求">
                {["不限", "男", "女"][descInfo.sex]}
              </Descriptions.Item>
              <Descriptions.Item label="需要人数">
                {descInfo.number}
              </Descriptions.Item>
              <Descriptions.Item label="面试时间">
                {descInfo.interview_time}
              </Descriptions.Item>
              <Descriptions.Item label="工作地点">
                {descInfo.work_provinces_name +
                  descInfo.work_cities_name +
                  descInfo.work_areas_name +
                  descInfo.work_address}
              </Descriptions.Item>
              <Descriptions.Item label="薪资待遇">
                {descInfo.min_salary_name + "-" + descInfo.max_salary_name}
              </Descriptions.Item>
              <Descriptions.Item label="佣金/人">
                {" "}
                ¥{descInfo.brokerage}{" "}
              </Descriptions.Item>
              <Descriptions.Item label="福利待遇">
                {descInfo.benefits}
              </Descriptions.Item>
              <Descriptions.Item label="职位描述">
                {descInfo.intro}
              </Descriptions.Item>
            </Descriptions>
            <div
              className="alert alert-primary"
              style={{
                padding: "15px",
                backgroundColor: "rgb(64, 158, 255)",
                color: "#fff",
                marginBottom: 15,
              }}
            >
              您共有 {descInfo.resume_total} 条推荐，其中{" "}
              {descInfo.resume_count} 条推荐 正在进行中
            </div>
            {descInfo?.brokerage && (
              <Table
                loading={loading}
                dataSource={dataSource}
                columns={columns}
                onChange={this.handleTableChange}
                pagination={{
                  current: pagination.pageNo,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                }}
              />
            )}
          </PageHeader>
        </Spin>

        {/* 新简历模板 */}
        <Modal
          open={visibleNew}
          title={`【${visibleNewInfo?.resume?.name}】 的 信息`}
          onCancel={() => {
            this.setState({
              visibleNew: false,
              visibleNewInfo: {},
            });
          }}
          width="850px"
          footer={false}
        >
          <Form layout="vertical">
            <div className="new_resume">
              <div className="page-header">
                <div className="title mb-3">基础信息</div>
              </div>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="name" label="真实姓名">
                    {visibleNewInfo?.resume?.name}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="age" label="年龄">
                    {visibleNewInfo?.resume?.age}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="mobile" label="联系方式">
                    {visibleNewInfo?.resume?.mobile}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="experience" label="工作经验">
                    {visibleNewInfo?.resume?.experience}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sex" label="性别">
                    {visibleNewInfo?.resume?.sex}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="education" label="学历">
                    {visibleNewInfo?.resume?.education}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="state" label="求职状态">
                    {visibleNewInfo?.resume?.state}
                  </Form.Item>
                </Col>
              </Row>

              <div className="page-header">
                <div className="title mb-3">求职意向</div>
              </div>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="occupation" label="意向职位">
                    {visibleNewInfo?.resume?.occupation}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="cities" label="意向城市">
                    {visibleNewInfo?.resume?.cities}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="期望薪资">
                    {visibleNewInfo?.resume?.min_salary} ~{" "}
                    {visibleNewInfo?.resume?.max_salary}
                  </Form.Item>
                </Col>
              </Row>

              <div className="page-header">
                <div className="title mb-3">个人优势</div>
              </div>
              <Form.Item name="advantage">
                {visibleNewInfo?.resume?.advantage}
              </Form.Item>

              <div className="page-header">
                <div className="title mb-3">社交主页</div>
              </div>
              <Form.Item name="page">{visibleNewInfo?.resume?.page}</Form.Item>
              <div className="page-header">
                <div className="title mb-3">工作经历</div>
              </div>
              {(visibleNewInfo?.resume?.experienceList &&
                visibleNewInfo?.resume?.experienceList.length &&
                visibleNewInfo?.resume?.experienceList.map((item, ind) => (
                  <Space
                    key={ind}
                    style={{
                      display: "block",
                      background: "rgb(248,248,248)",
                      padding: 15,
                      marginBottom: 20,
                      position: "relative",
                    }}
                    align="baseline"
                  >
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="公司名称">
                          {item.corporate_name}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="公司行业">
                          {item.corporate_industry}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="在职时间">
                          {item.entry_time} ~ {item.depart_time}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="职位名称">{item.position}</Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="工作内容">{item.work_content}</Form.Item>
                    <Row gutter={30}>
                      <Col span={6}>
                        <Form.Item label="月薪(选填)">{item.pay}</Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="所属部门(选填)">
                          {item.department}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="汇报对象(选填)">
                          {item.report}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="下属人数(选填)">
                          {item.subordinates}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="工作地点(选填)">
                      {item.work_location}
                    </Form.Item>
                  </Space>
                ))) || <div className="empty">暂无信息</div>}

              <div className="page-header">
                <div className="title mb-3">教育经历</div>
              </div>
              {(visibleNewInfo?.resume?.educationalList &&
                visibleNewInfo?.resume?.educationalList.length &&
                visibleNewInfo?.resume?.educationalList.map((item, ind) => (
                  <Space
                    key={ind}
                    style={{
                      display: "block",
                      background: "rgb(248,248,248)",
                      padding: 15,
                      marginBottom: 20,
                      position: "relative",
                    }}
                    align="baseline"
                  >
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="学校名称">
                          {item.school_name}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="学历">{item.education}</Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="专业名称">
                          {item.professional_name}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="在校时间">
                          {item.start_date} ~ {item.end_date}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))) || <div className="empty">暂无信息</div>}
              <div className="page-header">
                <div className="title mb-3">项目经历</div>
              </div>
              {(visibleNewInfo?.resume?.projectList &&
                visibleNewInfo?.resume?.projectList.length &&
                visibleNewInfo?.resume?.projectList.map((item, ind) => (
                  <Space
                    key={ind}
                    style={{
                      display: "block",
                      background: "rgb(248,248,248)",
                      padding: 15,
                      marginBottom: 20,
                      position: "relative",
                    }}
                    align="baseline"
                  >
                    <Form.Item label="项目名称">{item.product_name}</Form.Item>
                    <Form.Item label="项目描述">{item.product_intro}</Form.Item>
                    <Form.Item label="项目成就">{item.achieve}</Form.Item>
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="项目时间">
                          {item.start_date} ~ {item.end_date}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="项目链接(选填)">
                          {item.link}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))) || <div className="empty">暂无信息</div>}
            </div>
          </Form>
        </Modal>

        {/* 旧简历模板 */}
        <Modal
          open={visible}
          title={`【${visibleInfo?.resume_name}】 的 信息`}
          onCancel={() => {
            this.setState({
              visible: false,
              visibleInfo: {},
            });
          }}
          width="650px"
          footer={false}
        >
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Form.Item label="面试日期">
              {visibleInfo?.interview_time}
            </Form.Item>
            <Form.Item label="姓名">{visibleInfo?.resume_name}</Form.Item>
            <Form.Item label="联系电话">{visibleInfo?.resume_mobile}</Form.Item>
            <Form.Item label="面试状态">
              <div>
                {(visibleInfo?.status == 5 && (
                  <span
                    style={{
                      display: "inline-block",
                      padding: "5px 10px",
                      background: "#1890ff",
                      color: "#fff",
                    }}
                  >
                    入职 {visibleInfo?.entry_day} 天
                  </span>
                )) ||
                  this.state.statusList.find(
                    (item) => item.id == visibleInfo?.status
                  )?.title}
              </div>
            </Form.Item>
            <Form.Item label="性别">{visibleInfo?.resume_sex}</Form.Item>
            <Form.Item label="年龄">{visibleInfo?.resume_age}</Form.Item>
            <Form.Item label="学历">{visibleInfo?.resume_education}</Form.Item>
            <Form.Item label="工作年限">{visibleInfo?.experience}</Form.Item>
            <Form.Item label="期望求职区域">
              {visibleInfo?.resume_intention_province} /{" "}
              {visibleInfo?.resume_intention_city} /{" "}
              {visibleInfo?.resume_intention_area} /{" "}
              {visibleInfo?.resume_intention_address}
            </Form.Item>
            <Form.Item label="期望薪资">
              {(visibleInfo?.min_salary_id == 1 &&
                visibleInfo?.max_salary_id == 1 &&
                "面议") || (
                <div>
                  {visibleInfo?.min_salary || "暂无"} ~{" "}
                  {visibleInfo?.max_salary || "暂无"}
                </div>
              )}
            </Form.Item>
            <Form.Item label="悬赏佣金/人">{descInfo.brokerage}</Form.Item>
            <Form.Item label="已扣佣金">
              {(visibleInfo?.status == 6 && descInfo.brokerage) || 0}
            </Form.Item>
            <Form.Item label="技能">{visibleInfo?.resume_skill}</Form.Item>
            <Form.Item></Form.Item>
          </Form>
        </Modal>
      </main>
    );
  }
}
