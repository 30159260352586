import React from "react";

import {
  Link,
  // , withRouter, Switch, Route, Redirect
} from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  Spin,
  message,
} from "antd";
import {
  TabletOutlined,
  CommentOutlined,
  MailOutlined,
  LockOutlined,
} from "@ant-design/icons";

import httpPromise from "@/api";
import cookie from "react-cookies";
import "../index.less";

export default class Forget extends React.Component {
  state = {
    contdown: 0,
    timeout: false,
    loading: false,
    loading2: false,
    isagree: false,
    codeUrl: "",
    url: "",
    hasLogin: true,
    isHiring: false,
  };
  constructor(props) {
    super(props);
  }
  componentWillMount = () => {
    let { pathname } = window.location;
    if (pathname.includes("forget")) {
      let codeUrl = "companyForgetCode";
      let url = "companyForget";
      let isHiring = pathname.includes("hr");
      if (isHiring) {
        codeUrl = "hiringForgetCode";
        url = "hiringForget";
        this.setState({ isHiring });
      }
      this.setState({ codeUrl, url, hasLogin: true, wrapperCol: null });
    } else {
      this.setState({
        codeUrl: "companyChangeCode",
        url: "companyChange",
        hasLogin: false,
        wrapperCol: {
          sm: {
            span: 24,
          },
          md: {
            span: 16,
            offset: 4,
          },
          lg: {
            span: 8,
            offset: 8,
          },
        },
      });
    }
  };

  register = async (values) => {
    this.setState({ loading: true });
    let t = this;
    httpPromise({ apiDemo: this.state.url, params: values })
      .then(function (res) {
        t.setState({ loading: false });
        if (res.code === 200) {
          message.success("修改密码成功");
          cookie.remove("access_token", { path: "/" });
          if (this.state.isHiring) {
            window.location.href = "/entrance/hr/login";
          } else {
            window.location.href = "/entrance/biz/login";
          }
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  formRef = React.createRef();

  getcode = (e) => {
    this.setState({ loading: true });
    let mobile = this.formRef.current.getFieldValue().mobile,
      t = this;
    if (/^(1[3-9][0-9])\d{8}$/.test(mobile)) {
      let data = { mobile };
      httpPromise({ apiDemo: this.state.codeUrl, params: data })
        .then(function (res) {
          if (res.code === 200) {
            message.success("验证码发送成功");
            t.setState({ contdown: 60, timeout: true, loading: false });
            setInterval(() => {
              if (t.state.contdown >= 1) {
                t.setState({ contdown: t.state.contdown - 1 });
              } else {
                t.setState({ timeout: false });
              }
              t.setState({ loading: false });
            }, 1000);
          } else {
            message.error(res.message);
            t.setState({ loading: false });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      message.error("请输入正确的手机号");
      this.setState({ loading: false });
    }
  };

  render() {
    let { isHiring } = this.state;
    return (
      <Spin spinning={this.state.loading}>
        <div className="register">
          <Form
            wrapperCol={this.state.wrapperCol}
            ref={this.formRef}
            className="register-form"
            name="register"
            onFinish={this.register}
            scrollToFirstError
          >
            <div className="page-header mb-2">
              <h2>找回密码</h2>
            </div>
            <Form.Item
              name="mobile"
              rules={[
                { required: true, message: "请输入你的手机号" },
                { pattern: /^(1[3-9])\d{9}$/, message: "请输入正确的手机号" },
              ]}
            >
              <Input
                placeholder="请输入手机号"
                maxLength="11"
                prefix={<TabletOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "请输入你的密码!" },
                {
                  pattern: /^[0-9a-zA-Z]{8,}$/,
                  message: "密码需为8位以上数字及字母的组合",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                placeholder="请输入新密码"
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                { required: true, message: "请再次输入密码" },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject("两次密码不一致");
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="重复密码"
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              />
            </Form.Item>
            <Form.Item
              rules={[{ required: true, message: "请输入手机验证码" }]}
            >
              <Row gutter={8}>
                <Col span={14}>
                  <Form.Item
                    name="smscode"
                    noStyle
                    rules={[
                      { required: true, message: "请输入手机验证码" },
                      { pattern: /\d{6}$/, message: "请输入6位的验证码" },
                    ]}
                    hasFeedback
                  >
                    <Input
                      placeholder="请输入验证码"
                      prefix={
                        <CommentOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Spin spinning={this.state.loading2}>
                    {this.state.timeout === true ? (
                      <Button disabled>等待{this.state.contdown}秒后</Button>
                    ) : (
                      <Button block onClick={this.getcode}>
                        获取验证码
                      </Button>
                    )}
                  </Spin>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item className="mb-0">
              <Button
                type="primary"
                htmlType="submit"
                block
                className="register-form-button"
              >
                修改密码
              </Button>
              <Button className="mt-1" href="/index" block>
                返回首页
              </Button>
              {this.state.hasLogin && (
                <div className="fs-12 pg-entrance-form_item-tips">
                  <span></span>
                  <p>
                    已有帐号?
                    {isHiring ? (
                      <Link to="/entrance/biz/login">去登录</Link>
                    ) : (
                      <Link to="/entrance/biz/login">去登录</Link>
                    )}
                  </p>
                </div>
              )}
            </Form.Item>
          </Form>
        </div>
      </Spin>
    );
  }
}
