import React, { useState } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import {
  Spin,
  Layout,
  Row,
  Col,
  Menu,
  Dropdown,
  Avatar,
  message,
  Input,
  Button,
  Carousel,
  Icon,
  Card,
  Affix,
  Alert,
  AutoComplete,
} from "antd";
import { DownOutlined } from "@ant-design/icons";

import { UserOutlined, SearchOutlined } from "@ant-design/icons";
import httpPromise from "@/api";

import WOW from "wowjs";
import ReactWOW from "react-wow";
import Slider from "@ant-design/react-slick";
import cookie from "react-cookies";
import _ from "lodash";

import "./indexnew.less";
import "animate.css";

import CompanyLogin from "../entrance/login/companylogin";
import Headers from "./Headers";
import HomeFooter from "../../components/homeFooter";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

class Indexnew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userinfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      mode: "inline",
      theme: "light",
      container: null,
      current: 1,
      codeType: true,
      scrollNum: 600,
      loginCurrent: 1,
      cIndex: 0,
      searchVal: "",
    };
    this.ref = null;
    this.carouselRef = React.createRef();
  }
  componentDidMount() {
    var urlhash = window.location.hash;
    if (!urlhash.match("fromapp")) {
      if (navigator.userAgent.match(/(iPhone|iPod|Android|ios|iPad)/i)) {
        window.location.href = "http://m.slzhitui.com/index"; // 替换成你要跳转的移动页面的URL
      }
    }

    this.getUserInfo();
  }
  componentWillUnmount() {}

  getUserInfo = async () => {
    let t = this;
    httpPromise({ apiDemo: "companyGetUserInfo" })
      .then(function (res) {
        if (res.code === 200) {
          t.setState({ loading: false, userinfo: res.data });
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          cookie.save("userinfo", res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeCIndex(index) {
    this.setState({
      cIndex: index,
    });
    console.log(this.carouselRef);
    this.carouselRef.current.goTo(index);
  }

  render() {
    const { userinfo, cIndex, searchVal } = this.state;

    return (
      <Spin spinning={this.state.loading}>
        <Headers userinfo={userinfo} />
        <Layout className="indexnew">
          <Layout style={{ minHeight: "100vh" }}>
            <Content style={{ position: "relative" }}>
              <div className="top-banner">
                <div className="container">
                  {/* {((!userinfo || !userinfo.name) && (
                    <div className="login-wrap">
                      <CompanyLogin loginCurrent={loginCurrent} />
                    </div>
                  )) || <div></div>} */}
                  <div className="title">速猎直推</div>
                  <div className="subtitle">智能推荐人才服务的领导者</div>
                  <div className="search">
                    <Input
                      value={searchVal}
                      placeholder="搜索想要的人才"
                      onChange={(e) => {
                        this.setState({ searchVal: e.target.value });
                      }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        this.props.history.push(
                          `/selected?search=${searchVal}`
                        );
                      }}
                    >
                      搜索
                    </Button>
                  </div>
                </div>
              </div>
              <div className="intro-info">
                <section className="container clearfix">
                  <dl>
                    <dt>
                      <span>专业</span>
                    </dt>
                    <dd>活跃精英人才库</dd>
                  </dl>
                  <dl>
                    <dt>
                      <span>免费</span>
                    </dt>
                    <dd>极速发布职位 搜索查看简历</dd>
                  </dl>
                  <dl>
                    <dt>
                      <span>高效</span>
                    </dt>
                    <dd>大数据算法智能推荐匹配人选</dd>
                  </dl>
                  <dl>
                    <dt>
                      <span>交易</span>
                    </dt>
                    <dd>优质企业猎头订单</dd>
                  </dl>
                </section>
              </div>
              {/* <div className="used block">
                <div className="container clearfix">
                  <div className="title">他们正在使用</div>
                  <div className="logo-list">
                    <div className="logo"></div>
                    <div className="logo"></div>
                    <div className="logo"></div>
                    <div className="logo"></div>
                    <div className="logo"></div>
                    <div className="logo"></div>
                    <div className="logo"></div>
                    <div className="logo"></div>
                    <div className="logo"></div>
                    <div className="logo"></div>
                    <div className="logo"></div>
                    <div className="logo"></div>
                  </div>
                </div>
              </div> */}
              <div className="whychoose block">
                <div className="container clearfix">
                  <div className="title">为什么选择速猎直推</div>
                  <div className="intro-cont">
                    <div className="navs">
                      <div
                        className={`navs-item ${(cIndex == 0 && "cur") || ""}`}
                        onClick={() => {
                          this.changeCIndex(0);
                        }}
                      >
                        海量人才
                      </div>
                      <div
                        className={`navs-item ${(cIndex == 1 && "cur") || ""}`}
                        onClick={() => {
                          this.changeCIndex(1);
                        }}
                      >
                        简单便捷
                      </div>
                      <div
                        className={`navs-item ${(cIndex == 2 && "cur") || ""}`}
                        onClick={() => {
                          this.changeCIndex(2);
                        }}
                      >
                        效果付费
                      </div>
                      <div
                        className={`navs-item ${(cIndex == 3 && "cur") || ""}`}
                        onClick={() => {
                          this.changeCIndex(3);
                        }}
                      >
                        高效快速
                      </div>
                      <div
                        className={`navs-item ${(cIndex == 4 && "cur") || ""}`}
                        onClick={() => {
                          this.changeCIndex(4);
                        }}
                      >
                        精选推荐
                      </div>
                      <div
                        className={`navs-item ${(cIndex == 5 && "cur") || ""}`}
                        onClick={() => {
                          this.changeCIndex(5);
                        }}
                      >
                        智能算法
                      </div>
                      <div
                        className={`navs-item ${(cIndex == 6 && "cur") || ""}`}
                        onClick={() => {
                          this.changeCIndex(6);
                        }}
                      >
                        质量保证
                      </div>
                    </div>
                    <Carousel ref={this.carouselRef} className="carousel">
                      <div className={`carousel-item`}>
                        <div className="intro">
                          <div className="title">速猎直推精选人才库</div>
                          <div className="subtitle">
                            经过速猎直推严格筛选，拥有超过100000+精选人才库，海量人才让企业能快速的找到自己所需要的钟意人选。
                          </div>
                        </div>
                        <div className="cover">
                          <img
                            src={require("../../atess/image/indexnew/carousel/1.png")}
                          />
                        </div>
                      </div>
                      <div className={`carousel-item`}>
                        <div className="intro">
                          <div className="title">快速招聘只需一步</div>
                          <div className="subtitle">
                            告别繁琐流程，使用速猎直推招聘简单便捷的开始，只需发布人才要求，速猎直推将会为您快速推荐合适的人才。
                          </div>
                        </div>
                        <div className="cover">
                          <img
                            src={require("../../atess/image/indexnew/carousel/2.png")}
                          />
                        </div>
                      </div>
                      <div className={`carousel-item`}>
                        <div className="intro">
                          <div className="title">不成功，不收费</div>
                          <div className="subtitle">
                            速猎直推首创按效果付费的招聘方式，通过快速面试，45天入职后结算，简单更直接！
                          </div>
                        </div>
                        <div className="cover">
                          <img
                            src={require("../../atess/image/indexnew/carousel/3.png")}
                          />
                        </div>
                      </div>
                      <div className={`carousel-item`}>
                        <div className="intro">
                          <div className="title">高效快速的招聘</div>
                          <div className="subtitle">
                            速猎直推，通过海量精选人才库可高效推荐快至1分钟即可推荐优秀人才！
                          </div>
                        </div>
                        <div className="cover">
                          <img
                            src={require("../../atess/image/indexnew/carousel/4.png")}
                          />
                        </div>
                      </div>
                      <div className={`carousel-item`}>
                        <div className="intro">
                          <div className="title">精选推荐 精准高效</div>
                          <div className="subtitle">
                            依托于速猎直推海量人才库，精选更适合更匹配的人才。
                          </div>
                        </div>
                        <div className="cover">
                          <img
                            src={require("../../atess/image/indexnew/carousel/5.png")}
                          />
                        </div>
                      </div>
                      <div className={`carousel-item`}>
                        <div className="intro">
                          <div className="title">高效匹配算法</div>
                          <div className="subtitle">
                            基于高效的人才匹配算法，让您快速匹配到想要的人才
                          </div>
                        </div>
                        <div className="cover">
                          <img
                            src={require("../../atess/image/indexnew/carousel/6.png")}
                          />
                        </div>
                      </div>
                      <div className={`carousel-item`}>
                        <div className="intro">
                          <div className="title">45天入职再付费</div>
                          <div className="subtitle">
                            满意入职再付费，不花一分冤枉钱
                          </div>
                        </div>
                        <div className="cover">
                          <img
                            src={require("../../atess/image/indexnew/carousel/7.png")}
                          />
                        </div>
                      </div>
                    </Carousel>
                  </div>
                </div>
              </div>

              <div className="start">
                <div className="title">5000 + 企业首选的招聘平台</div>
                <div className="btn-cont">
                  <Button href="/entrance/biz/register">
                    使用速猎直推开始招聘
                  </Button>
                </div>
              </div>

              <HomeFooter />
            </Content>
          </Layout>
        </Layout>
      </Spin>
    );
  }
}

export default Indexnew;
