import instance from './instance.js';
import { base,base2 } from "./config.js";
import {message} from 'antd'
export default {
  //用户注册/登录
  async userlogin(params){
    return  await instance.post(`${base}/user/base/auth`,params).then((res)=>{
      return res.data;
    }).catch((error)=>{
      message.error('服务器出错');
    });
  },
  //获取用户信息
  async user_Info(params) {
    return await instance.get(`${base}/user/base/info?id=${params}`, ).then((res) => {
      return res.data;
    }).catch((error) => {
      //message.error('服务器出错')
    });
  },
  //忘记密码
  async userResetPwd(params){
    return await instance.put(`${base}/user/base/resetPwd`,params).then((res)=>{
      return res.data;
    }).catch((error)=>{
      //message.error('服务器出错')
    });
  },
  //修改信息
  async updata_Info({type,params}) {
    return await instance.put(`${base}/user/base/account/${type}`, params).then((res) => {
      return res.data;
    }).catch((error) => {
      //message.error('服务器出错')
    });
  },
  //
  async user_isin(params) {
    return await instance.get(`${base}/user/isin?telphone=${params}`, ).then((res) => {
      return res.data;
    }).catch((error) => {
      //message.error('服务器出错')
    });
  },
  // UserQrcode
  // 用户扫码登录二维码
  async getqrcode() {
    return await instance.get(`${base}/user/qrcode/img`, ).then((res) => {
      return res.data;
    }).catch((error) => {
      //message.error('服务器出错')
    });
  },
  async regqrcode(params) {
    return await instance.get(`${base}/user/qrcode/img/${params}`, ).then((res) => {
      return res.data;
    }).catch((error) => {
      //message.error('服务器出错')
    });
  },
  async logout(){
    return  await instance.post(`${base}/logout`).then((res)=>{
      return res.data;
    }).catch((error)=>{
      //message.error('服务器出错')
    });
  },
}