import React from "react";
import { Link } from "react-router-dom";
import {
  message,
  Row,
  Col,
  Button,
  notification,
  Modal,
  Form,
  InputNumber,
  Carousel,
} from "antd";
import {
  DoubleRightOutlined,
  MoneyCollectOutlined,
  VerticalAlignTopOutlined,
  InteractionOutlined,
} from "@ant-design/icons";

import cookie from "react-cookies";
import CountUp from "react-countup";
import { QRCodeCanvas } from "qrcode.react";

import "./index.less";
import httpPromise from "@/api";

const openNotificationWithIcon = (type) => {
  notification[type]({
    message: "信息提示",
    description: "该功能正在开发中",
  });
};

export default class BizHome extends React.Component {
  timeRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      shortcutLis: [
        {
          title: "精选人才库",
          path: "/biz/share",
          image: "home-jingxuanrencaiku.png",
          isWait: false,
        },
        {
          title: "悬赏招聘",
          path: "/biz/recruit",
          image: "home-xuanshangzhaopin.png",
          isWait: false,
        },
        {
          title: "企业培训",
          path: "/biz/home",
          image: "home-qiyepeixun.png",
          isWait: true,
        },
        {
          title: "人事培训",
          path: "/biz/home",
          image: "home-renshipeixun.png",
          isWait: true,
        },
      ],
      moneyList: [
        {
          field: "balance",
          title: "人才币",
          icon: <MoneyCollectOutlined />,
        },
        {
          field: "top_number",
          title: "置顶次数",
          icon: <VerticalAlignTopOutlined />,
        },
        {
          field: "refresh_number",
          title: "刷新次数",
          icon: <InteractionOutlined />,
        },
      ],
      balance: {},
      peopleList: [
        {
          title: "S级",
          link: require("../../atess/image/S.png"),
          number: 0,
        },
        {
          title: "A级",
          link: require("../../atess/image/Aj.png"),
          number: 0,
        },
        {
          title: "B级",
          link: require("../../atess/image/B.png"),
          number: 0,
        },
        {
          title: "C级",
          link: require("../../atess/image/C.png"),
          number: 0,
        },
      ],
      shareList: [
        {
          link: require("../../atess/image/S01.png"),
          number: 0,
        },
        {
          link: require("../../atess/image/A01.png"),
          number: 0,
        },
        {
          link: require("../../atess/image/B01.png"),
          number: 0,
        },
        {
          link: require("../../atess/image/C01.png"),
          number: 0,
        },
        {
          title: "无效",
          type: "text-gry2",
          number: 0,
        },
        {
          title: "审核中",
          type: "text-error",
          number: 0,
        },
      ],
      bannerList: [
        {
          title: "合作企业",
          number: "6896",
        },
        {
          title: "合作HR",
          number: "16896",
        },
        {
          title: "累计推荐人才",
          number: "10328",
        },
      ],
      visible: false,
      visible1: false,
      modalLoading: false,
      userInfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      rechargeQrcode: "",
      rechargeVisible: false,
      rechargeParams: {},
      money: 100,
      visibleStart: false,
    };
  }

  componentWillMount = () => {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      return;
    }
    this.setState({ userInfo });

    this.getbalance();
    this.getuserinfo();
  };

  getuserinfo = () => {
    httpPromise({ apiDemo: "companyGetUserInfo" })
      .then((res) => {
        if (res.code === 200) {
          this.setState({ userInfo: res.data });
          cookie.save("userinfo", res.data);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getbalance = () => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companyGetBalance" })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({ balance: res.data });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showModal1 = () => {
    this.setState({
      visible1: true,
    });
  };

  handleOk = () => {
    this.setState({ modalLoading: true });
    setTimeout(() => {
      this.setState({ modalLoading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCancel1 = () => {
    this.setState({ visible1: false });
  };
  rechargeCall = async (money) => {
    this.setState({ rechargeVisible: true });
  };
  getQrcode = async (money) => {
    clearInterval(this.timeRef.current);
    httpPromise({
      apiDemo: "companyRecharge",
      params: {
        id: this.state.userInfo.id,
        type: 2,
        money: money || this.state.money,
      },
    })
      .then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.setState({
            rechargeQrcode: res.data.code_url,
            rechargeParams: {
              order_id: res.data.order_id,
            },
          });

          this.timeRef.current = setInterval(async () => {
            httpPromise({
              apiDemo: "companyPayStatus",
              params: {
                id: this.state.rechargeParams.order_id,
              },
            })
              .then((res) => {
                if (res.code === 200) {
                  if (res.data.status == 1) {
                    this.setState({
                      rechargeParams: {},
                      visible: false,
                      rechargeVisible: false,
                    });
                    message.success("已完成充值");
                    this.getbalance();
                    clearInterval(this.timeRef.current);
                  }
                }
              })
              .catch((error) => {
                console.log(error);
                clearInterval(this.timeRef.current);
              });
          }, 3000);
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  rechargeOk = async () => {
    this.getbalance();
    this.setState({ visible: false, rechargeVisible: false });
    clearInterval(this.timeRef.current);
  };
  rechargeCancel = () => {
    this.setState({ rechargeVisible: false, rechargeQrcode: "" });
    clearInterval(this.timeRef.current);
  };

  render() {
    let {
      shortcutLis,
      moneyList,
      balance,
      userInfo,
      bannerList,
      shareList,
      peopleList,
      visible,
      visible1,
      modalLoading,
      rechargeQrcode,
      rechargeVisible,
      rechargeParams,
      money,
      visibleStart,
    } = this.state;
    // console.log(userInfo?.state);
    return (
      <main className="pg-biz-home">
        <Row gutter="24">
          <Col span={18}>
            <section className="md-section slider">
              <Carousel autoplay={true}>
                <div>
                  <img
                    style={{ maxWidth: "100%" }}
                    src={require("../../atess/image/carousel/1d5e0f80-7069-4312-94b1-1f0e777b68ab.jpg")}
                  />
                </div>
                <div>
                  <img
                    style={{ maxWidth: "100%" }}
                    src={require("../../atess/image/carousel/084da50b-2e07-4310-a7ef-6bcd77070965.jpg")}
                  />
                </div>
                <div>
                  <img
                    style={{ maxWidth: "100%" }}
                    src={require("../../atess/image/carousel/333c43d1-c426-430f-8212-181239dd1bf6.jpg")}
                  />
                </div>
                <div>
                  <img
                    style={{ maxWidth: "100%" }}
                    src={require("../../atess/image/carousel/e334cf3c-db00-4470-80b7-000e19003a6a.jpg")}
                  />
                </div>
              </Carousel>
            </section>
            <section className="md-section border">
              <header className="md-section-header">快捷入口</header>
              <Row gutter={16} className="md-section-body">
                {shortcutLis.map((item, index) => {
                  return (
                    <Col className="md-section-row" span={6} key={index}>
                      <Link
                        className="md-section-item"
                        to={item.path}
                        onClick={() =>
                          (!!item.isWait && openNotificationWithIcon("info")) ||
                          ""
                        }
                      >
                        {/* <p className="md-section-item_title">{item.title}</p> */}
                        {/* <p className="md-section-item_desc">{item.desc}</p> */}
                        {/* {item.icon} */}
                        <img
                          src={require(`../../atess/image/${item.image}`)}
                          style={{ width: "100%", maxWidth: "100%" }}
                        />
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            </section>
            <section className="md-section text-center banner">
              <h2 className="my-2 text-white fs-24">
                倾我平台资源，铸您企业辉煌
              </h2>
              <p className="my-2">
                众人抬柴火焰高，人力资源所面临的问题还需要人力资源从业者共同来解决平台数千名HR帮您一起解决招人问题
              </p>
              <p className="my-2">专注于人才的优化与配置</p>
              {/* <Row gutter={16} className="md-section-body my-2">
    {bannerList.map((item, index) => {
      return (
        <Col className="md-section-rich" span={8} key={index}>
          <p className="fs-40 lh-50">
            <CountUp
              start={0}
              end={item.number}
              duration="3"
            ></CountUp>{" "}
            +
          </p>
          <p className="fs-18 lh-30">{item.title}</p>
        </Col>
      );
    })}
  </Row> */}
              <div className="text-center mt-1">
                <Button ghost size="large">
                  <Link to="/biz/recruit">点我发布“悬赏招聘职位”</Link>
                </Button>
              </div>
            </section>

            <section className="md-section border">
              <header className="md-section-header">
                企业人才{" "}
                <Link className="md-section-header_right" to="/biz/download">
                  查看已下载简历 <DoubleRightOutlined />
                </Link>
              </header>
              <Row gutter={16} className="md-section-body">
                {peopleList.map((item, index) => {
                  return (
                    <Col
                      className="md-section-rich"
                      span={6}
                      key={index}
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        padding: "24px 0",
                      }}
                    >
                      <img src={item.link} style={{ marginRight: "24px" }} />
                      <p
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <span className="fs-30 lh-30">{item.number}</span>
                        <span className="lh-50">{item.title}简历</span>
                      </p>
                    </Col>
                  );
                })}
              </Row>
            </section>

            <section className="md-section border">
              <header className="md-section-header">
                分享简历{" "}
                <Link className="md-section-header_right" to="/biz/share">
                  查看已分享简历 <DoubleRightOutlined />
                </Link>
              </header>
              <Row gutter={16} className="md-section-body">
                {shareList.map((item, index) => {
                  return (
                    <Col
                      className={`md-section-rich ${item.type || ""}`}
                      span={4}
                      key={index}
                    >
                      <span className="fs-30 lh-50">{item.number}</span>
                      <span className="lh-50">
                        {item.title || <img src={item.link} />}
                      </span>
                    </Col>
                  );
                })}
              </Row>
            </section>
          </Col>
          <Col span={6}>
            <Button
              type="primary"
              block
              size="large"
              style={{
                height: "64px",
                fontSize: "18px",
                marginBottom: "24px",
              }}
            >
              <Link to="/biz/share">分享简历</Link>
            </Button>
            <section className="md-section border">
              <header className="md-section-header">
                账户信息{" "}
                <div className="ml-auto">
                  <Button
                    type="primary"
                    size="small"
                    className="mr-1"
                    style={{ height: "auto" }}
                    onClick={() => {
                      this.showModal();
                    }}
                  >
                    充值人才币
                  </Button>
                  <Button
                    type="primary"
                    size="small"
                    style={{ height: "auto" }}
                    onClick={() => {
                      this.showModal1();
                    }}
                  >
                    人才币说明
                  </Button>
                </div>
              </header>
              <Row gutter={16} className="md-section-body">
                {moneyList.map((item, index) => {
                  return (
                    <Col className="md-section-rich" span={8} key={index}>
                      <span className="fs-24 lh-50 text-error">
                        {balance ? balance[item.field] : 0}
                      </span>
                      <span className="lh-30">{item.title}</span>
                    </Col>
                  );
                })}
              </Row>
            </section>
            <section className="md-section border">
              <header className="md-section-header">扫码联系客服</header>
              <div>
                <p style={{ marginBottom: "24px" }}>
                  扫描下方二维码，将简历信息或简历截图发送给客服，并留下您的平台登录账号。平台审核通过后，将给您的账户奖励简历人才币。
                </p>
                <img
                  src={require("../../atess/image/qrcode.png")}
                  style={{
                    display: "block",
                    margin: "auto",
                    width: "80%",
                    height: "auto",
                    backgroundColor: "#eee",
                  }}
                />
              </div>
            </section>
            <section className="md-section border">
              <header className="md-section-header">简历审核</header>
              <div>
                为保证简历真实有效，平台将对HR上传简历信息进行审核，审核通过后将会给您的企业增加人才币。要求简历中的联系方式为本人号码，且求职者当下正在找工作。
              </div>
            </section>
          </Col>
        </Row>

        <Modal
          visible={rechargeVisible}
          title="微信扫码支付充值人才币"
          onOk={this.rechargeOk}
          onCancel={this.rechargeCancel}
          width="400px"
          footer={[
            <Button key="submit" type="primary" onClick={this.rechargeOk}>
              完成充值
            </Button>,
            <Button key="link" onClick={this.rechargeCancel}>
              取消
            </Button>,
          ]}
        >
          {userInfo.state == 1 && (
            <div className="mb-3">
              <Form.Item
                label="充值金额"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
              >
                <Row gutter={30}>
                  <Col span={12}>
                    <InputNumber
                      min={0.01}
                      style={{ width: "100%" }}
                      value={money}
                      onChange={(val) => {
                        this.setState({
                          money: val,
                        });
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <Button
                      onClick={() => {
                        localStorage.setItem("paymain", money);
                        window.location.href = "/paymain";
                      }}
                    >
                      去支付
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </div>
          )}
        </Modal>
        <Modal
          visible={visible}
          title="增加人才币"
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          width="600px"
          footer={[
            <Button
              key="submit"
              type="primary"
              //   loading={modalLoading}
              //   onClick={this.handleOk}
            >
              分享简历
            </Button>,
            <Button
              key="link"
              type="primary"
              //   loading={modalLoading}
              onClick={this.rechargeCall}
            >
              在线充值
            </Button>,
          ]}
        >
          <div>
            <p>
              可通过以下方式快速获取人才币：<b>分享简历</b>或<b>联系客服</b>
              在线充值。相关规则，说明如下。
            </p>
            <p>
              上传审核通过后，
              <span style={{ color: "rgb(151,57,230)", fontSize: "16px" }}>
                S级
              </span>
              可获得<span style={{ color: "red", fontSize: "16px" }}>8</span>
              个人才币； 上传审核通过后，
              <span style={{ color: "orange", fontSize: "16px" }}>A级</span>
              可获得<span style={{ color: "red", fontSize: "16px" }}>6</span>
              个人才币
            </p>
            <p>
              上传审核通过后，
              <span style={{ color: "green", fontSize: "16px" }}>B级</span>
              可获得<span style={{ color: "red", fontSize: "16px" }}>2</span>
              个人才币； 上传审核通过后，
              <span style={{ color: "skyblue", fontSize: "16px" }}>C级</span>
              可获得<span style={{ color: "red", fontSize: "16px" }}>0</span>
              个人才币
            </p>
            <p>联系客服，获取更多人才币。</p>
          </div>
        </Modal>
        <Modal
          visible={visible1}
          title="人才币说明"
          onOk={this.handleOk}
          onCancel={this.handleCancel1}
          width="600px"
          footer={[
            <Button key="link" type="primary" onClick={this.handleCancel1}>
              确定
            </Button>,
          ]}
        >
          <div>
            <p>
              <b>1. 人才币的获得？</b>
            </p>
            <p>
              企业在各自后台“分享简历”中上传一条求职者简历信息，审核通过后即可获得相应数量的人才币。
            </p>
            <p>
              <b>2. 人才币的使用？</b>
            </p>
            <p>
              人才币是速猎直推人才云聘中用于下载简历，使用面试申请，代理招聘等功能的虚拟货币。
            </p>
          </div>
        </Modal>
      </main>
    );
  }
}
