import React from "react";
import httpPromise from "@/api";
import { Form, Cascader } from "antd";

export default class LinkAge extends React.Component {
  ref = React.createRef();
  state = {
    loading: false,
    options: [],
    isExit: false,
  };
  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    this.getCityList();
  };

  componentWillReceiveProps = (props) => {
    if (props.defaultValue === this?.ref?.current?.props?.value) {
      return;
    }
    if (props.defaultValue && this.state.options.length && !props.isExit) {
      let targer = null;
      props.defaultValue.reduce((pre, cur, index) => {
        return pre.then(() => {
          let data = index ? targer.children : this.state.options;
          targer = data.find((option) => cur === option.code);
          return this.getCityList(targer);
        });
      }, Promise.resolve());
    }
  };

  loadData = (selectedOptions) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    this.getCityList(targetOption);
  };

  getCityList = (target) => {
    let params = {};
    if (target) {
      params.code = target.code;
    } else {
      this.setState({ loading: false });
    }
    // console.log(target);
    return new Promise((resolve, reject) => {
      httpPromise({ apiDemo: "common_get_city", params })
        .then((res) => {
          if (target) {
            target.loading = false;
          }
          this.setState({ loading: false });
          if (200 !== res.code) {
            return;
          }
          if (res.data.isLeaf) {
            return;
          }
          let options = [
            ...res.data.map((item) => {
              item.isLeaf = item.code.length === 6;
              return item;
            }),
          ];
          if (!target) {
            this.setState({ options: [...options] });
          } else {
            target.children = [...options];
            this.setState({ options: [...this.state.options] });
          }
          resolve();
        })
        .catch((error) => {
          this.setState({ loading: false });
          console.log(error);
          reject();
        });
    });
  };

  render() {
    let { placeholder, name, label, rules } = this.props;
    let { options } = this.state;
    return (
      <Form.Item name={name} label={label} rules={rules}>
        <Cascader
          ref={this.ref}
          fieldNames={{ label: "name", value: "code" }}
          options={options}
          style={{ width: "100%" }}
          loadData={this.loadData}
          placeholder={placeholder}
        />
      </Form.Item>
    );
  }
}
