import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Alert, Modal } from "antd";

import Index from "@/pages/base/indexnew";
import Selected from "@/pages/base/selected";
import Hot from "@/pages/base/hot";
import Famous from "@/pages/base/famous";
import Service from "@/pages/base/service";
import Entrance from "@/pages/entrance/index";
import Forget from "@/pages/entrance/forget";
import Home from "@/pages/home/index";
import RichText from "@/pages/richtext";
import Paymain from "@/pages/paymain/index";
import FeedBack from "./pages/feedback";
import Alerts from "./pages/alerts";

import "./App.css";
import "./apps.less";

function App(porps) {
  return (
    <div className="app" style={{ position: "relative", height: "100%" }}>
      <Alerts />
      <div className="feedback-box">
        <FeedBack />
      </div>

      <Switch>
        {/* 首页部分 */}
        {/* <Route path="/" exact component={Index} onEnter={() => { document.title = "登录 - 速聘达" }} /> */}
        <Route path="/index" component={Index} />
        <Route path="/selected" component={Selected} />
        <Route path="/hot" component={Hot} />
        <Route path="/famous" component={Famous} />
        <Route path="/service" component={Service} />

        <Route path="/entrance" component={Entrance} />

        {/* <Route path="/reset" component={Forget} /> */}
        {/* <Route path="/base" component={Index} /> */}
        <Route path="/biz" component={Home} />
        <Route path="/hr" component={Home} />
        <Route path="/deal" component={RichText} />
        <Route path="/paymain" component={Paymain} />
        {/* test */}

        {/* <Route path="/test/upload" component={PicturesWall} /> */}
        <Redirect to="/index" />
      </Switch>
    </div>
  );
}

export default App;
