// store.js
import { createStore } from 'redux';

// 定义Action的类型
const SHOW_ALERT = 'SHOW_ALERT';
const HIDE_ALERT = 'HIDE_ALERT';

// 定义Action的创建函数
export const showAlert = () => ({
  type: SHOW_ALERT
});

export const hideAlert = () => ({
  type: HIDE_ALERT
});

// 定义Reducer
const alertReducer = (state = false, action) => {
  switch (action.type) {
    case SHOW_ALERT:
      return true;
    case HIDE_ALERT:
      return false;
    default:
      return state;
  }
};

// 创建Store
const store = createStore(alertReducer);

export default store;