import { createStore } from 'redux';
// 初始化
const initialState = {
    username: null,
    token: null
};
// 定义一个仓库类
function userInfoReducer(state = initialState, action) {
    // 根据action修改state
    return {
        ...state,
        ...action
    }
}
const UserInfoStore = createStore(userInfoReducer);
// 导出仓库；
export default UserInfoStore;