import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  Alert,
  Space,
  Empty,
  Typography,
  message,
  Row,
  Col,
  Collapse,
  Form,
  Modal,
} from "antd";

import "./index.less";
import httpPromise from "@/api";

const { Text } = Typography;
const { Panel } = Collapse;

export default class BizDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      visibleNew: false,
      visibleNewInfo: {},
      psList: [],
      issueList: [],
      tableInfo: [
        {
          title: "申请时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "联系信息",
          dataIndex: "name",
          key: "name",
          render: (text, item) => (
            <div
              onClick={async () => {
                let res = this.setState({
                  visibleNew: true,
                  visibleNewInfo: item,
                });
              }}
              style={{ color: "#1890ff", cursor: "pointer" }}
            >
              <div>{text}</div>
              <div>{item.mobile}</div>
            </div>
          ),
        },
        {
          title: "性别",
          dataIndex: "sex",
          key: "sex",
        },
        {
          title: "年龄",
          dataIndex: "age",
          key: "age",
        },
        {
          title: "学历",
          dataIndex: "education",
          key: "education",
        },
        {
          title: "经验",
          dataIndex: "experience",
          key: "experience",
        },
        {
          title: "意向职业",
          dataIndex: "occupation",
          key: "occupation",
        },
        {
          title: "意向地区",
          dataIndex: "cities",
          key: "cities",
        },
        {
          title: "个人优势",
          dataIndex: "advantage",
          key: "advantage",
        },
      ],
    };
  }

  componentWillMount = () => {
    this.getPsList();
    this.getIssue();
  };

  download = (id) => {
    httpPromise({
      apiDemo: "companyDownloadResume",
      params: {
        ids: id + "",
      },
    })
      .then((res) => {
        console.log(1);
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("操作成功");
          this.getPsList();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleTableChange = ({ current }, filters, sorter) => {
    let { pagination } = this.state;
    pagination.pageNo = current;
    this.setState({ pagination });
    this.getPsList();
  };

  getPsList = () => {
    this.setState({ loading: true });
    let { pagination } = this.state;
    httpPromise({
      apiDemo: "companyDownloadResumeList",
      params: { ...pagination },
    })
      .then((res) => {
        console.log(1);
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({
            psList: res.data.data,
            pagination: { ...pagination, total: res.data.total },
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getIssue = () => {
    this.setState({ loading: true });
    let { pagination } = this.state;
    httpPromise({ apiDemo: "companyIssue" })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({ issueList: res.data });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  continue = (id) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyEditJob",
      params: {
        id,
        state: 1,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getPsList();
          message.success("操作成功");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  pause = (id) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyEditJob",
      params: {
        id,
        state: 0,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getPsList();
          message.success("操作成功");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  over = (id) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyEditJob",
      params: {
        id,
        state: 2,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getPsList();
          message.success("操作成功");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toTop = (id) => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companySetTop", params: { id } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("置顶成功");
          this.getPsList();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toRefre = (id) => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companySetRefresh", params: { id } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("刷新成功");
          this.getPsList();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    let {
      psList,
      tableInfo,
      pagination,
      loading,
      issueList,
      visibleNew,
      visibleNewInfo,
    } = this.state;
    return (
      <main className="pg-biz-recruit">
        <Row gutter="24">
          <Col span={18}>
            <Alert
              message="温馨提示：线上HR推荐人才到企业面试，企业需及时更改求职者状态。"
              type="warning"
            />
            <section
              className="md-section border"
              style={{ marginTop: "24px" }}
            >
              <header
                className="md-section-header"
                style={{ position: "relative" }}
              >
                人才管理
              </header>
              <div className="md-section-body">
                <Table
                  columns={tableInfo}
                  dataSource={psList}
                  onChange={this.handleTableChange}
                  pagination={{
                    current: pagination.pageNo,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                  }}
                  loading={loading}
                  rowKey="id"
                />
              </div>
            </section>
          </Col>
          <Col span={6}>
            <section className="md-section border">
              <header className="md-section-header">常见问题</header>
              {issueList.length ? (
                <Collapse accordion className="md-section-body">
                  {issueList.map((item) => (
                    <Panel header={item.title} key={item.id}>
                      <p>{item.content}</p>
                    </Panel>
                  ))}
                </Collapse>
              ) : (
                <div className="md-section-body">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </section>
          </Col>
        </Row>

        <Modal
          open={visibleNew}
          title={`【${visibleNewInfo?.name}】 的 信息`}
          onCancel={() => {
            this.setState({
              visibleNew: false,
              visibleNewInfo: {},
            });
          }}
          width="850px"
          footer={false}
          className="info-modal"
        >
          <Form layout="vertical">
            <div className="new_resume">
              <div className="page-header">
                <div className="title mb-3">基础信息</div>
              </div>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="name" label="真实姓名">
                    {visibleNewInfo?.name}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="age" label="年龄">
                    {visibleNewInfo?.age}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="mobile" label="联系方式">
                    {visibleNewInfo?.mobile}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="experience" label="工作经验">
                    {visibleNewInfo?.experience}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sex" label="性别">
                    {visibleNewInfo?.sex}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="education" label="学历">
                    {visibleNewInfo?.education}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="state" label="求职状态">
                    {visibleNewInfo?.state}
                  </Form.Item>
                </Col>
              </Row>

              <div className="page-header">
                <div className="title mb-3">求职意向</div>
              </div>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="occupation" label="意向职位">
                    {visibleNewInfo?.occupation}
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="cities" label="意向城市">
                    {visibleNewInfo?.cities}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="期望薪资">
                    {visibleNewInfo?.min_salary} ~ {visibleNewInfo?.max_salary}
                  </Form.Item>
                </Col>
              </Row>

              <div className="page-header">
                <div className="title mb-3">个人优势</div>
              </div>
              <Form.Item name="advantage">
                {visibleNewInfo?.advantage}
              </Form.Item>

              <div className="page-header">
                <div className="title mb-3">社交主页</div>
              </div>
              <Form.Item name="page">{visibleNewInfo?.page}</Form.Item>
              <div className="page-header">
                <div className="title mb-3">工作经历</div>
              </div>
              {(visibleNewInfo?.experienceList &&
                visibleNewInfo?.experienceList.length &&
                visibleNewInfo?.experienceList.map((item, ind) => (
                  <Space
                    key={ind}
                    style={{
                      display: "block",
                      background: "rgb(248,248,248)",
                      padding: 15,
                      marginBottom: 20,
                      position: "relative",
                    }}
                    align="baseline"
                  >
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="公司名称">
                          {item.corporate_name}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="公司行业">
                          {item.corporate_industry}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="在职时间">
                          {item.entry_time} ~ {item.depart_time}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="职位名称">{item.position}</Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="工作内容">{item.work_content}</Form.Item>
                    <Row gutter={30}>
                      <Col span={6}>
                        <Form.Item label="月薪(选填)">{item.pay}</Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="所属部门(选填)">
                          {item.department}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="汇报对象(选填)">
                          {item.report}
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item label="下属人数(选填)">
                          {item.subordinates}
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item label="工作地点(选填)">
                      {item.work_location}
                    </Form.Item>
                  </Space>
                ))) || <div className="empty">暂无信息</div>}

              <div className="page-header">
                <div className="title mb-3">教育经历</div>
              </div>
              {(visibleNewInfo?.educationalList &&
                visibleNewInfo?.educationalList.length &&
                visibleNewInfo?.educationalList.map((item, ind) => (
                  <Space
                    key={ind}
                    style={{
                      display: "block",
                      background: "rgb(248,248,248)",
                      padding: 15,
                      marginBottom: 20,
                      position: "relative",
                    }}
                    align="baseline"
                  >
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="学校名称">
                          {item.school_name}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="学历">{item.education}</Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="专业名称">
                          {item.professional_name}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="在校时间">
                          {item.start_date} ~ {item.end_date}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))) || <div className="empty">暂无信息</div>}
              <div className="page-header">
                <div className="title mb-3">项目经历</div>
              </div>
              {(visibleNewInfo?.projectList &&
                visibleNewInfo?.projectList.length &&
                visibleNewInfo?.projectList.map((item, ind) => (
                  <Space
                    key={ind}
                    style={{
                      display: "block",
                      background: "rgb(248,248,248)",
                      padding: 15,
                      marginBottom: 20,
                      position: "relative",
                    }}
                    align="baseline"
                  >
                    <Form.Item label="项目名称">{item.product_name}</Form.Item>
                    <Form.Item label="项目描述">{item.product_intro}</Form.Item>
                    <Form.Item label="项目成就">{item.achieve}</Form.Item>
                    <Row gutter={30}>
                      <Col span={12}>
                        <Form.Item label="项目时间">
                          {item.start_date} ~ {item.end_date}
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="项目链接(选填)">
                          {item.link}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Space>
                ))) || <div className="empty">暂无信息</div>}
            </div>
          </Form>
        </Modal>
      </main>
    );
  }
}
