import React from 'react';
import Forget from '@/pages/entrance/forget/index';

export default class ChangePass extends React.Component {

    constructor(props) {
        super(props)
    }


    render() {
        return (
            <main className="pg-biz-changepass">
                <section className="md-section">
                    <header className="md-section-header">修改密码</header>
                    <Forget />
                </section>
            </main>
        )
    }
}


