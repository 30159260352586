import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  message,
  Form,
  Input,
  Checkbox,
  Button,
  Select,
  Steps,
  Cascader,
} from "antd";
import {
  LockOutlined,
  MobileOutlined,
  UserOutlined,
  HomeOutlined,
  MailOutlined,
} from "@ant-design/icons";
import Item from "antd/lib/list/Item";
import CountDown from "@/components/countdown";

import httpPromise from "@/api";
import LinkAge from "../../../components/linkage";

// const { Option } = Select;
const { Step } = Steps;

{
  /* 联系人信息 */
}
class ContactsModules extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let { showModules } = this.props;
    return (
      <div style={{ display: showModules ? "block" : "none" }}>
        <Form.Item
          label="联系人"
          name="contact"
          className="pg-entrance-form_item"
          rules={[{ required: true, message: "请输入联系人姓名" }]}
        >
          <Input placeholder="请输入联系人姓名" />
        </Form.Item>
        <Form.Item
          label="联系电话"
          name="phone"
          className="pg-entrance-form_item"
          rules={[
            { required: true, message: "请输入联系电话" },
            {
              pattern: /^1[3-9][0-9]{9}$/,
              message: "手机号格式不正确，请重新输入",
            },
          ]}
        >
          <Input maxLength="11" placeholder="联系电话" />
        </Form.Item>
      </div>
    );
  }
}
{
  /* 企业信息 */
}
class CompanyModules extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { showModules } = this.props;
    return (
      <div style={{ display: showModules ? "block" : "none" }}>
        <Form.Item
          name="name"
          label="企业名称"
          className="pg-entrance-form_item"
          rules={[{ required: true, message: "请输入企业名称" }]}
        >
          <Input placeholder="请输入企业名称" />
        </Form.Item>
        <LinkAge
          name="companyRegion"
          label="所在地区"
          className="pg-entrance-form_item"
          rules={[{ required: true, message: "请选择企业所在地区" }]}
          placeholder="请选择企业所在地区"
        />
        <Form.Item
          name="address"
          label="详细地址"
          className="pg-entrance-form_item"
          rules={[
            { required: true, message: "请输入企业详细地址，精确到门牌号" },
          ]}
        >
          <Input placeholder="请输入企业详细地址，精确到门牌号" />
        </Form.Item>
        <Form.Item
          name="intro"
          label="企业简介"
          className="pg-entrance-form_item"
          rules={[{ required: true, message: "请填写企业简介" }]}
        >
          <Input placeholder="请填写企业简介" />
        </Form.Item>
      </div>
    );
  }
}
{
  /* 验证手机 */
}
class PhoneModules extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
  }
  getMobileAndVal = () => {
    let { current } = this.formRef;
    let { value } = current.input;
    current.input.focus();
    current.input.blur();
    return value;
  };
  render() {
    let { showModules } = this.props;
    return (
      <div style={{ display: showModules ? "block" : "none" }}>
        <Form.Item
          name="mobile"
          label="手机号"
          className="pg-entrance-form_item"
          rules={[
            { required: true, message: "请输入手机号" },
            {
              pattern: /^1[3-9][0-9]{9}$/,
              message: "手机号格式不正确，请重新输入",
            },
          ]}
        >
          <Input maxLength="11" placeholder="请输入手机号" ref={this.formRef} />
        </Form.Item>

        <Form.Item required label="验证码" className="pg-entrance-form_item">
          <Form.Item
            name="smscode"
            noStyle
            rules={[{ required: true, message: "请输入验证码" }]}
          >
            <Input.Password
              style={{ width: "60%" }}
              placeholder="请输入验证码"
            />
          </Form.Item>
          <CountDown
            total={120}
            apiDemo="companyGetCode"
            mobileFn={this.getMobileAndVal}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="密码"
          className="pg-entrance-form_item"
          rules={[
            { required: true, message: "请输入密码" },
            {
              pattern: /^[0-9a-zA-Z]{8,}$/,
              message: "密码需为8位以上数字及字母的组合",
            },
          ]}
        >
          <Input.Password placeholder="请输入密码" />
        </Form.Item>

        <Form.Item
          name="confirm_password"
          label="确认密码"
          className="pg-entrance-form_item"
          rules={[
            { required: true, message: "请再次输入密码" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("两次密码输入不一致"));
              },
            }),
          ]}
        >
          <Input.Password placeholder="请再次输入密码" />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24 }}>
          <Form.Item
            noStyle
            name="argee"
            valuePropName="checked"
            className="pg-entrance-form_item"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("请同意相关协议")),
              },
            ]}
          >
            <Checkbox className="fs-12">同意</Checkbox>
          </Form.Item>
          <a className="fs-12" href="/deal" target="_blank">
            《用户服务协议》
          </a>
        </Form.Item>
      </div>
    );
  }
}

export default class CompanyRegister extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      stepIndex: 0,
    };
  }

  onFinish = (values) => {
    let [provinces_code, cities_code, areas_code] = values.companyRegion;
    let params = {
      ...values,
      provinces_code,
      cities_code,
      areas_code,
    };
    delete params.companyRegion;
    delete params.argee;
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyregister",
      params,
    })
      .then((res) => {
        this.setState({ loading: false });
        console.log(res);
        if (200 !== res.code) {
          message.error(res.message || "注册失败");
          return false;
        }
        message.success("注册成功");
        this.props.history.push("/entrance/biz/login");
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("注册失败");
        console.log(error);
      });
  };

  nextStep = () => {
    let validateArr = [];
    switch (this.state.stepIndex) {
      case 0:
        validateArr = ["contact", "phone"];
        break;
      case 1:
        validateArr = ["name", "companyRegion", "address", "intro"];
        break;
      case 2:
        validateArr = [
          "mobile",
          "smscode",
          "password",
          "confirmpassword",
          "argee",
        ];
        break;
    }
    this.formRef.current
      .validateFields(validateArr)
      .then((values) => {
        let index = this.state.stepIndex;
        this.setState({ stepIndex: ++index });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    let { stepIndex } = this.state;
    return (
      <div className="pg-entrance-primary">
        <div className="page-header mb-2">
          <h2>企业注册</h2>
        </div>
        <Steps
          direction="horizontal"
          labelPlacement="horizontal"
          size="smalll"
          className="pg-entrance-steps"
        >
          {/* 填写个人信息 */}
          <Step status="finish" icon={<UserOutlined />} />
          {/* 补充企业信息 */}
          <Step
            status={stepIndex < 1 ? "wait" : "finish"}
            icon={<HomeOutlined />}
          />
          {/* 验证手机号 */}
          <Step
            status={stepIndex < 2 ? "wait" : "finish"}
            icon={<LockOutlined />}
          />
        </Steps>
        <Form
          className="pg-entrance-form"
          name="companyregister"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          validateTrigger={["onBlur", "onChange"]}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
        >
          <ContactsModules showModules={stepIndex < 1} />
          <CompanyModules showModules={1 === stepIndex} />
          <PhoneModules showModules={2 === stepIndex} />

          <Form.Item wrapperCol={{ span: 24 }}>
            {stepIndex < 2 ? (
              <div
                className="ant-btn ant-btn-primary ant-btn-block"
                onClick={this.nextStep}
              >
                下一步
              </div>
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={this.state.loading}
              >
                立即注册
              </Button>
            )}
            <Button className="mt-1" href="/index" block>
              返回首页
            </Button>
            <div className="fs-14 pg-entrance-form_item-tips">
              <span></span>
              <p>
                已有账号？
                <Link to="/entrance/biz/login">去登录</Link>
              </p>
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
