import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Dropdown,
  message,
  Alert,
  Form,
  Input,
  Modal,
} from "antd";
import cookie from "react-cookies";
import { DownOutlined } from "@ant-design/icons";
import httpPromise from "@/api";
import "./index.less";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const FeedBack = (props) => {
  const { userinfo } = props;

  // 反馈框展示
  const [bugVisible, setBugVisible] = useState(false);
  // 反馈框提交表单
  const [bugForm] = Form.useForm();

  return (
    <div className="feedback">
      <div
        className="feedback-btn"
        onClick={() => {
          setBugVisible(true);
        }}
      >
        <div className="text">意见反馈</div>
      </div>
      {/* bug提交 */}
      <Modal
        open={bugVisible}
        width={700}
        title={false}
        className="wait ant-modal-confirm"
        keyboard={false}
        maskClosable={false}
        footer={[
          <Button
            type="primary"
            size="small"
            onClick={() => {
              bugForm.submit();
            }}
          >
            提交
          </Button>,
        ]}
        centered
        maskStyle={{ display: "block", zIndex: "10001" }}
        onCancel={() => {
          setBugVisible(false);
        }}
      >
        <div className="header">
          <div className="title">意见反馈</div>
          <div className="subtitle">
            请您在描述内容中留下联系方式，以便更好地帮助您解决问题
          </div>
        </div>
        <Form
          layout="vertical"
          form={bugForm}
          onFinish={async (vals) => {
            console.log(vals);
            // if (!userInfo.email) {
            //   message.error('请先进行登录')

            //   return false;
            // }
            const isMobile = /^(1[0-9][0-9])\d{8}$/.test(vals.mobile);

            if (!isMobile) {
              message.error("请输入正确的手机号");
              return;
            }

            httpPromise({
              apiDemo: "feedbackAdd",
              params: {
                ...vals,
              },
            })
              .then((res) => {
                if (res.code === 200) {
                  message.success("您的反馈已经提交");
                  bugForm.resetFields();
                  setBugVisible(false);
                  return false;
                } else {
                  message.error("网络请求失败，请稍后再试");
                }
                setBugVisible(false);
              })
              .catch((error) => {
                console.log(error);
              });
          }}
        >
          <Form.Item label="描述" name="content">
            <Input.TextArea rows={6} placeholder="请输入" />
          </Form.Item>
          <Form.Item label="手机号" name="mobile">
            <Input placeholder="请输入" maxLength={11} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default FeedBack;
