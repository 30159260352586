import React from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Select,
  Checkbox,
  PageHeader,
  Space,
  Steps,
  message,
  Spin,
} from "antd";

import "../index.less";
import httpPromise from "@/api";
import LinkAge from "../../../components/linkage";

let { Step } = Steps;
function getInfo(api, name, _t) {
  return new Promise((resolve, reject) => {
    httpPromise({ apiDemo: api, params: {} })
      .then((res) => {
        if (res.code === 200) {
          let params = { [name]: res.data };
          _t.setState(params);
          resolve();
        } else {
          message.error(res.message);
          reject();
        }
      })
      .catch((error) => {
        console.log(error);
        reject();
      });
  });
}
class BaseInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { showModules, occClassList, occMoneyList, formDefaultValue } =
      this.props;
    return (
      <div style={{ display: showModules ? "block" : "none" }}>
        <Form.Item
          name="occupation_id"
          label="职业分类"
          rules={[{ required: true, message: "请选择职业分类" }]}
        >
          <Select placeholder="请选择职业分类">
            {" "}
            {occClassList.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="title"
          label="职业名称"
          rules={[{ required: true, message: "请填写职位名称" }]}
        >
          <Input placeholder="请填写职位名称，平台将进行核实，请勿发布不实信息" />
        </Form.Item>
        <Form.Item required label="薪资待遇">
          <Space size="middle">
            <Form.Item
              noStyle
              name="min_salary_id"
              rules={[{ required: true, message: "请选择最低薪资" }]}
            >
              <Select placeholder="请选择最低薪资">
                {" "}
                {occMoneyList.map((item) => (
                  <Select.Option key={`min_${item.id}`} value={item.id}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <span>至</span>
            <Form.Item
              noStyle
              name="max_salary_id"
              rules={[{ required: true, message: "请选择最高薪资" }]}
            >
              <Select placeholder="请选择最高薪资">
                {" "}
                {occMoneyList.map((item) => (
                  <Select.Option key={`max_${item.id}`} value={item.id}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Space>
        </Form.Item>
        <LinkAge
          name="work_code"
          label="工作地区"
          className="pg-entrance-form_item"
          rules={[{ required: true, message: "请选择企业所在地区" }]}
          placeholder="请选择企业所在地区"
          defaultValue={formDefaultValue.work_code}
        />
        <Form.Item
          name="work_address"
          label="详细地址"
          rules={[{ required: true, message: "请填写详细地址" }]}
        >
          <Input placeholder="请填写详细地址" />
        </Form.Item>
        <Form.Item
          name="number"
          label="招聘人数"
          rules={[
            { required: true, message: "请填写招聘人数" },
            { pattern: /^[0-9]+$/, message: "请输入整数" },
          ]}
        >
          <Input placeholder="请填写招聘人数" />
        </Form.Item>
        <Form.Item
          name="brokerage"
          label="佣金"
          rules={[
            { required: true, message: "请填写佣金" },
            { pattern: /^[0-9]+(\.?[0-9]+)$/, message: "请输入整数" },
          ]}
        >
          <Input placeholder="请填写佣金" />
        </Form.Item>
      </div>
    );
  }
}

class ClassInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sexList: [
        {
          id: 0,
          name: "不限",
        },
        {
          id: 1,
          name: "男",
        },
        {
          id: 2,
          name: "女",
        },
      ],
    };
  }

  render() {
    let { sexList } = this.state;
    let { showModules, exList, eduList, welfareOptions } = this.props;
    return (
      <div style={{ display: showModules ? "block" : "none" }}>
        <Form.Item
          name="sex"
          label="性别"
          rules={[{ required: true, message: "请选择性别" }]}
        >
          <Select placeholder="请选择性别">
            {" "}
            {sexList.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item required label="年龄">
          <Space size="middle">
            <Form.Item
              noStyle
              name="min_age"
              rules={[
                { required: true, message: "请输入最小年龄" },
                { pattern: /^[1-9][0-9]$/, message: "请输入0-99的正整数" },
              ]}
            >
              <Input placeholder="请输入最小年龄" />
            </Form.Item>
            <span>到</span>
            <Form.Item
              noStyle
              name="max_age"
              rules={[
                { required: true, message: "请输入最大年龄" },
                { pattern: /^[1-9][0-9]$/, message: "请输入0-99的正整数" },
              ]}
            >
              <Input placeholder="请输入最大年龄" />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          name="education_id"
          label="学历要求"
          rules={[{ required: true, message: "请选择学历要求" }]}
        >
          <Select placeholder="请选择学历要求">
            {" "}
            {eduList.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="experience_id"
          label="工作经验"
          rules={[{ required: true, message: "请选择工作经验" }]}
        >
          <Select placeholder="请选择工作经验">
            {" "}
            {exList.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.title}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="benefits_ids" label="福利待遇">
          <Checkbox.Group
            options={welfareOptions.map((item) => {
              return { label: item.title, value: item.id };
            })}
          />
        </Form.Item>
        <Form.Item
          name="intro"
          label="职位描述"
          rules={[{ required: true, message: "请填写职位描述" }]}
        >
          <Input.TextArea
            autoSize={{ minRows: 6, maxRows: 14 }}
            placeholder="一、薪资架构&#13;&#10;二、工作时间&#13;&#10;三、作息时间四、任职要求&#13;&#10;五、福利待遇&#13;&#10;六、公司介绍"
          />
        </Form.Item>
      </div>
    );
  }
}

class ContactInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { showModules } = this.props;
    return (
      <div style={{ display: showModules ? "block" : "none" }}>
        <Form.Item
          name="interview_time"
          label="面试时间"
          rules={[{ required: true, message: "请填写面试时间" }]}
        >
          <Input placeholder="请填写面试时间，例如 周一至周五 08:00 - 17:00" />
        </Form.Item>
        <Form.Item
          name="interview_phone"
          label="面试电话"
          rules={[
            { required: true, message: "请填写面试电话" },
            { pattern: /^1[3-9][0-9]{9}$/, message: "请填写有效手机号" },
          ]}
        >
          <Input
            placeholder="请输入有效手机号，HR将据此联系您，便于预约面试时间"
            maxLength="11"
          />
        </Form.Item>
        <Form.Item
          name="interview_contact"
          label="面试联系人"
          rules={[{ required: true, message: "请填写联系人姓名" }]}
        >
          <Input placeholder="请填写联系人姓名" />
        </Form.Item>
        <Form.Item
          name="interview_address"
          label="面试地址"
          rules={[{ required: true, message: "请填写面试地址" }]}
        >
          <Input placeholder="请填写面试地址" />
        </Form.Item>
      </div>
    );
  }
}

export default class AddPosition extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isExit: false,
      defaultValues: {},
      stepIndex: 0,
      occClass: [],
      occExp: [],
      occEdu: [],
      occBenefits: [],
      occSalary: [],
    };
  }

  componentWillMount = () => {
    this.setState({ loading: true });
    Promise.all([
      getInfo("companyGetClass", "occClass", this),
      getInfo("companyGetExp", "occExp", this),
      getInfo("companyGetEdu", "occEdu", this),
      getInfo("companyGetBenefits", "occBenefits", this),
      getInfo("companyGetSalary", "occSalary", this),
    ]).then(() => {
      if (this.props.match.params.id) {
        this.setState({ itemId: this.props.match.params.id }, () => {
          this.getDetail();
        });
      } else {
        this.setState({ loading: false });
      }
    });
  };
  getDetail = () => {
    let id = this.state.itemId;
    httpPromise({ apiDemo: "companyGetJobDetail", params: { id } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          let result = res.data;
          this.setState({
            defaultValues: {
              work_code: [
                result.work_provinces_code,
                result.work_cities_code,
                result.work_areas_code,
              ],
              ...result,
            },
          });
          this.formRef.current.setFieldsValue(this.state.defaultValues);
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  };
  onFinish = (values) => {
    let [work_provinces_code, work_cities_code, work_areas_code] =
      values.work_code;
    let params = {
      work_provinces_code,
      work_cities_code,
      work_areas_code,
      ...values,
      benefits_ids:
        (typeof values.benefits_ids == "string" && values.benefits_ids) ||
        (values?.benefits_ids && values.benefits_ids.join(",")) ||
        "",
    };
    delete params.work_code;
    if (this.state.itemId) {
      params.id = this.state.itemId;
    }
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companyEditJob", params })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("提交成功！");
          this.setState({ isExit: true });
          this.props.history.push("/biz/recruit");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  prevStep = () => {
    let index = this.state.stepIndex;
    this.setState({ stepIndex: --index });
  };
  nextStep = () => {
    let validateArr = [];
    switch (this.state.stepIndex) {
      case 0:
        validateArr = [
          "occupation_id",
          "title",
          "min_salary",
          "max_salary",
          "work_code",
          "work_address",
          "number",
          "brokerage",
        ];
        break;
      case 1:
        validateArr = [
          "sex",
          "minAge",
          "maxAge",
          "education_id",
          "experience_id",
          "benefits_ids",
          "intro",
        ];
        break;
    }
    this.formRef.current
      .validateFields(validateArr)
      .then((values) => {
        let index = this.state.stepIndex;
        this.setState({ stepIndex: ++index });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  goback = () => {
    this.props.history.push("/biz/recruit");
  };
  render() {
    let {
      loading,
      stepIndex,
      occClass,
      occSalary,
      occExp,
      occEdu,
      occBenefits,
      defaultValues,
      isExit,
    } = this.state;
    return (
      <main className="pg-resruit-add">
        <Spin spinning={loading}>
          <PageHeader
            className="pg-biz-header"
            onBack={() => this.goback()}
            title="职位录入"
            subTitle="平台将对职位信息进行核实，请勿发布不实信息"
          >
            <Steps className="pg-resruit-add-steps">
              <Step
                status={!stepIndex ? "process" : "finish"}
                title="基本信息"
              />
              <Step
                status={
                  stepIndex > 1
                    ? "finish"
                    : 1 === stepIndex
                    ? "process"
                    : "wait"
                }
                title="职位要求"
              />
              <Step
                status={
                  stepIndex > 2
                    ? "finish"
                    : 2 === stepIndex
                    ? "process"
                    : "wait"
                }
                title="联系方式"
              />
            </Steps>
            <Form
              className="pg-resruit-add-form"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 14 }}
              validateTrigger={["onBlur", "onChange"]}
              onFinish={this.onFinish}
              onFinishFailed={this.onFinishFailed}
              ref={this.formRef}
            >
              <BaseInfo
                showModules={!stepIndex}
                occClassList={occClass}
                occMoneyList={occSalary}
                formDefaultValue={defaultValues}
                isExit={isExit}
              />
              <ClassInfo
                showModules={1 === stepIndex}
                exList={occExp}
                eduList={occEdu}
                welfareOptions={occBenefits}
              />
              <ContactInfo showModules={2 === stepIndex} />
              <Form.Item wrapperCol={{ offset: 6, span: 14 }}>
                <div className="d-flex">
                  {stepIndex >= 1 ? (
                    <div
                      className="ant-btn ant-btn-block mr-2"
                      onClick={this.prevStep}
                    >
                      上一步
                    </div>
                  ) : (
                    ""
                  )}
                  {stepIndex < 2 ? (
                    <div
                      className="ant-btn ant-btn-primary ant-btn-block"
                      onClick={this.nextStep}
                    >
                      下一步
                    </div>
                  ) : (
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      loading={this.state.loading}
                    >
                      提交
                    </Button>
                  )}
                </div>
              </Form.Item>
            </Form>
          </PageHeader>
        </Spin>
      </main>
    );
  }
}
