import React from "react";
import { Button } from "antd";
import httpPromise from "@/api";

export default class CountDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalTime: 1000,
      residue: 0,
      timer: null,
    };
  }
  componentWillUnmount = () => {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
  };

  getSmscode = () => {
    let { apiDemo, mobileFn } = this.props;
    let mobile = mobileFn();
    console.log(mobile);
    if (this.state.residue > 0 || !mobile) {
      return;
    }
    this.setState({ loading: true });
    httpPromise({ apiDemo, params: { mobile } })
      .then((res) => {
        this.setState({ loading: false });
        if (200 !== res.code) {
          return;
        }
        this.setState({ residue: this.props.total }, () => {
          this.countdown();
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  countdown = () => {
    if (this.state.residue <= 0) {
      return;
    }
    let timer = setTimeout(() => {
      this.setState({ residue: --this.state.residue });
      this.countdown();
    }, this.state.intervalTime);
    this.setState({ timer });
  };

  render() {
    let { residue } = this.state;
    return (
      <Button
        block
        htmlType="button"
        loading={this.state.loading}
        style={{ width: "35%", marginLeft: "5%" }}
        disabled={!!residue}
        onClick={this.getSmscode}
      >
        {residue ? `重新获取(${residue}s)` : "获取验证码"}
      </Button>
    );
  }
}
