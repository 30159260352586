import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Layout,
  Menu,
  Button,
  Dropdown,
  message,
  Alert,
  Form,
  Input,
  Modal,
} from "antd";
import cookie from "react-cookies";
import { DownOutlined } from "@ant-design/icons";
import httpPromise from "@/api";
import "./index.less";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const FeedBack = (props) => {
  const { userinfo } = props;

  // 反馈框展示
  const [bugVisible, setBugVisible] = useState(false);
  // 反馈框提交表单
  const [bugForm] = Form.useForm();

  return (
    <div className="alerts">
      <Alert
        message={
          <div style={{ cursor: "pointer" }}>
            速猎直推提醒您：近期有机构冒充或以速猎直推子公司等名义对外宣传并引导客户
          </div>
        }
        type="warning"
        closable
        style={{
          backgroundColor: "#D8D8D8",
          textAlign: "center",
          border: "none",
        }}
        onClick={() => {
          setBugVisible(true);
        }}
      />

      {/* bug提交 */}
      <Modal
        open={bugVisible}
        width={700}
        title={false}
        className="ant-modal-confirm"
        keyboard={false}
        maskClosable={false}
        footer={[]}
        centered
        maskStyle={{ display: "block", zIndex: "10001" }}
        onCancel={() => {
          setBugVisible(false);
        }}
      >
        <div className="header mt-1">
          <div
            className="title"
            style={{
              fontSize: "18px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            严正声明
          </div>
        </div>
        <div className="mt-1">
          <p className="mb-1">尊敬的用户：</p>
          <p className="mb-1">
            我们，速猎直推，是一家专注于提供高质量招聘服务的公司/网站，致力于为企业和求职者提供最优质、最有效的招聘体验。近期，我们发现有用户冒充我们开展业务，并谎称子公司和产品。这种行为严重侵犯了我们的权益，也给广大用户带来了极大的困扰和损失。
          </p>
          <p className="mb-1">
            我们郑重声明：速猎直推是唯一合法的运营者，我们从未设立任何子公司或授权任何第三方代理机构开展业务。所有声称是速猎直推子公司或代理机构的行为都是虚假的，与本公司无关。
          </p>
          <p className="mb-1">
            为了维护广大用户的合法权益，我们强烈提醒大家注意以下几点：
          </p>
          <p className="mb-1">
            不要轻信任何声称是速猎直推子公司或代理机构的信息，务必通过官方渠道核实身份。
          </p>
          <p className="mb-1">
            在选择招聘服务时，请选择正规、有资质的机构，避免因贪图便宜或方便而选择非法机构。
          </p>
          <p className="mb-1">
            如发现任何冒充速猎直推开展业务的行为，请立即与我们联系，我们将全力配合相关部门进行调查和处理。
          </p>
          <p className="mb-1">
            我们将继续秉持诚信、专业的服务理念，为广大用户提供最优质、最可靠的招聘服务。同时，我们也呼吁广大用户共同维护良好的市场秩序，共同打击任何形式的侵权行为。
          </p>
          <p>谢谢大家的理解和支持！</p>
        </div>
      </Modal>
    </div>
  );
};

export default FeedBack;
