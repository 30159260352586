import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import { Layout, Menu, Button, Dropdown, message, Alert } from "antd";
import cookie from "react-cookies";
import { DownOutlined } from "@ant-design/icons";
import httpPromise from "@/api";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const Headers = (props) => {
  const { userinfo } = props;

  const selectDownMenu = ({ key }) => {
    switch (key) {
      case "logout":
        // this.setState({ loading: true });
        httpPromise({ apiDemo: "companyLoginOut" })
          .then((res) => {
            // this.setState({ loading: false });
            if (res.code === 200) {
              message.success("退出成功！");
              cookie.remove("access_token", { path: "/" });
              localStorage.removeItem("userInfo");
              window.location.reload();
            } else {
              message.error(res.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        break;

      default:
        break;
    }
  };

  return (
    <div style={{ position: "sticky", top: 0, zIndex: 99, width: "100%" }}>
      <Header className="top-header" theme="light">
        <div className="container">
          <Link className="logo" to="/index">
            <img src={require("@/atess/image/logo-lan.png")} />
          </Link>
          <Menu theme="light" mode="horizontal" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1">
              <a href="/index">首页</a>
            </Menu.Item>
            <Menu.Item key="2">
              <a href="/selected">精选人才</a>
            </Menu.Item>
            <Menu.Item key="3">
              <a href="/hot">热招职位</a>
            </Menu.Item>
            <Menu.Item key="4">
              <a href="/famous">名企专区</a>
            </Menu.Item>
            <Menu.Item key="5">
              <a href="/service">服务介绍</a>
            </Menu.Item>
          </Menu>

          {((!userinfo || !userinfo.name) && (
            <div className="btn-box right">
              <Button type="primary" href="/entrance/biz/login">
                登录
              </Button>
              <Button type="primary" href="/entrance/biz/register">
                注册
              </Button>
              {/* <Button type="default" ghost href="https://hr.slzhitui.com/login">
                前往HR版
              </Button> */}
            </div>
          )) || (
            <div className="btn-box right">
              <Dropdown
                className="right"
                overlay={
                  <Menu selectable={false} onClick={selectDownMenu}>
                    <Menu.Item key="1">
                      <Link to="/biz/search">搜索简历</Link>
                    </Menu.Item>
                    <Menu.Item key="2">
                      <Link to="/biz/account">账户中心</Link>
                    </Menu.Item>
                    <Menu.Item key="3">
                      <Link to="/biz/account/change">修改密码</Link>
                    </Menu.Item>
                    <Menu.Item key="logout">
                      <span>退出登录</span>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  type="link"
                  style={{ marginLeft: "auto", alignSelf: "center" }}
                  href="/biz/home"
                >
                  {(!!userinfo && userinfo.name) || "暂无用户信息"}{" "}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          )}

          {/* <div className="head-telphone">全国客服电话：400-8383-646</div> */}
        </div>
      </Header>
    </div>
  );
};

export default Headers;
