import React from "react";
import httpPromise from '@/api';
import { message, Spin } from "antd";
export default class RichText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '',
            loading: false
        }
    }
    componentWillMount = () => {
        this.getDetail();
    }

    getDetail = () => {
        this.setState({ loading: true });
        httpPromise({ apiDemo: 'companyDeal' }).then((res) => {
            this.setState({ loading: false });
            if (200 === res.code) {
                this.setState({ content: res.data.reg_agreement })
            } else {
                message.error(res.message);
            }
        }).catch((error) => {
            this.setState({ loading: false });
            console.log(error);
        })
    }
    render() {
        return (
            <Spin spinning={this.state.loading}>
                <div dangerouslySetInnerHTML={{
                    __html: this.state.content
                }} style={{ maxWidth: '800px', margin: '40px auto' }}>
                </div>
            </Spin>
        )
    }
}