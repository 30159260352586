import React from "react";
import { Link } from "react-router-dom";

import { message, Form, Input, Checkbox, Button } from "antd";
import CountDown from "@/components/countdown";

import httpPromise from "@/api";

export default class HiringRegister extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillUnmount = () => {
    window.location.href = "https://hr.slzhitui.com/register";
  };

  getMobileAndVal = () => {
    let { current } = this.formRef;
    let value = current.getFieldValue("mobile");
    current.validateFields(["mobile"]);
    return /^1[3-9][0-9]{9}$/.test(value) && value;
  };
  onFinish = (values) => {
    let params = {
      ...values,
    };
    delete params.argee;
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "hiringregister",
      params,
    })
      .then((res) => {
        this.setState({ loading: false });
        if (200 !== res.code) {
          message.error(res.message || "注册失败");
          return;
        }
        message.success("注册成功");
        this.props.history.push("/entrance/hr/login");
      })
      .catch((error) => {
        this.setState({ loading: false });
        message.error("注册失败");
        console.log(error);
      });
  };

  render() {
    return (
      <div className="pg-entrance-primary">
        <Form
          className="pg-entrance-form"
          name="companyregister"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={this.onFinish}
          onFinishFailed={this.onFinishFailed}
          ref={this.formRef}
        >
          <Form.Item
            name="name"
            label="姓名"
            className="pg-entrance-form_item"
            rules={[{ required: true, message: "请输入姓名" }]}
          >
            <Input placeholder="请输入姓名" />
          </Form.Item>

          <Form.Item
            name="mobile"
            label="手机号"
            className="pg-entrance-form_item"
            rules={[
              { required: true, message: "请输入手机号" },
              {
                pattern: /^1[3-9][0-9]{9}$/,
                message: "手机号格式不正确，请重新输入",
              },
            ]}
          >
            <Input maxLength="11" placeholder="请输入手机号" />
          </Form.Item>

          <Form.Item label="验证码" required className="pg-entrance-form_item">
            <Form.Item
              name="smscode"
              rules={[{ required: true, message: "请输入验证码" }]}
              noStyle
            >
              <Input.Password
                style={{ width: "60%" }}
                placeholder="请输入验证码"
              />
            </Form.Item>
            <CountDown
              total={5}
              apiDemo="hiringGetCode"
              mobileFn={this.getMobileAndVal}
            />
          </Form.Item>

          <Form.Item
            name="password"
            label="密码"
            className="pg-entrance-form_item"
            rules={[
              { required: true, message: "请输入密码" },
              {
                pattern: /^[0-9a-zA-Z]{8,}$/,
                message: "密码需为8位以上数字及字母的组合",
              },
            ]}
          >
            <Input.Password placeholder="请输入密码" />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            label="确认密码"
            className="pg-entrance-form_item"
            rules={[
              { required: true, message: "请再次输入密码" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("两次密码输入不一致"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="请再次输入密码" />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
            <Form.Item
              noStyle
              name="argee"
              valuePropName="checked"
              className="pg-entrance-form_item"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error("请同意相关协议")),
                },
              ]}
            >
              <Checkbox className="fs-12">同意</Checkbox>
            </Form.Item>
            <a className="fs-12" href="/deal" target="_blank">
              《用户服务协议》
            </a>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
            <Button
              type="primary"
              htmlType="submit"
              block
              loading={this.state.loading}
            >
              立即注册
            </Button>
            <Button className="mt-1" href="/index" block>
              返回首页
            </Button>
            <div className="fs-14 pg-entrance-form_item-tips">
              <span></span>
              <p>
                已有账号？
                <Link to="/entrance/hr/login">去登录</Link>
              </p>
            </div>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
