import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import {
  Layout,
  Menu,
  Dropdown,
  message,
  Typography,
  Button,
  Modal,
  Form,
  InputNumber,
  Row,
  Col,
  Tag,
} from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";

import "./index.less";
import cookie from "react-cookies";
import httpPromise from "@/api";
import { QRCodeCanvas } from "qrcode.react";
import moment from "moment";

import BizIndex from "@/pages/business/index";
import HrIndex from "@/pages/hiring/index";

const { Text } = Typography;

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export default class Admin extends React.Component {
  timeRef = React.createRef();
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      userInfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      rechargeQrcode: "",
      rechargeVisible: false,
      rechargeParams: {},
      money: localStorage.getItem("paymain"),
      visibleStart: false,
      time: "",
    };
  }

  componentWillMount = () => {
    document.title = "速猎直推";
  };
  componentDidMount = () => {
    if (!cookie.load("access_token")) {
      this.props.history.push("/entrance");
    }
    this.getUserInfo();

    if (!this.state.money) {
      message.error("未获取到支付信息");
      window.location.href = "/biz/home";
    }
    this.setState({
      time: moment().format("YYYY-MM-DD HH:mm:ss"),
    });
    this.getQrcode(this.state.money);
  };

  getUserInfo = () => {
    this.getuserinfo();
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  selectDownMenu = ({ key }) => {
    switch (key) {
      case "logout":
        this.setState({ loading: true });
        httpPromise({ apiDemo: "companyLoginOut" })
          .then((res) => {
            this.setState({ loading: false });
            if (res.code === 200) {
              message.success("退出成功！");
              cookie.remove("access_token", { path: "/" });
              localStorage.removeItem("userInfo");
              this.props.history.push("/");
            } else {
              message.error(res.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        break;

      default:
        break;
    }
  };

  getuserinfo = () => {
    httpPromise({ apiDemo: "companyGetUserInfo" })
      .then((res) => {
        if (res?.code === 200) {
          this.setState({ userInfo: res.data });
          cookie.save("userinfo", res.data);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getQrcode = async (money) => {
    clearInterval(this.timeRef.current);
    httpPromise({
      apiDemo: "companyRecharge",
      params: {
        id: this.state.userInfo.id,
        type: 2,
        money: money || this.state.money,
      },
    })
      .then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.setState({
            rechargeQrcode: res.data.code_url,
            rechargeParams: {
              order_id: res.data.order_id,
            },
          });

          this.timeRef.current = setInterval(async () => {
            httpPromise({
              apiDemo: "companyPayStatus",
              params: {
                id: this.state.rechargeParams.order_id,
              },
            })
              .then((res) => {
                if (res.code === 200) {
                  if (res.data.status == 1) {
                    this.setState({
                      rechargeParams: {},
                      visible: false,
                      rechargeVisible: false,
                    });
                    message.success("已完成充值");
                    clearInterval(this.timeRef.current);
                    window.location.href = "/biz/home";
                  }
                }
              })
              .catch((error) => {
                console.log(error);
                clearInterval(this.timeRef.current);
              });
          }, 3000);
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { userInfo, rechargeQrcode, money, time } = this.state;
    return (
      <Layout className="pg-home-layout">
        <Header className="pg-home-layout_header">
          <Link className="logo" to="/index">
            <img src={require("@/atess/image/logo-lan.png")} />
          </Link>
          <Menu mode="horizontal" selectable={false}>
            <Menu.Item key="1">
              <Link to="/index">首页</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/about">关于我们</Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/service">服务优势</Link>
            </Menu.Item>
          </Menu>
          <Dropdown
            overlay={
              <Menu selectable={false} onClick={this.selectDownMenu}>
                <Menu.Item key="1">
                  <Link to="/biz/search">搜索简历</Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/biz/account">账户中心</Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/biz/account/change">修改密码</Link>
                </Menu.Item>
                <Menu.Item key="logout">
                  <span>退出登录</span>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ marginLeft: "auto", alignSelf: "center" }}
              href="/biz/home"
            >
              {" "}
              {(userInfo && userInfo.name) || "暂无用户信息"} <DownOutlined />
            </Button>
          </Dropdown>
        </Header>
        <div className="paymain">
          <div className="header">
            <div className="logo">
              <img src={require("@/atess/image/logo-lan.png")} />
            </div>
            <div className="subtitle">支付中心</div>
          </div>
          <Row className="info mb-2">
            <Col span={12}>
              <div className="mb-1">
                <span>收款方：</span>
                <span>上海华士腾信息科技有限公司</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="mb-1 text-right">
                <span>订单编号：</span>
                <span></span>
              </div>
            </Col>
            <Col span={12}>
              <div className="mb-1">
                <span>订单描述：</span>
                <span>招聘套餐账户充值</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="mb-1 text-right">
                <span>下单时间：</span>
                <span>{time}</span>
              </div>
            </Col>
            <Col span={12}>
              <div className="mb-1">
                <span>订单金额：</span>
                <span>
                  <span style={{ color: "red" }}>{money}</span> 元
                </span>
              </div>
            </Col>
          </Row>

          <div className="qrcode">
            <div className="choose-list">
              <div className="choose-list-item on">
                <div className="icon">
                  <img src={require("../../atess/image/wxpay.png")} />
                </div>
                <div className="title">微信支付</div>
              </div>
            </div>
            <div className="title mb-3">
              请使用 <span>微信</span> 扫码完成支付
            </div>
            <div className="qrcode-cont">
              {(rechargeQrcode && (
                <div
                  className=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <QRCodeCanvas width={300} value={rechargeQrcode} />
                </div>
              )) || (
                <LoadingOutlined
                  style={{ fontSize: "44px", color: "rgb(64, 158, 255)" }}
                />
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
