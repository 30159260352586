import React from 'react';
import { Switch, Route } from 'react-router-dom';

import api from '@/api/index';
import CompanyRegister from './companyregister';
import HiringRegister from './hiringregister';

export default class Register extends React.Component {
    formRef = React.createRef();
    state = {
        contdown: 0,
        timeout: false,
        loading: false, loading2: false,
        isagree: false
    }
    componentWillMount = () => {
        document.title = "注册 - 速猎直推"
    }
    render() {
        return (
            <Switch>
                <Route path="/entrance/biz/register" component={CompanyRegister} />
                <Route path="/entrance/hr/register" component={HiringRegister} />
            </Switch>
        );
    }
}
