import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Checkbox, Button, Select, message } from "antd";
import {
  LockOutlined,
  MobileOutlined,
  DoubleLeftOutlined,
} from "@ant-design/icons";

import cookie from "react-cookies";
import httpPromise from "@/api";

const { Option } = Select;

export default class CompanyLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      agreeList: [
        {
          title: "用户服务协议",
          link: "/deal",
        },
      ],
    };
  }
  componentWillMount = () => {
    // if (cookie.load("access_token")) {
    //   window.location.href = "/biz/home";
    // }
  };

  onFinish = (values) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companylogin",
      params: { mobile: values.mobile, password: values.password },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("登录成功");
          cookie.save("access_token", res.data, { path: "/", maxAge: 7200 });
          this.getUserInfo();
          setTimeout(() => {
            window.location.href = "/biz/home";
          }, 500);
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  };

  getUserInfo = () => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companyGetUserInfo" })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          setTimeout(() => {
            window.location.href = "/biz/home";
          }, 500);
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    let { agreeList, loading } = this.state;
    return (
      <Form
        className="pg-entrance-form"
        name="login"
        initialValues={{ remember: true }}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
      >
        <div className="page-header mb-2">
          <h2>企业登录</h2>
        </div>
        <Form.Item
          name="mobile"
          className="pg-entrance-form_item"
          rules={[
            { required: true, message: "请输入手机号" },
            {
              pattern: /^1[3-9][0-9]{9}$/,
              message: "手机号格式不正确，请重新输入",
            },
          ]}
        >
          <Input
            prefix={<MobileOutlined />}
            maxLength="11"
            placeholder="手机号"
          />
        </Form.Item>

        <Form.Item
          name="password"
          className="pg-entrance-form_item"
          rules={[
            { required: true, message: "请输入密码" },
            {
              pattern: /^[0-9a-zA-Z]{8,}$/,
              message: "密码需为8位以上数字及字母的组合",
            },
          ]}
        >
          <Input.Password prefix={<LockOutlined />} placeholder="密码" />
        </Form.Item>

        <Form.Item>
          <Form.Item
            noStyle
            name="argee"
            valuePropName="checked"
            className="pg-entrance-form_item"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error("请同意相关协议")),
              },
            ]}
          >
            <Checkbox className="fs-12">同意</Checkbox>
          </Form.Item>
          {agreeList.map((item, index) => (
            <a
              className="fs-12"
              href={item.link}
              target="_blank"
              key={`entrance_${index}`}
            >
              《{item.title}》
            </a>
          ))}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            立即登录
          </Button>
          <Button className="mt-1" href="/index" block>
            返回首页
          </Button>
          <div className="fs-14 pg-entrance-form_item-tips">
            <Link to="/entrance/biz/forget">忘记密码？</Link>
            <p>
              没有账号？
              <Link to="/entrance/biz/register">去注册</Link>
            </p>
          </div>
        </Form.Item>
      </Form>
    );
  }
}
