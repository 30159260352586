import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
export default {
  // 获取用户协议 /enterprise/system/get
  async companyDeal(params) {
    return await instance
      .get(`${base}/enterprise/system/get`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取常用问题 /enterprise/system/issue
  async companyIssue(params) {
    return await instance
      .get(`${base}/enterprise/system/issue`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  //企业登录
  async companylogin(params) {
    return await instance
      .post(`${base}/enterprise/login/login`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  //企业注册
  async companyregister(params) {
    return await instance
      .post(`${base}/enterprise/login/register`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  //企业手机号获取验证码
  async companyGetCode(params) {
    return await instance
      .post(`${base}/enterprise/login/getRegCode`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 企业忘记密码
  async companyForget(params) {
    return await instance
      .post(`${base}/enterprise/login/forget`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 企业忘记密码获取验证码
  async companyForgetCode(params) {
    return await instance
      .post(`${base}/enterprise/login/getForgetCode`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 企业修改密码
  async companyChange(params) {
    return await instance
      .post(`${base}/enterprise/user/changePass`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 企业修改密码获取验证码
  async companyChangeCode(params) {
    return await instance
      .post(`${base}/enterprise/user/getchangepasscode`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 企业修改用户信息
  async companyChangeUser(params) {
    return await instance
      .post(`${base}/enterprise/user/edit`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 企业修改用户信息验证码
  async companyChangeUserCode(params) {
    return await instance
      .post(`${base}/enterprise/user/getEditInfoCode`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取用户信息
  async companyGetUserInfo(params) {
    return await instance
      .get(`${base}/enterprise/user/info`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        // message.error("服务器出错");
      });
  },
  // 获取余额信息
  async companyGetBalance(params) {
    return await instance
      .get(`${base}/enterprise/user/balance`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 根据职位关键词搜索相关人才简历
  async companyGetSearch(params) {
    return await instance
      .post(`${base}/enterprise/search/index`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 根据职位关键词搜索相关职位
  async companyGetSearchJob(params) {
    return await instance
      .post(`${base}/enterprise/search/job`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 根据职位关键词搜索相关职位
  async companyGetEnterpriseSearchJob(params) {
    return await instance
      .post(`${base}/enterprise/search/enterprise_job`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  
  //企业注册
  async companyregister(params) {
    return await instance
      .post(`${base}/enterprise/login/register`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  //新增意见反馈
  async feedbackAdd(params) {
    return await instance
      .post(`${base}/enterprise/feedback/add`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取企业发布职位
  async companyGetJbList(params) {
    return await instance
      .post(`${base}/enterprise/JobList/list`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取职位详情
  async companyGetJobDetail(params) {
    return await instance
      .get(`${base}/enterprise/JobList/get`, { params })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 编辑职位
  async companyEditJob(params) {
    return await instance
      .post(`${base}/enterprise/JobList/edit`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 置顶职位
  async companySetTop(params) {
    return await instance
      .post(`${base}/enterprise/JobList/setTop`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 刷新职位
  async companySetRefresh(params) {
    return await instance
      .post(`${base}/enterprise/JobList/setRefresh`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 删除职位
  async companyDelJob(params) {
    return await instance
      .delete(`${base}/enterprise/JobList/del`, { data: params })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取企业推荐简历
  async companyGetResumeList(params) {
    return await instance
      .post(`${base}/enterprise/JobList/resumeList`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 简历状态枚举
  async companyJobResumeStatus(params) {
    return await instance
      .post(`${base}/base/base/list?table=job_resume_status`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取类型枚举
  async companyGetTable(params) {
    return await instance
      .post(`${base}/base/base/list?table=${params.table}`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 修改简历状态
  async companyChangeJobResumeStatus(params) {
    return await instance
      .post(`${base}/enterprise/JobList/jobResumeStatus`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 新增简历
  async companyCreateResume(params) {
    return await instance
      .post(`${base}/enterprise/Resume/add`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 修改简历
  async companyUpdateResume(params) {
    return await instance
      .post(`${base}/enterprise/Resume/update`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 删除简历
  async companyDelResume(params) {
    return await instance
      .delete(`${base}/enterprise/Resume/delete?id=${params.id}`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 上传简历
  async companyImportResume(params) {
    return await instance
      .post(`${base}/enterprise/Resume/import`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 分享简历列表
  async companyShareResumeList(params) {
    return await instance
      .post(`${base}/enterprise/Resume/list`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 搜索简历列表
  async companySearchResumeList(params) {
    return await instance
      .post(`${base}/enterprise/Resume/search`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 下载简历
  async companyDownloadResume(params) {
    return await instance
      .post(`${base}/enterprise/Resume/download`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 已下载简历列表
  async companyDownloadResumeList(params) {
    return await instance
      .post(`${base}/enterprise/Resume/downloadList`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 已下载简历详情
  async companyDownloadResumeDetail(params) {
    return await instance
      .post(`${base}/enterprise/Resume/downloadDetail`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 企业充值扫码
  async companyRecharge(params) {
    return await instance
      .post(`${base}/base/pay/recharge`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 企业充值订单查询
  async companyPayStatus(params) {
    return await instance
      .post(`${base}/base/pay/status`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 企业收支明细
  async companyUserLog(params) {
    return await instance
      .post(`${base}/enterprise/user/log`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取职业分类 /enterprise/JobList/getOccupation
  async companyGetClass(params) {
    return await instance
      .get(`${base}/enterprise/JobList/getOccupation`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取工作经验 /enterprise/JobList/getExperience
  async companyGetExp(params) {
    return await instance
      .get(`${base}/enterprise/JobList/getExperience`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取学历需求 /enterprise/JobList/getEducation
  async companyGetEdu(params) {
    return await instance
      .get(`${base}/enterprise/JobList/getEducation`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取福利待遇 /enterprise/JobList/getBenefits
  async companyGetBenefits(params) {
    return await instance
      .get(`${base}/enterprise/JobList/getBenefits`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取薪资待遇 /enterprise/JobList/getSalary
  async companyGetSalary(params) {
    return await instance
      .get(`${base}/enterprise/JobList/getSalary`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 退出登录 /enterprise/login/logout
  async companyLoginOut(params) {
    return await instance
      .get(`${base}/enterprise/login/logout`, params)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
};
