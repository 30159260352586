import React, { useState } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import {
  Spin,
  Layout,
  Row,
  Col,
  Menu,
  Dropdown,
  Avatar,
  message,
  Input,
  Button,
  Carousel,
  Icon,
  Card,
  Affix,
  Alert,
  AutoComplete,
} from "antd";
import { DownOutlined } from "@ant-design/icons";

import httpPromise from "@/api";

import cookie from "react-cookies";

import "./service.less";
import "animate.css";

import Headers from "./Headers";
import HomeFooter from "../../components/homeFooter";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userinfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      mode: "inline",
      theme: "light",
      container: null,
      current: 1,
      codeType: true,
      scrollNum: 600,
      loginCurrent: 1,
      cIndex: 0,
      searchVal: "",
    };
    this.ref = null;
    this.carouselRef = React.createRef();
  }
  componentDidMount() {
    this.getUserInfo();
  }
  componentWillUnmount() {}

  getUserInfo = async () => {
    let t = this;
    httpPromise({ apiDemo: "companyGetUserInfo" })
      .then(function (res) {
        if (res.code === 200) {
          t.setState({ loading: false, userinfo: res.data });
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          cookie.save("userinfo", res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeCIndex(index) {
    this.setState({
      cIndex: index,
    });
    console.log(this.carouselRef);
    this.carouselRef.current.goTo(index);
  }

  render() {
    const { userinfo, cIndex, searchVal } = this.state;

    return (
      <Spin spinning={this.state.loading}>
        <Headers userinfo={userinfo} />
        <Layout className="service">
          <Layout style={{ minHeight: "100vh" }}>
            <Content style={{ position: "relative" }}>
              <div className="top-banner">
                <div className="container">
                  {/* {((!userinfo || !userinfo.name) && (
                    <div className="login-wrap">
                      <CompanyLogin loginCurrent={loginCurrent} />
                    </div>
                  )) || <div></div>} */}
                  <div className="title">
                    <span className="mr-3">优质人才</span>
                    <span>就上速猎直推</span>
                  </div>
                  <div className="subtitle">悬赏招聘 效果招聘 开创者！</div>
                </div>
              </div>
              <div className="intro-info">
                <section class="container clearfix">
                  <div className="title">速猎直推 悬赏招聘</div>
                  <div className="subtitle">
                    速猎直推首创悬赏效果招聘，让每一次的招聘和推荐都立竿见影、直达效果
                  </div>
                  <div className="list">
                    <div className="item">专业甄选</div>
                    <div className="item">海量数据</div>
                    <div className="item">智能算法</div>
                    <div className="item">精准推荐</div>
                    <div className="item">满意付费</div>
                    <div className="item">满意付费</div>
                  </div>
                </section>
              </div>
              <div className="fast block">
                <div className="container clearfix">
                  <div className="title">快捷的招聘方式</div>
                  <div className="fast-list">
                    <div className="fast-list-item">
                      <div className="cover">
                        <img
                          src={require("../../atess/image/indexnew/service/step1.png")}
                        />
                      </div>
                      <div className="text">注册企业账号</div>
                    </div>
                    <div className="fast-list-item">
                      <div className="cover">
                        <img
                          src={require("../../atess/image/indexnew/service/step2.png")}
                        />
                      </div>
                      <div className="text">开通账号服务</div>
                    </div>
                    <div className="fast-list-item">
                      <div className="cover">
                        <img
                          src={require("../../atess/image/indexnew/service/step3.png")}
                        />
                      </div>
                      <div className="text">发布所需职位</div>
                    </div>
                    <div className="fast-list-item">
                      <div className="cover">
                        <img
                          src={require("../../atess/image/indexnew/service/step4.png")}
                        />
                      </div>
                      <div className="text">面试候选人</div>
                    </div>
                    <div className="fast-list-item">
                      <div className="cover">
                        <img
                          src={require("../../atess/image/indexnew/service/step5.png")}
                        />
                      </div>
                      <div className="text">成功入职</div>
                    </div>
                  </div>
                </div>
              </div>

              <HomeFooter />
            </Content>
          </Layout>
        </Layout>
      </Spin>
    );
  }
}

export default Service;
