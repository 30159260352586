import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import {
  Layout,
  Menu,
  Dropdown,
  message,
  Typography,
  Button,
  Modal,
  Form,
  InputNumber,
  Row,
  Col,
  Tag,
  Alert,
} from "antd";
import { DownOutlined } from "@ant-design/icons";

import "./index.less";
import cookie from "react-cookies";
import httpPromise from "@/api";
import { QRCodeCanvas } from "qrcode.react";

import BizIndex from "@/pages/business/index";
import HrIndex from "@/pages/hiring/index";
import PayMain from "@/pages/paymain/index";

const { Text } = Typography;

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export default class Admin extends React.Component {
  timeRef = React.createRef();
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      userInfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      rechargeQrcode: "",
      rechargeVisible: false,
      rechargeParams: {},
      money: 100,
      visibleStart: false,
      qrcodeUrl: "",
    };
  }

  componentWillMount = () => {
    document.title = "速猎直推";
    this.getUserInfo();
  };
  componentDidMount = () => {
    if (!cookie.load("access_token")) {
      this.props.history.push("/entrance");
    }
    this.getUserInfo();
  };

  getUserInfo = () => {
    this.getuserinfo();
  };

  createBindQrcode = (uinfo) => {
    console.log(uinfo);
    let qrcodelink = `https://admin.slzhitui.com/enterprise/index/index?id=${uinfo.id}`;
    console.log(qrcodelink);
    this.setState({
      qrcodeUrl: qrcodelink,
    });
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  selectDownMenu = ({ key }) => {
    switch (key) {
      case "logout":
        this.setState({ loading: true });
        httpPromise({ apiDemo: "companyLoginOut" })
          .then((res) => {
            this.setState({ loading: false });
            if (res.code === 200) {
              message.success("退出成功！");
              cookie.remove("access_token", { path: "/" });
              localStorage.removeItem("userInfo");
              this.props.history.push("/");
            } else {
              message.error(res.message);
            }
          })
          .catch((error) => {
            console.log(error);
          });
        break;

      default:
        break;
    }
  };

  getuserinfo = () => {
    httpPromise({ apiDemo: "companyGetUserInfo" })
      .then((res) => {
        if (res?.code === 200) {
          this.setState({ userInfo: res.data });
          cookie.save("userinfo", res.data);
          localStorage.setItem("userInfo", JSON.stringify(res.data));

          this.createBindQrcode(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  rechargeCall = async (money) => {
    this.setState({ rechargeVisible: true });
  };
  getQrcode = async (money) => {
    clearInterval(this.timeRef.current);

    localStorage.setItem("paymain", money);
    window.location.href = "/paymain";
  };

  rechargeOk = async () => {
    this.getbalance();
    this.setState({ visible: false, rechargeVisible: false });
    clearInterval(this.timeRef.current);
  };
  rechargeCancel = () => {
    this.setState({ rechargeVisible: false, rechargeQrcode: "" });
    clearInterval(this.timeRef.current);
  };
  render() {
    let {
      userInfo,
      rechargeQrcode,
      rechargeVisible,
      money,
      visibleStart,
      qrcodeUrl,
    } = this.state;
    return (
      <Layout className="pg-home-layout">
        <Header className="pg-home-layout_header">
          <Link className="logo" to="/index">
            <img src={require("@/atess/image/logo-lan.png")} />
          </Link>
          <Menu mode="horizontal" selectable={false}>
            <Menu.Item key="1">
              <a href="/index">首页</a>
            </Menu.Item>
            <Menu.Item key="2">
              <a href="/selected">精选人才</a>
            </Menu.Item>
            <Menu.Item key="3">
              <a href="/hot">热招职位</a>
            </Menu.Item>
            <Menu.Item key="4">
              <a href="/famous">名企专区</a>
            </Menu.Item>
            <Menu.Item key="5">
              <a href="/service">服务介绍</a>
            </Menu.Item>
          </Menu>

          <div className="qrcode" style={{ marginLeft: "auto", zIndex: 1 }}>
            <span className="title">绑定微信</span>
            <div className="img">
              <QRCodeCanvas width={200} value={qrcodeUrl} />
            </div>
          </div>
          <Dropdown
            overlay={
              <Menu selectable={false} onClick={this.selectDownMenu}>
                <Menu.Item key="1">
                  <Link to="/biz/search">搜索简历</Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link to="/biz/account">账户中心</Link>
                </Menu.Item>
                <Menu.Item key="3">
                  <Link to="/biz/account/change">修改密码</Link>
                </Menu.Item>
                <Menu.Item key="logout">
                  <span>退出登录</span>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="link"
              style={{ marginLeft: "15px", alignSelf: "center" }}
              href="/biz/home"
            >
              {" "}
              {(userInfo && userInfo.name) || "暂无用户信息"} <DownOutlined />
            </Button>
          </Dropdown>
        </Header>
        <Switch>
          <Route path="/biz" component={BizIndex} />
          <Route path="/hr" component={HrIndex} />
        </Switch>
        {userInfo?.state != 1 && (
          <div
            className="cover"
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: "8",
            }}
            onClick={() => {
              this.setState({
                visibleStart: true,
              });
            }}
          ></div>
        )}

        {visibleStart && (
          <div
            className="container rechange-packs"
            style={{
              position: "fixed",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              zIndex: "9",
              background: "#f8f8f8",
              padding: "60px 30px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "15px",
                left: "15px",
                fontSize: "24px",
                cursor: "pointer",
              }}
            >
              <Button href="/index">返回首页</Button>
            </div>
            <div
              className="close"
              style={{
                position: "absolute",
                top: "15px",
                right: "15px",
                fontSize: "24px",
                cursor: "pointer",
              }}
              onClick={() => {
                this.setState({
                  visibleStart: false,
                });
              }}
            >
              X
            </div>
            <div
              className="close"
              style={{
                position: "absolute",
                top: "15px",
                right: "15px",
                fontSize: "24px",
                cursor: "pointer",
              }}
              onClick={() => {
                this.setState({
                  visibleStart: false,
                });
              }}
            >
              X
            </div>
            <div className="title">充值人才币解锁账户</div>
            <div className="subtitle">初次登录需要选择充值人才币激活账户</div>
            <div className="packs">
              <Row gutter={30}>
                <Col style={{ maxWidth: "25%", flex: "0 0 25%" }}>
                  <div className="packs-item">
                    <div className="title">基础版A</div>
                    <div className="intro">
                      <div className="service">
                        <div>
                          <Tag className="mr-1">服务（含赠送）</Tag>
                        </div>
                        <div>
                          人才币2800，职位置顶20次， 职位刷新50次，可发布5个不
                          同职位数量
                        </div>
                      </div>
                      <div className="service mb-1">
                        <div>
                          <Tag className="mr-1">服务期</Tag>
                        </div>
                        <div>6个月</div>
                      </div>
                      <div className="service">
                        <div>
                          <Tag className="mr-1">适合</Tag>
                        </div>
                        <div> 中小微商户使用</div>
                      </div>
                    </div>
                    <div className="money">
                      <span className="num">￥2688.00</span>
                    </div>
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        this.rechargeCall();
                        this.getQrcode(2688);
                      }}
                    >
                      立即激活
                    </Button>
                  </div>
                </Col>
                <Col style={{ maxWidth: "25%", flex: "0 0 25%" }}>
                  <div className="packs-item">
                    <div className="title">基础版本套餐B</div>
                    <div className="intro">
                      <div className="service">
                        <div>
                          <Tag className="mr-1">服务（含赠送）</Tag>
                        </div>
                        <div>
                          人才币4200，职位置顶40次， 职位刷新80次，可发布8个不
                          同职位数量
                        </div>
                      </div>
                      <div className="service mb-1">
                        <div>
                          <Tag className="mr-1">服务期</Tag>
                        </div>
                        <div>12个月</div>
                      </div>
                      <div className="service">
                        <div>
                          <Tag className="mr-1">适合</Tag>
                        </div>
                        <div>中小微商户使用</div>
                      </div>
                    </div>
                    <div className="money">
                      <span className="num">￥3988.00</span>
                    </div>
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        this.rechargeCall();
                        this.getQrcode(3988);
                      }}
                    >
                      立即激活
                    </Button>
                  </div>
                </Col>
                <Col style={{ maxWidth: "25%", flex: "0 0 25%" }}>
                  <div className="packs-item">
                    <div className="title">高级版套餐（C1）</div>
                    <div className="intro">
                      <div className="service">
                        <div>
                          <Tag className="mr-1">服务（含赠送）</Tag>
                        </div>
                        <div>
                          人才币5500，职位置顶60次， 职位刷新180次，可发布15个不
                          同职位数量
                        </div>
                      </div>
                      <div className="service mb-1">
                        <div>
                          <Tag className="mr-1">服务期</Tag>
                        </div>
                        <div>12个月</div>
                      </div>
                      <div className="service">
                        <div>
                          <Tag className="mr-1">适合</Tag>
                        </div>
                        <div>中小微商户使用</div>
                      </div>
                    </div>
                    <div className="money">
                      <span className="num">￥4988.00</span>
                    </div>
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        this.rechargeCall();
                        this.getQrcode(4988);
                      }}
                    >
                      立即激活
                    </Button>
                  </div>
                </Col>
                <Col style={{ maxWidth: "25%", flex: "0 0 25%" }}>
                  <div className="packs-item">
                    <div className="title">高级版套餐（C2）</div>
                    <div className="intro">
                      <div className="service">
                        <div>
                          <Tag className="mr-1">服务（含赠送）</Tag>
                        </div>
                        <div>
                          人才币6800，职位置顶80次， 职位刷新200次，可发布18个不
                          同职位数量
                        </div>
                      </div>
                      <div className="service mb-1">
                        <div>
                          <Tag className="mr-1">服务期</Tag>
                        </div>
                        <div>12个月</div>
                      </div>
                      <div className="service">
                        <div>
                          <Tag className="mr-1">适合</Tag>
                        </div>
                        <div>中小微商户使用</div>
                      </div>
                    </div>
                    <div className="money">
                      <span className="num">￥5988.00</span>
                    </div>
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        this.rechargeCall();
                        this.getQrcode(5988);
                      }}
                    >
                      立即激活
                    </Button>
                  </div>
                </Col>
                {/* <Col style={{ maxWidth: "25%", flex: "0 0 25%" }}>
                  <div className="packs-item">
                    <div className="title">钻石版</div>
                    <div className="intro">
                      <div className="service">
                        <div>
                          <Tag className="mr-1">服务（含赠送）</Tag>
                        </div>
                        <div>
                          人才币2000，职位置顶20次， 职位刷新50次，可发布5个不
                          同职位数量
                        </div>
                      </div>
                      <div className="service">
                        <div>
                          <Tag className="mr-1">适合</Tag>
                        </div>
                        <div>中小微商户使用</div>
                      </div>
                    </div>
                    <div className="money">
                      <span className="num">9888.00</span>人才币
                    </div>
                    <Button
                      type="primary"
                      block
                      onClick={() => {
                        this.rechargeCall();
                        this.getQrcode(9888);
                      }}
                    >
                      立即激活
                    </Button>
                  </div>
                </Col> */}
              </Row>
            </div>
            <div className="tips mt-3" style={{ color: "red" }}>
              温馨提示：优惠赠送部分，请与客户经理或运营客服领取，招聘端口激活后系统会自动完成充值。
            </div>
          </div>
        )}

        <Modal
          visible={rechargeVisible}
          title="微信扫码支付充值人才币"
          onOk={this.rechargeOk}
          onCancel={this.rechargeCancel}
          width="400px"
          footer={[
            <Button key="submit" type="primary" onClick={this.rechargeOk}>
              完成充值
            </Button>,
            <Button key="link" onClick={this.rechargeCancel}>
              取消
            </Button>,
          ]}
        >
          {userInfo.state == 1 && (
            <div className="mb-3">
              <Form.Item
                label="充值金额"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
              >
                <Row gutter={30}>
                  <Col span={12}>
                    <InputNumber
                      min={0.01}
                      style={{ width: "100%" }}
                      value={money}
                      onChange={(val) => {
                        this.setState({
                          money: val,
                        });
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <Button
                      onClick={() => {
                        this.getQrcode();
                      }}
                    >
                      获取二维码
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </div>
          )}
          {rechargeQrcode && (
            <div
              className=""
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <QRCodeCanvas width={200} value={rechargeQrcode} />
            </div>
          )}
        </Modal>
      </Layout>
    );
  }
}
