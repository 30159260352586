import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "../index.less";

import CompanyLogin from "./companylogin";
import HiringLogin from "./hiringlogin";

export default class Login extends React.Component {
  componentWillMount = () => {
    document.title = "登录 - 速猎直推";
  };

  render() {
    return (
      <Switch>
        <Route path="/entrance/biz/login" component={CompanyLogin} />
        <Route path="/entrance/hr/login" component={HiringLogin} />
        <Redirect to={`/entrance/biz/login`} />
      </Switch>
    );
  }
}
