import React, { useState } from "react";
import { Switch, Route, Redirect, Link, useHistory } from "react-router-dom";
import {
  Spin,
  Layout,
  Row,
  Col,
  Menu,
  message,
  Input,
  Button,
  Empty,
} from "antd";
import { DownOutlined } from "@ant-design/icons";

import httpPromise from "@/api";

import cookie from "react-cookies";
import qs from "qs";

import "./selected.less";

import Headers from "./Headers";
import HomeFooter from "../../components/homeFooter";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

class Selected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userinfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      searchInput: "",
      searchVal: "",
      pagination: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
      },
      searchRes: [],
      links: [
        "销售",
        "销售经理",
        "客服",
        "电话销售",
        "文员",
        "美容",
        "技师",
        "服务",
      ],
    };
    this.ref = null;
    this.scrollRef = React.createRef();
  }
  componentDidMount() {
    this.getUserInfo();

    let param = qs.parse(this.props.history.location.search.split("?")[1]);

    if (!!param?.search) {
      this.setState(
        { searchInput: param?.search, searchVal: param?.search },
        () => {
          this.getSearch();
        }
      );
    } else {
      this.getSearch();
    }

    window.addEventListener("scroll", this.handleScroll, false);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  getScrollTop = () => {
    var scrollTop = 0,
      bodyScrollTop = 0,
      documentScrollTop = 0;
    if (document.body) {
      bodyScrollTop = document.body.scrollTop;
    }
    if (document.documentElement) {
      documentScrollTop = document.documentElement.scrollTop;
    }
    scrollTop =
      bodyScrollTop - documentScrollTop > 0 ? bodyScrollTop : documentScrollTop;
    return scrollTop;
  };

  getScrollHeight = () => {
    var scrollHeight = 0,
      bodyScrollHeight = 0,
      documentScrollHeight = 0;
    if (document.body) {
      bodyScrollHeight = document.body.scrollHeight;
    }
    if (document.documentElement) {
      documentScrollHeight = document.documentElement.scrollHeight;
    }
    scrollHeight =
      bodyScrollHeight - documentScrollHeight > 0
        ? bodyScrollHeight
        : documentScrollHeight;
    return scrollHeight;
  };
  getWindowHeight = () => {
    var windowHeight = 0;
    if (document.compatMode == "CSS1Compat") {
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowHeight = document.body.clientHeight;
    }
    return windowHeight;
  };

  getUserInfo = async () => {
    let t = this;
    httpPromise({ apiDemo: "companyGetUserInfo" })
      .then(function (res) {
        console.log(res);
        if (res.code === 200) {
          t.setState({ loading: false, userinfo: res.data });
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          cookie.save("userinfo", res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // 页面滚动
  handleScroll = () => {
    if (
      this.getScrollHeight() - this.getScrollTop() - this.getWindowHeight() <
      500
    ) {
      // 解除绑定
      window.removeEventListener("scroll", this.handleScroll, false);
      // 在这里发送请求
      if (this.state.pagination.pageNo >= this.state.pagination.pageTotal) {
        return false;
      }

      this.setState(
        {
          pagination: {
            ...this.state.pagination,
            pageNo: this.state.pagination.pageNo + 1,
          },
        },
        () => {
          this.getSearch();
        }
      );

      console.log("到底了");
      // 并在请求到数据后重新开启监听
      setTimeout(
        () => window.addEventListener("scroll", this.handleScroll, false),
        300
      );
    }
  };

  getSearch = async () => {
    let t = this;
    httpPromise({
      apiDemo: "companyGetSearch",
      params: { ...this.state.pagination, occupation: t.state.searchInput },
    })
      .then(function (res) {
        console.log(res);
        if (res.code === 200) {
          t.setState({
            searchRes: [...t.state.searchRes, ...res.data.data],
            pagination: {
              ...t.state.pagination,
              total: res.data.total,
              pageTotal: res.data.pageTotal,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { userinfo, searchInput, searchVal, searchRes, pagination, links } =
      this.state;

    return (
      <Spin spinning={this.state.loading}>
        <Headers userinfo={userinfo} />
        <Layout className="selected">
          <Layout style={{ minHeight: "100vh" }}>
            <Content style={{ position: "relative" }}>
              <div className="top-banner">
                <div className="container">
                  <div className="search">
                    <Input
                      value={searchInput}
                      placeholder="输入岗位搜索您需要的人才"
                      onChange={(e) => {
                        this.setState({
                          searchInput: e.target.value,
                        });
                      }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        this.setState(
                          {
                            searchVal: searchInput,
                            searchRes: [],
                            pagination: {
                              pageNo: 1,
                              pageSize: 12,
                            },
                          },
                          () => {
                            this.getSearch();

                            window.removeEventListener(
                              "scroll",
                              this.handleScroll
                            );

                            window.addEventListener(
                              "scroll",
                              this.handleScroll,
                              false
                            );
                          }
                        );
                      }}
                    >
                      搜索
                    </Button>
                  </div>

                  <div className="links">
                    热门岗位：
                    {links.map((item, index) => (
                      <span key={index}>
                        <a href={`/selected?search=${item}`}>{item}</a>
                        {/* <a href={`/selected?search=${"销售"}`}>{item}</a> */}
                        {index + 1 < links.length ? <span>、</span> : ""}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div
                className="search-res"
                // ref={this.scrollRef}
                // onScroll={this.handleScroll}
              >
                <div className="container">
                  {(searchVal && (
                    <div className="tips">
                      <div className="title">
                        已为您找到
                        {/* {pagination?.total} 位 */}
                        <span className="red"> {searchVal} </span>
                        人才
                      </div>
                    </div>
                  )) ||
                    ""}
                  <div className="search-list">
                    {searchRes.map((item, index) => (
                      <div
                        className="search-list-item"
                        key={index}
                        onClick={() => {
                          this.props.history.push(`/biz/home`);
                        }}
                      >
                        <div className="head">
                          <div className="name">
                            <img
                              className="cover"
                              src={require(`../../atess/image/indexnew/${
                                item.sex == "男" ? "man" : "woman"
                              }.png`)}
                            />
                            <span>{item.name}</span>
                          </div>
                          <div className="price">
                            {(item.min_salary === item.max_salary &&
                              item.max_salary) ||
                              `${item.min_salary} ~ ${item.max_salary}`}
                          </div>
                        </div>
                        <div className="cont">
                          <div className="tag">{item.education}</div>
                          <div className="tag">{item.experience}</div>
                          <div className="tag">{item.sex}</div>
                          <div className="tag">{item.age}</div>
                          <div className="tag">{item.state}</div>
                        </div>
                        <div className="foot">
                          <div className="postname">{item.occupation}</div>
                          <div className="address">{item.cities}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {(searchRes.length <= 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "60px",
                      }}
                    >
                      <Empty />
                    </div>
                  )) ||
                    ""}
                </div>
              </div>

              <HomeFooter />
            </Content>
          </Layout>
        </Layout>
      </Spin>
    );
  }
}

export default Selected;
