import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Table,
  Alert,
  Space,
  Empty,
  Typography,
  message,
  Row,
  Col,
  Collapse,
} from "antd";

import "../index.less";
import httpPromise from "@/api";

const { Text } = Typography;
const { Panel } = Collapse;

export default class BizRecruit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pagination: {
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      psList: [],
      issueList: [],
      tableInfo: [
        {
          title: "发布时间",
          dataIndex: "create_time",
          key: "create_time",
          render: (value, item) => {
            return (
              <div>
                <img
                  src={require("@/atess/image/time.png")}
                  className="mr-1"
                  alt=""
                  style={{
                    opacity: 0.5,
                    marginRight: "10px",
                    width: "13px",
                    position: "relative",
                    top: "-1px",
                  }}
                />
                <span>{item.create_time}</span>
              </div>
            );
          },
        },
        {
          title: "职位名称",
          dataIndex: "title",
          key: "title",
          render: (value, item) => {
            return (
              <Link to={`/biz/recruit/detail/${item.id}`}>{item.title}</Link>
            );
          },
        },
        {
          title: "",
          dataIndex: "id",
          key: "id",
          render: (value, item) => {
            // disabled={!item.status}
            return (
              <Space size="small">
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    this.toTop(item.id);
                  }}
                >
                  置顶
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    this.toRefre(item.id);
                  }}
                >
                  刷新
                </Button>
              </Space>
            );
          },
        },
        {
          title: "推荐次数",
          dataIndex: "recommend",
          key: "recommend",
          render: (value, data) => {
            return (
              <div>
                您有 <b> {data.resume_count} 条推荐</b> 正在进行中
              </div>
            );
          },
        },
        {
          title: "操作",
          dataIndex: "",
          key: "op",
          render: (value) => {
            const continueOp = (
              <Button
                style={{ cursor: "pointer" }}
                type="primary"
                size="small"
                onClick={() => {
                  this.continue(value.id);
                }}
              >
                继续招聘
              </Button>
            );
            const pauseOp = (
              <Button
                style={{
                  cursor: "pointer",
                  background: "#e6a23c",
                  borderColor: "#e6a23c",
                }}
                type="primary"
                size="small"
                onClick={() => {
                  this.pause(value.id);
                }}
              >
                暂停招聘
              </Button>
            );
            const editOp = (
              <Button
                type="primary"
                size="small"
                href={`/biz/recruit/add/${value.id}`}
              >
                编辑
              </Button>
            );
            const detailOp = (
              <Button
                type="warning"
                size="small"
                href={`/biz/recruit/detail/${value.id}`}
              >
                查看
              </Button>
            );
            const delOp = (
              <Button type="primary" size="small" danger>
                删除
              </Button>
            );
            const overOp = (
              <Button
                style={{ cursor: "pointer" }}
                type="primary"
                size="small"
                danger="true"
                onClick={() => {
                  this.over(value.id);
                }}
              >
                结束招聘
              </Button>
            );
            const isOver = (
              <Button
                style={{ cursor: "pointer" }}
                type="default"
                size="small"
                disabled={true}
              >
                招聘已结束
              </Button>
            );
            return (
              <Space size="middle">
                {(value.state == 0 && continueOp) || ""}
                {(value.state == 1 && pauseOp) || ""}
                {(value.state != 2 && overOp) || isOver}
                {editOp}
                {detailOp}
              </Space>
            );
          },
        },
      ],
    };
  }

  componentWillMount = () => {
    this.getPsList();
    this.getIssue();
  };

  handleTableChange = ({ current }, filters, sorter) => {
    let { pagination } = this.state;
    pagination.pageNo = current;
    this.setState({ pagination });
    this.getPsList();
  };

  getPsList = () => {
    this.setState({ loading: true });
    let { pagination } = this.state;
    httpPromise({ apiDemo: "companyGetJbList", params: { ...pagination } })
      .then((res) => {
        console.log(1);
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({
            psList: res.data.data,
            pagination: { ...pagination, total: res.data.pageTotal },
          });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getIssue = () => {
    this.setState({ loading: true });
    let { pagination } = this.state;
    httpPromise({ apiDemo: "companyIssue" })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.setState({ issueList: res.data });
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  continue = (id) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyEditJob",
      params: {
        id,
        state: 1,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getPsList();
          message.success("操作成功");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  pause = (id) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyEditJob",
      params: {
        id,
        state: 0,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getPsList();
          message.success("操作成功");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  over = (id) => {
    this.setState({ loading: true });
    httpPromise({
      apiDemo: "companyEditJob",
      params: {
        id,
        state: 2,
      },
    })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          this.getPsList();
          message.success("操作成功");
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toTop = (id) => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companySetTop", params: { id } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("置顶成功");
          this.getPsList();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toRefre = (id) => {
    this.setState({ loading: true });
    httpPromise({ apiDemo: "companySetRefresh", params: { id } })
      .then((res) => {
        this.setState({ loading: false });
        if (res.code === 200) {
          message.success("刷新成功");
          this.getPsList();
        } else {
          message.error(res.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    let { psList, tableInfo, pagination, loading, issueList } = this.state;
    return (
      <main className="pg-biz-recruit">
        <Row gutter="24">
          <Col span={18}>
            <Alert
              message="温馨提示：线上HR推荐人才到企业面试，企业需及时更改求职者状态。"
              type="warning"
            />
            <section
              className="md-section border"
              style={{ marginTop: "24px" }}
            >
              <header
                className="md-section-header"
                style={{ position: "relative" }}
              >
                我的直推
                <Button
                  type="primary"
                  size="large"
                  style={{
                    position: "absolute",
                    right: 0,
                    top: -15,
                    paddingLeft: "40px",
                  }}
                  className="md-section-body_ctrlbtn"
                >
                  <Link to="/biz/recruit/add">
                    <span
                      style={{
                        fontSize: "35px",
                        position: "absolute",
                        top: "-12px",
                        left: "9px",
                      }}
                    >
                      +
                    </span>{" "}
                    发布职位
                  </Link>
                </Button>
              </header>
              <div className="md-section-body">
                <Table
                  columns={tableInfo}
                  dataSource={psList}
                  onChange={this.handleTableChange}
                  pagination={pagination}
                  loading={loading}
                  rowKey="id"
                />
              </div>
            </section>
          </Col>
          <Col span={6}>
            <section className="md-section border">
              <header className="md-section-header">常见问题</header>
              {issueList.length ? (
                <Collapse accordion className="md-section-body">
                  {issueList.map((item) => (
                    <Panel header={item.title} key={item.id}>
                      <p>{item.content}</p>
                    </Panel>
                  ))}
                </Collapse>
              ) : (
                <div className="md-section-body">
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </section>
          </Col>
        </Row>
      </main>
    );
  }
}
