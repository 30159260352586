import instance from './instance.js';
import { base } from "./config.js";
import {message} from 'antd'
export default {
  //发送短信验证码
  async common_code_phone(params){
    return  await instance.post(`${base}/common/smscode/phone`,params).then((res)=>{
      return res.data;
    }).catch((error)=>{
      message.error('服务器出错');
    });
  },
  //发送邮件验证码
  async common_code_email(params){
    return  await instance.post(`${base}/common/smscode/email`,params).then((res)=>{
      return res.data;
    }).catch((error)=>{
      message.error('服务器出错');
    });
  },
  // 获取城市
  async common_get_city(params){
    return  await instance.post(`${base}/enterprise/common/getCity`,params).then((res)=>{
      return res.data;
    }).catch((error)=>{
      console.log(error);
      // message.error('服务器出错');
    });
  },
  // 

}