import React from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { Layout, Menu, Breadcrumb } from 'antd';
import {
    HomeOutlined,
    FireOutlined,
    FileSearchOutlined,
    CloudDownloadOutlined,
    ShareAltOutlined,
    SolutionOutlined
} from '@ant-design/icons';

import './index.less';

// import BizHome from '@/pages/hiring/home';
// import BizRecruit from '@/pages/hiring/recruit/index';
// import BizSearch from '@/pages/hiring/search';
// import BizDownload from '@/pages/hiring/download';
// import BizShare from '@/pages/hiring/share';
// import BizAccount from '@/pages/hiring/account';

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export default class HrIndex extends React.Component {

    formRef = React.createRef();
    constructor(props) {
        super(props)
        this.state = {
            collapsed: false,
            SelectedKeys: ['101'],
            navList: [{
                id: 101,
                title: '首页',
                path: '/biz/home',
                icon: <HomeOutlined />
            }, {
                id: 102,
                title: '悬赏招聘',
                path: '/biz/recruit',
                icon: <FireOutlined />
            }, {
                id: 103,
                disabled: true,
                title: '搜索简历',
                path: '/biz/search',
                icon: <FileSearchOutlined />
            }, {
                id: 104,
                disabled: true,
                title: '查看下载',
                path: '/biz/download',
                icon: <CloudDownloadOutlined />
            }, {
                id: 105,
                disabled: true,
                title: '分享简历',
                path: '/biz/share',
                icon: <ShareAltOutlined />
            }, {
                id: 106,
                title: '企业账户',
                path: '/biz/account',
                icon: <SolutionOutlined />
            }]
        };
    }

    componentWillMount = () => {
        let { pathname } = this.props.location;
        let { navList } = this.state;
        this.setState({
            SelectedKeys: [navList.find(item => pathname.includes(item.path)).id.toString()]
        })
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    navItem = (item) => {
        if (item.child) {
            return (
                <SubMenu key={item.id} icon={item.icon} title={item.title} disabled={item.disabled}>
                    {item.child.map(child => this.navItem(child))}
                </SubMenu>
            )
        } else {
            return <Menu.Item key={item.id} icon={item.icon} disabled={item.disabled}>
                <Link to={item.path}>{item.title}</Link>
            </Menu.Item>
        }
    }

    render() {
        let { navList, SelectedKeys } = this.state;
        const navListDom = navList.map((item) => {
            return this.navItem(item);
        })
        return (
            <Layout>
                <Sider width={200} className="pg-home-layout-slider">
                    <Menu
                        mode="inline"
                        defaultSelectedKeys={SelectedKeys}
                        style={{ height: '100%', borderRight: 0 }}
                    >
                        {navListDom}
                    </Menu>
                </Sider>
                <Content className="site-layout-background">
                    {/* <Switch>
                        <Route path="/hr/home" component={BizHome} />
                        <Route path="/hr/recruit" component={BizRecruit} />
                        <Route path="/hr/search" component={BizSearch} />
                        <Route path="/hr/download" component={BizDownload} />
                        <Route path="/hr/share" component={BizShare} />
                        <Route path="/hr/account" component={BizAccount} />
                    </Switch> */}
                </Content>
            </Layout>
        )
    }
}


