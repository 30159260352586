
import codes from './state_code.js'; //状态码，报错提示
import login from './login.js';
import user from './user.js';
import comm from './comm.js';
import hiring from './hiring.js';
import company from './company.js';
// import upload from './upload.js';
// import appcreate from './appcreate.js';


let api = {
    stateCode: codes,
    ...login,
    ...user,
    ...comm,
    ...hiring,
    ...company,
    // ...upload,
    // ...appcreate,
}

function httpPromise({ apiDemo, params }) {
    const promise = new Promise(function (resolve, reject) {
        let res = api[apiDemo](params);
        if (res) {
            resolve(res)
        } else {
            reject('出错')
        }
    });
    return promise;
}

export default httpPromise

