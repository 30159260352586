import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, message } from "antd";
import {
  HomeOutlined,
  FireOutlined,
  FileSearchOutlined,
  CloudDownloadOutlined,
  ShareAltOutlined,
  SolutionOutlined,
  TeamOutlined,
  StarOutlined,
  UserOutlined,
  ForkOutlined,
} from "@ant-design/icons";

import "./index.less";
import cookie from "react-cookies";

import BizHome from "@/pages/business/home";

import BizRecruit from "@/pages/business/recruit/index";
import AddPosition from "@/pages/business/recruit/addposition";
import BizRecruitDetail from "@/pages/business/recruit/detail";

import BizSearch from "@/pages/business/search";
import BizDownload from "@/pages/business/download";
import BizShare from "@/pages/business/share";
import BizAccount from "@/pages/business/account";
import BizAccountPass from "@/pages/business/changepass";

import httpPromise from "@/api";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

export default class BizIndex extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      userInfo: localStorage.getItem("userInfo")
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      collapsed: false,
      SelectedKeys: ["101"],
      navList: [
        {
          id: 101,
          title: "首页",
          path: "/biz/home",
          icon: <HomeOutlined />,
        },
        {
          id: 102,
          title: "我的直推",
          path: "/biz/recruit",
          icon: (
            <img
              src={require("@/atess/image/slidebar-icon02.png")}
              style={{ position: "relative", left: "-4px" }}
            />
          ),
        },
        {
          id: 103,
          // disabled: true,
          title: "人才管理",
          path: "/biz/download",
          icon: <TeamOutlined />,
        },
        {
          id: 104,
          // disabled: true,
          title: "共享资源",
          path: "/biz/share",
          icon: <ForkOutlined />,
        },
        {
          id: 105,
          // disabled: true,
          title: "人才精选",
          path: "/biz/search",
          icon: <StarOutlined />,
        },
        {
          id: 106,
          title: "企业账户",
          path: "/biz/account",
          icon: <UserOutlined />,
        },
      ],
    };
  }

  componentWillMount = () => {
    this.getuserinfo();
  };

  getuserinfo = () => {
    httpPromise({ apiDemo: "companyGetUserInfo" })
      .then((res) => {
        if (res.code === 200) {
          this.setState({ userInfo: res.data });
          cookie.save("userinfo", res.data);
          localStorage.setItem("userInfo", JSON.stringify(res.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  navItem = (item) => {
    if (item.child) {
      return (
        <SubMenu
          key={item.id}
          icon={item.icon}
          title={item.title}
          disabled={item.disabled}
        >
          {item.child.map((child) => this.navItem(child))}
        </SubMenu>
      );
    } else {
      console.log(item);

      return (
        <Menu.Item
          key={item.id}
          icon={item.icon}
          disabled={item.disabled}
          className={item.id == 102 ? "menu-red" : ""}
        >
          <Link to={item.path || "/biz/empty"}>{item.title}</Link>
        </Menu.Item>
      );
    }
  };

  getSelectKey = () => {
    let { navList } = this.state;
    let { pathname } = this.props.location;
    let child = navList.find((item) => pathname.includes(item.path));
    if (!child) {
      this.props.history.push("/biz/home");
    } else {
      return child.id.toString();
    }
  };

  render() {
    let { navList, userInfo } = this.state;
    const navListDom = navList.map((item) => {
      return this.navItem(item);
    });
    let selectKey = this.getSelectKey();
    return (
      <Layout className="pg-biz">
        <Sider width={160} className="pg-home-layout-slider">
          <Menu
            mode="inline"
            theme="dark"
            selectedKeys={[selectKey]}
            style={{ height: "100%", borderRight: 0 }}
          >
            {navListDom}
          </Menu>
        </Sider>
        <Switch>
          <Route path="/biz/home" component={BizHome} />
          <Route path="/biz/recruit/detail/:id" component={BizRecruitDetail} />
          <Route path="/biz/recruit/add/:id" component={AddPosition} />
          <Route path="/biz/recruit/add" component={AddPosition} />
          <Route path="/biz/recruit" component={BizRecruit} />
          <Route path="/biz/search" component={BizSearch} />
          <Route path="/biz/download" component={BizDownload} />
          <Route path="/biz/share" component={BizShare} />
          <Route path="/biz/account/change" component={BizAccountPass} />
          <Route path="/biz/account" component={BizAccount} />
        </Switch>
      </Layout>
    );
  }
}
