import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { Button, Modal } from "antd";
import {
  TeamOutlined,
  MessageOutlined,
  UngroupOutlined,
} from "@ant-design/icons";

import "./index.less";
import api from "@/api";
import cookie from "react-cookies";

import Login from "./login/index";
import Register from "./register/index";
import Forget from "./forget/index";

class ListItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <li className="list-item">
        {this.props.data.icon}
        <p className="list-item_info">
          <span className="list-item_info-title">{this.props.data.title}</span>
          <span className="list-item_info-desc">{this.props.data.desc}</span>
        </p>
      </li>
    );
  }
}

class PageType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { active, type, visible } = this.props;
    let isCompany = active === "biz";
    switch (type) {
      case "forget":
        return <div className="pg-entrance-pagetype fs-18">找回密码</div>;
      case "change":
        return <div className="pg-entrance-pagetype fs-18">修改密码</div>;
      default:
        let title = type === "login" ? "登录" : "注册";
        return (
          <div className="pg-entrance-pagetype">
            <Button
              type={isCompany && "primary"}
              ghost={isCompany}
              size="large"
              href={`/entrance/biz/${type}`}
            >
              企业{title}
            </Button>
            <Button
              type={!isCompany && "primary"}
              ghost={!isCompany}
              size="large"
              href={`/entrance/hr/${type}`}
            >
              HR{title}
            </Button>
          </div>
        );
    }
  }
}

export default class Entrance extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      activeType: "biz",
      sloganHeader: {
        icon: <TeamOutlined className="list-item_pattern" />,
        title: "找工作",
        desc: "我要跟老板谈",
      },
      visible: false,
    };
  }

  componentWillMount = () => {};

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  getSloganList = (activeType) => {
    switch (activeType) {
      case "biz":
        return [
          {
            icon: <MessageOutlined className="list-item_pattern" />,
            title: "沟通",
            desc: "在线职位及时沟通",
          },
          {
            icon: <UngroupOutlined className="list-item_pattern" />,
            title: "任性选",
            desc: "各大行业职位任你选",
          },
        ];

      default:
        return [
          {
            icon: <MessageOutlined className="list-item_pattern" />,
            title: "沟通",
            desc: "在线职位及时沟通",
          },
          {
            icon: <UngroupOutlined className="list-item_pattern" />,
            title: "任性选",
            desc: "各大行业职位任你选",
          },
        ];
    }
  };

  render() {
    const { sloganHeader, visible } = this.state;
    const { pathname } = this.props.location;
    const routeArr = pathname.split("/");
    const [activeType] = routeArr.splice(-2, 1);
    const [entranceType] = routeArr.splice(-1, 1);
    const sloganList = this.getSloganList(activeType);
    return (
      <div className="login-cont">
        <div className="pg-entrance">
          <main className="pg-entrance-main">
            {/* <aside className="pg-entrance_aside">
              <header
                className="pg-entrance_aside-header"
                onClick={() => this.showModal()}
                style={{ cursor: "pointer" }}
              >
                {sloganHeader.icon}
                <p className="header_info">
                  <span>{sloganHeader.title}</span>
                  <span>{sloganHeader.desc}</span>
                </p>
              </header>
              <ul className="pg-entrance_aside-list">
                {sloganList.map((item, index) => (
                  <div
                    onClick={() => this.showModal()}
                    style={{ cursor: "pointer" }}
                  >
                    <ListItem key={`aside_${index}`} data={item}></ListItem>
                  </div>
                ))}
              </ul>
            </aside> */}
            <section className="pg-entrance_section">
              {/* <PageType active={activeType} type={entranceType} /> */}
              <Switch>
                <Route path="/entrance/biz/login" component={Login} />
                <Route path="/entrance/hr/login" component={Login} />
                <Route path="/entrance/biz/register" component={Register} />
                <Route path="/entrance/hr/register" component={Register} />
                <Route path="/entrance/biz/forget" component={Forget} />
                <Route path="/entrance/hr/forget" component={Forget} />
                <Redirect to={`/entrance/biz/login`} />
              </Switch>
            </section>
          </main>

          <Modal
            visible={visible}
            title={false}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            width="400px"
            footer={[]}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={require("@/atess/image/qrcode.png")}
                alt=""
                style={{
                  marginBottom: 15,
                  marginTop: 40,
                  maxWidth: "100%",
                }}
              />
              <p>使用微信扫描二维码联系在线客服</p>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}
